import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from '../../../components/other/Headingtext-Btn'
import AboutOverview from '../../../components/about/AboutOverview'
import VinsysCaterSection from '../../../components/digilearn/about/VinsysCaterSection'
import WhyVinsys from '../../../components/digilearn/LearningManagementPlatform/WhyVinsys'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import CommonSec from '../../../components/other/CommonSec'
import ContributesSection, { ContributesSectionItem } from '../../../components/digilearn/about/ContributesSection'
import ApiPath from '../../../components/other/ApiPath';
import ErrorSection from '../../../components/other/ErrorSection';
const DigilearnPOEPage = () => {
    $("body").addClass("inner-page")
    const [poweroilpagedata, setpoweroilpagedata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const poweroilpagepagedata = await fetch(`${ApiPath()}/get-poweroil-details`);
                const currentpoweroilpagepagedata = await poweroilpagepagedata.json();
                setpoweroilpagedata(currentpoweroilpagepagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!poweroilpagedata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{poweroilpagedata.seo != null && poweroilpagedata.seo.meta_title != null ? poweroilpagedata.seo.meta_title : poweroilpagedata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={poweroilpagedata.seo != null && poweroilpagedata.seo.meta_key != null ? poweroilpagedata.seo.meta_key : poweroilpagedata.title} />

                <meta name="description" content={poweroilpagedata.seo != null && poweroilpagedata.seo.meta_description != null ? poweroilpagedata.seo.meta_description : poweroilpagedata.title} />

                <meta property="og:title" content={poweroilpagedata.seo != null && poweroilpagedata.seo.meta_title != null ? poweroilpagedata.seo.meta_title : poweroilpagedata.title} />

                <meta property="og:description" content={poweroilpagedata.seo != null && poweroilpagedata.seo.meta_description != null ? poweroilpagedata.seo.meta_description : poweroilpagedata.title} />

                <meta property="og:image" content={poweroilpagedata.seo != null && poweroilpagedata.seo.og_image != null ? poweroilpagedata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {poweroilpagedata.seo != null && poweroilpagedata.seo.schema_list != null ? poweroilpagedata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            {poweroilpagedata.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={poweroilpagedata.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={poweroilpagedata.banner.heading} />
                        <p>
                            {poweroilpagedata.banner.subheading}
                        </p>
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }
            {poweroilpagedata.introduction != null
                ?
                <AboutOverview style="delivery-about-sec" image={poweroilpagedata.introduction.gallery !=null ? poweroilpagedata.introduction.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} star={Number(poweroilpagedata?.star)} rating={Number(poweroilpagedata?.rating)}>
                    <CommonHeading mainHeading={poweroilpagedata.introduction.heading} />
                    <div dangerouslySetInnerHTML={{ __html: poweroilpagedata.introduction.content }} />
                </AboutOverview>
                :
                ""
            }  
            {poweroilpagedata.what_we_do !=null && poweroilpagedata.what_we_do.gallery !=null
                ?
                <VinsysCaterSection image={poweroilpagedata.what_we_do.gallery[0].media} imagealt={poweroilpagedata.what_we_do.gallery[0].alt} title={poweroilpagedata.what_we_do.heading}>
                    <div dangerouslySetInnerHTML={{ __html: poweroilpagedata.what_we_do.content }} />
                </VinsysCaterSection>
                :
                ""
            }
            {poweroilpagedata.our_usps_section !=null && poweroilpagedata.usps !=null
                ?
                <CommonSec sectionstyle="ourUspImgSection" innerspace="inner-container">
                    <div className='heading-wraper text-center'>
                        <CommonHeading mainHeading={poweroilpagedata.our_usps_section.heading} />
                        <p>
                            {poweroilpagedata.our_usps_section.subheading}
                        </p>
                    </div>
                    <div class="items d-flex mt-60">
                        {poweroilpagedata.usps.map((val,index) => {
                            return (
                                <div class="item" key={index}>
                                    <div class="inner-content">
                                        <p>{val.heading}</p>
                                        <div class="img-wrapper">
                                            <img src={val.media} alt={val.alt!=null ? val.alt : val.heading} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </CommonSec>
                :
                ""
            }
            {/* <ContributesSection itemview="two-column" sectionheading={poweroilpagedata.how_vinsys_section.heading} sectionshortdescription={poweroilpagedata.how_vinsys_section.subheading} >
                {poweroilpagedata.how_vinsys.map((val,index)=>{
                    return(
                        <ContributesSectionItem key={index}
                        image={val.media} imagealt={val.alt!=null ? val.alt : val.heading}
                        description={val.heading}
                        />   
                    )
                })}                           
            </ContributesSection> */}
            {poweroilpagedata.why_vinsys_section !=null && poweroilpagedata.why_vinsys
                ?
                <WhyVinsys subheading={poweroilpagedata.why_vinsys_section.subheading} image={poweroilpagedata.why_vinsys_section.gallery !=null ? poweroilpagedata.why_vinsys_section.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} imagealt={poweroilpagedata.why_vinsys_section.gallery[0].alt != null ? poweroilpagedata.why_vinsys_section.gallery[0].alt : poweroilpagedata.why_vinsys_section.gallery[0].heading}>
                    <ul className='items d-flex mt-60'>
                        {poweroilpagedata.why_vinsys.map((val, index) => {
                            return (
                                <li key={index}>
                                    <img src={val.thumb_image} alt={val.heading} />
                                    {val.heading}
                                </li>
                            )
                        })}
                    </ul>
                </WhyVinsys>
                :
                ""
            }
            <DigiGetInTouch />
        </>
    )
}
export default DigilearnPOEPage