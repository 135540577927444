import InnerBanner from "../../../components/other/InnerBanner";
import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../../components/other/ScrollToTop";
import AboutOverview from "../../../components/about/AboutOverview"
import WhyVinsys from "../../../components/digilearn/LearningManagementPlatform/WhyVinsys";
import CommonBtn, { CommonHeading } from "../../../components/other/Headingtext-Btn";
import FeatureCourses from "../../../components/digilearn/CousreDetail/FeatureCourses";
import SuiteOfLearningPlatform from "../../../components/digilearn/LearningManagementPlatform/SuiteOfLearningPlatform";
import DigiGetInTouch from "../../../components/digilearn/Home/DigiGetInTouch";
import {PartnersOtherModule} from "../../home/PartnersClients";
import LearningManagementTrainings from "../../../components/digilearn/LearningManagementPlatform/LearningManagementTrainings";
import ErrorSection from "../../../components/other/ErrorSection";
import ApiPath from "../../../components/other/ApiPath";
const LearningManagementPlatformPage = () => {
    $("body").addClass("inner-page")
    const [learningmanagementdata, setlearningmanagementdata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const learningmanagementpagedata = await fetch(`${ApiPath()}/get-learning-management-platform-page-details`);
                const currentlearningmanagementpagedata = await learningmanagementpagedata.json();
                setlearningmanagementdata(currentlearningmanagementpagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!learningmanagementdata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{learningmanagementdata.seo != null && learningmanagementdata.seo.meta_title != null ? learningmanagementdata.seo.meta_title : learningmanagementdata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={learningmanagementdata.seo != null && learningmanagementdata.seo.meta_key != null ? learningmanagementdata.seo.meta_key : learningmanagementdata.title} />

                <meta name="description" content={learningmanagementdata.seo != null && learningmanagementdata.seo.meta_description != null ? learningmanagementdata.seo.meta_description : learningmanagementdata.title} />

                <meta property="og:title" content={learningmanagementdata.seo != null && learningmanagementdata.seo.meta_title != null ? learningmanagementdata.seo.meta_title : learningmanagementdata.title} />

                <meta property="og:description" content={learningmanagementdata.seo != null && learningmanagementdata.seo.meta_description != null ? learningmanagementdata.seo.meta_description : learningmanagementdata.title} />

                <meta property="og:image" content={learningmanagementdata.seo != null && learningmanagementdata.seo.og_image != null ? learningmanagementdata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {learningmanagementdata.seo != null && learningmanagementdata.seo.schema_list != null ? learningmanagementdata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            {learningmanagementdata.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={learningmanagementdata.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={learningmanagementdata.banner.heading} />
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            } 
            {learningmanagementdata.introduction != null
                ?           
                <AboutOverview style="delivery-about-sec" image={learningmanagementdata.introduction.gallery !=null ? learningmanagementdata.introduction.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} star={Number(learningmanagementdata?.star)} rating={Number(learningmanagementdata?.rating)}>
                    <CommonHeading mainHeading={learningmanagementdata.introduction.heading} />
                    <div className="description" dangerouslySetInnerHTML={{ __html: learningmanagementdata.introduction.content }} />
                </AboutOverview>
                :
                ""
            } 
            {learningmanagementdata.usps != null
                ?  
                <FeatureCourses topStyle="usps" sctionTitle={learningmanagementdata.usps_section.heading}>
                    {learningmanagementdata.usps.map((val, index) => {
                        return (
                            <li key={index}>
                                <div className="icon d-flex justify-content-center align-items-center"><img src={val.thumb_image} alt={val.thumb_alt} /></div>
                                <div className="title">{val.heading}</div>
                            </li>
                        )
                    })}
                </FeatureCourses>
                :
                ""
            } 
            {learningmanagementdata.our_suite_of_learning_platform != null
                ? 
                <SuiteOfLearningPlatform sectionTitle={learningmanagementdata.our_suite_section.heading} sectionSubTitle={learningmanagementdata.our_suite_section.subheading}>
                    {learningmanagementdata.our_suite_of_learning_platform.map((val, index) => {
                        return (
                            <div className="items d-flex justify-content-between align-items-center" key={index}>
                                <div className="image">
                                    <img src={val.media} alt={val.alt} />
                                    <div className="small-image">
                                        <img src={val.thumb_image} alt={val.thumb_alt} />
                                    </div>
                                </div>
                                <div className="detail">
                                    <div className="title">{val.heading}</div>
                                    <div className="description" dangerouslySetInnerHTML={{ __html: val.content }} />
                                </div>
                                <div className="w-100 shedule d-flex justify-content-between align-items-center">
                                    <div className="left">
                                        <div className="description" dangerouslySetInnerHTML={{ __html: learningmanagementdata.schedule_now.content }} />
                                    </div>
                                    <div className="right">
                                        <a className="common-btn" href="#getintouchform">{learningmanagementdata.schedule_now.heading} <i class="icon">&nbsp;</i></a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </SuiteOfLearningPlatform>
                :
                ""
            } 
            {learningmanagementdata.traning != null
                ? 
                <LearningManagementTrainings sectionTitle={learningmanagementdata.traning_section.heading} sectionSubTitle={learningmanagementdata.traning_section.subheading}>
                    {learningmanagementdata.traning.map((val, index) => {
                        return (
                            <div className="item" key={index}>
                                <div className="image"><img src={val.media} alt={val.alt} /></div>
                                <div className="detail">
                                    <div className="name">{val.heading}</div>
                                    <div className="description" dangerouslySetInnerHTML={{ __html: val.content }} />
                                </div>
                            </div>
                        )
                    })}
                    {/* <div class="w-100 d-flex justify-content-center mt-30">
                            <CommonBtn class="short light-bg" link="" text="View All" />
                        </div> */}
                </LearningManagementTrainings>
                :
                ""
            } 
            {learningmanagementdata.why_vinsys != null
                ? 
                <WhyVinsys subheading={learningmanagementdata.why_vinsys.subheading} image={learningmanagementdata.why_vinsys.gallery !=null ? learningmanagementdata.why_vinsys.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} imagealt={learningmanagementdata.why_vinsys.gallery[0].alt != null ? learningmanagementdata.why_vinsys.gallery[0].alt : learningmanagementdata.why_vinsys.gallery[0].heading}>
                    <ul className='items d-flex mt-60'>
                        {learningmanagementdata.why_vinsys_list.map((val, index) => {
                            return (
                                <li key={index}>
                                    <img src={val.thumb_image} alt={val.heading} />
                                    {val.heading}
                                </li>
                            )
                        })}
                    </ul>
                </WhyVinsys>
                :
                ""
            } 
            {learningmanagementdata.partners_section.partners != null
                ? 
                <PartnersOtherModule heading={learningmanagementdata.partners_section.heading} subheading={learningmanagementdata.partners_section.subheading}>
                    {learningmanagementdata.partners_section.partners.map((val,index) => {
                        return (
                            <div className="item" key={index}>
                                <img src={val.media} alt={val.alt} />
                            </div>
                        )
                    })}
                </PartnersOtherModule>
                :
                ""
            } 
            <DigiGetInTouch />

        </>
    )
}
export default LearningManagementPlatformPage