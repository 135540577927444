import $, { event } from 'jquery';
import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import AboutOverview from "../../components/about/AboutOverview";
import VisionMission from '../../components/about/VisionMission';
import OurCertification from '../../components/about/OurCertification';
import KeyFeature from '../../components/about/KeyFeature';
import VinsysJourney from '../../components/about/VinsysJourney';
import ChooseVinsys from '../../components/about/ChooseVinsys';
import ManagementTeam from '../../components/about/ManagementTeam';
import AwardsAccolades from '../../components/about/AwardsAccolades';
import ScrollToTop from '../../components/other/ScrollToTop';
import { CommonHeading } from '../../components/other/Headingtext-Btn';
import { CommonBtnFancyBox } from '../../components/other/Headingtext-Btn';
import InnerBanner from '../../components/other/InnerBanner';
import ApiPath from '../../components/other/ApiPath';
import Cookies from 'js-cookie';
import ErrorSection from '../../components/other/ErrorSection';
const AboutPage = () => {
    $("body").addClass("inner-page")
    const TokensCodes = Cookies.get('access_token');
    const [aboutdata, setAboutData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                //Contact Page Data
                const aboutpagedata = await fetch(`${ApiPath()}/get-about-page-details`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }

                });
                const currentaboutpagedata = await aboutpagedata.json();
                setAboutData(currentaboutpagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [TokensCodes]);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!aboutdata) {
        return <div className='text-center'>No data available.</div>;
    }
    const aboutFullOverview = <div className="detail-wrapper" dangerouslySetInnerHTML={{ __html: aboutdata.intro_section.content }} />;
    return (
        <>
            <ScrollToTop />
            <Helmet>
                <title>{aboutdata.seo != null && aboutdata.seo.meta_title != null ? aboutdata.seo.meta_title : aboutdata.heading}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content={aboutdata.seo != null && aboutdata.seo.meta_key != null ? aboutdata.seo.meta_key : aboutdata.heading} />
                <meta name="description" content={aboutdata.seo != null && aboutdata.seo.meta_description != null ? aboutdata.seo.meta_description : aboutdata.heading} />
                <meta property="og:title" content={aboutdata.seo != null && aboutdata.seo.meta_title != null ? aboutdata.seo.meta_title : aboutdata.heading} />
                <meta property="og:description" content={aboutdata.seo != null && aboutdata.seo.meta_description != null ? aboutdata.seo.meta_description : aboutdata.heading} />
                <meta property="og:image" content={aboutdata.seo != null && aboutdata.seo.og_image != null ? aboutdata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />
                <meta property="og:url" content={window.location.href} />
            </Helmet>

            {
                aboutdata.banner === null ? <>
                    <ErrorSection errorheading="Add Banner" />
                </> : <>
                    <InnerBanner image={aboutdata.banner.media} />
                </>
            }

            <AboutOverview style="" image={aboutdata.intro_section.image.media} fullContentRequire="yes-require" fullContent={aboutFullOverview}>
                <CommonHeading mainHeading={aboutdata.intro_section.heading} subHeading={aboutdata.intro_section.subheading} />
                <div className="detail-wrapper" dangerouslySetInnerHTML={{ __html: aboutdata.intro_section.intro }} />
            </AboutOverview>
            <VisionMission
                missionImage={aboutdata.mission_section.image.media}
                missionImageAlt={aboutdata.mission_section.image.alt}
                missionTitle={aboutdata.mission_section.heading}
                missionIntro={aboutdata.mission_section.intro}
                visionImage={aboutdata.vision_section.image.media}
                visionImageAlt={aboutdata.vision_section.image.alt}
                visionTitle={aboutdata.vision_section.heading}
                visionIntro={aboutdata.vision_section.intro}
            />
            <OurCertification title={aboutdata.cirtification_section.heading} image={aboutdata.cirtification_section.image.media}>
                {aboutdata.cirtification_section.critifications_list.map((val) => {
                    return (
                        <li key={val.id}>
                            <img src={val.image} alt="" /> {val.title}
                        </li>
                    )
                })}
            </OurCertification>
            <KeyFeature image={aboutdata.key_feature_section.image.media} title={aboutdata.key_feature_section.heading} subTitle={aboutdata.key_feature_section.subheading}>
                {aboutdata.key_feature_section.keyfeature_list.map((val) => {
                    return (
                        <li className="item d-flex" key={val.id}>
                            <div className="left-icon">
                                <img src={val.image} alt={val.alt} />
                            </div>
                            <div className="content">
                                <div className="feature-heading">
                                    {val.title}
                                </div>
                                <p>{val.details}</p>
                            </div>
                        </li>
                    )
                })}
            </KeyFeature>
            <VinsysJourney title={aboutdata.journey_section.heading} subTitle={aboutdata.journey_section.subheading}>
                {aboutdata.journey_section.journey_list.map((val) => {
                    return (
                        <li className="wow zoomIn" data-wow-delay={`${val.id}00ms`} key={val.id}>
                            <div className="content">
                                <div className="year">{val.year}</div>
                                <p>{val.details}</p>
                            </div>
                        </li>
                    )
                })}
            </VinsysJourney>
            <ChooseVinsys title={aboutdata.reason_section.heading} subTitle={aboutdata.reason_section.subheading}>
                {aboutdata.reason_section.reason_list.map((val) => {
                    return (
                        <li key={val.id}>
                            <div className="content-box">
                                <div className="icon">
                                    <img src={val.image} alt={val.alt} />
                                </div>
                                <span>{val.title}</span>
                            </div>
                        </li>
                    )
                })}
            </ChooseVinsys>
            <ManagementTeam title={aboutdata.team_section.heading}>
                {aboutdata.team_section.team_list.map((val) => {
                    return (
                        <div className="item" key={val.id}>
                            <div className="top-section">
                                <div className="heading-section d-flex align-items-center">
                                    <div className="teamImg">
                                        <div className='image'>
                                            <img src={val.image} alt={val.alt} />
                                        </div>
                                    </div>
                                    <div className="teamName">
                                        {val.name} <span>{val.designation}</span>
                                    </div>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: `${val.about.slice(0, 300)}....` }} />
                            </div>
                            <CommonBtnFancyBox class="short" link={`#full-text${val.id}`} text="Read More" />
                            <div className='full-text-team-profile' id={`full-text${val.id}`}>
                                <div className="teamName">
                                    {val.name} <span>{val.designation}</span>
                                </div>
                                <div className='description' dangerouslySetInnerHTML={{ __html: val.about }} />
                            </div>
                        </div>
                    )
                })}

            </ManagementTeam>
            <AwardsAccolades title={aboutdata.award_section.heading}>
                {aboutdata.award_section.award_list.map((val) => {
                    return (
                        <li className="item" key={val.id}>
                            <div className="content-box">
                                {val.details}
                            </div>
                        </li>
                    )
                })}

            </AwardsAccolades>
        </>
    )
}

export default AboutPage;