import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import AboutOverview from '../../../components/about/AboutOverview'
import CommonBtn, { CommonHeading } from '../../../components/other/Headingtext-Btn'
import ConsultancyOfferings from '../../../components/digilearn/Consultancy/ConsultancyOfferings'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import ErrorSection from '../../../components/other/ErrorSection';
import ApiPath from '../../../components/other/ApiPath';
const ConsultancyPage = () => {
    $("body").addClass("inner-page")
    const [consultancydata, setconsultancydata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const consultancypagedata = await fetch(`${ApiPath()}/get-consultancy-page-details`);
                const currentconsultancypagedata = await consultancypagedata.json();
                setconsultancydata(currentconsultancypagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!consultancydata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{consultancydata.seo != null && consultancydata.seo.meta_title != null ? consultancydata.seo.meta_title : consultancydata.title}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content={consultancydata.seo != null && consultancydata.seo.meta_key != null ? consultancydata.seo.meta_key : consultancydata.title} />
                <meta name="description" content={consultancydata.seo != null && consultancydata.seo.meta_description != null ? consultancydata.seo.meta_description : consultancydata.title} />
                <meta property="og:title" content={consultancydata.seo != null && consultancydata.seo.meta_title != null ? consultancydata.seo.meta_title : consultancydata.title} />
                <meta property="og:description" content={consultancydata.seo != null && consultancydata.seo.meta_description != null ? consultancydata.seo.meta_description : consultancydata.title} />
                <meta property="og:image" content={consultancydata.seo != null && consultancydata.seo.og_image != null ? consultancydata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />
                <meta property="og:url" content={window.location.href} />
                {consultancydata.seo != null && consultancydata.seo.schema_list != null ? consultancydata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            {consultancydata.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={consultancydata.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={consultancydata.banner.heading} />
                        <p>
                            {consultancydata.banner.subheading}
                        </p>
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }
            {consultancydata.introduction != null
                ?
                <AboutOverview style="delivery-about-sec" image={consultancydata.introduction.gallery[0].media} star={Number(consultancydata?.star)} rating={Number(consultancydata?.rating)}>
                    <CommonHeading mainHeading={consultancydata.introduction.heading} />
                    <div dangerouslySetInnerHTML={{ __html: consultancydata.introduction.content }} />
                </AboutOverview>
                :
                ""
            }
            {consultancydata.consultancy_offerings.page_list != null
                ?
                <ConsultancyOfferings sectiontitle={consultancydata.consultancy_offerings.heading} sectionsmalldesc={consultancydata.consultancy_offerings.subheading}>
                    {consultancydata.consultancy_offerings.page_list.map((val, index) => {
                        return (
                            <div className="item" key={index}>
                                <div className="inner-content">
                                    <div className="content-wrapper">
                                        <div className="small-heading">
                                            {val.heading}
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: val.content }} />
                                        <div className="btn-wrapper">
                                            <CommonBtn link={val.url} text="Learn More" placing="_blank" />
                                        </div>
                                    </div>
                                    <div className="img-wrapper">
                                        <img src={val.media} alt="offers" />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </ConsultancyOfferings>
                :
                ""
            }
            <DigiGetInTouch />
        </>
    )
}
export default ConsultancyPage