import CommonSec from "../../other/CommonSec"
import CommonBtn, { CommonHeading } from "../../other/Headingtext-Btn"

const DigitalCourses = ({sectionheading,sectionshortdescription,children}) => {
    return (
        <CommonSec sectionstyle="digi-courses" innerspace="inner-container">
            <div className="text-center">
                <CommonHeading mainHeading={sectionheading} />
                <p>
                    {sectionshortdescription}
                </p>
            </div>
            <div className="course-list d-flex justify-content-between mt-60">
                {children}
            </div>
        </CommonSec>
    )
}
const DigitalCoursesItem = ({image,imagealt,title,description,url}) => {
    return (
        <div className="item d-flex justify-content-between align-items-center">
            <div className="image">
                <img src={image} alt={imagealt} />
            </div>
            <div className="detail">
                <div className="course-name">{title}</div>
                <div dangerouslySetInnerHTML={{ __html: description }}/>
                {/* <div className="overview d-flex">
                    <span className="lesson">Lesson : 6</span>
                    <span className="student-count">Student : 198</span>
                    <span className="journey">Average</span>
                </div> */}
                <div className="links">
                    <CommonBtn link={url} text="Learn More" placing="_blank" />
                </div>
            </div>
        </div>
    )
}
export default DigitalCourses
export {DigitalCoursesItem}