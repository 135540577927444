import $ from 'jquery'
import React, { useState, useEffect } from "react"
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../components/other/ScrollToTop"
import InnerBanner from '../../components/other/InnerBanner'
import { CommonHeading } from "../../components/other/Headingtext-Btn"
import AboutOverview from "../../components/about/AboutOverview"
import FlsChooseUs, { FlsChooseUsItems } from "../../components/fls/FlsChooseUs"
import FlsGetQuote from "../../components/fls/Home/FlsGetQuote";
import axios from 'axios';
import ApiPath from '../../components/other/ApiPath';

const FlsAbout = () => {

    $(document).ready(function () {
        $("body").addClass("inner-page")
    })

    const [flsAboutdata, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Define the API endpoint URL
        const apiUrl = `${ApiPath()}/get-about-us-page-details`; // Replace with your API URL

        // Use Axios to make a GET request
        axios.get(apiUrl)
            .then((response) => {
                setData(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!flsAboutdata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }

    const aboutfullcontent = <div className="detail-wrapper" dangerouslySetInnerHTML={{ __html: flsAboutdata.overview.intro }} />

    return (
        <>
            <Helmet>
                <title>{flsAboutdata.seo != null && flsAboutdata.seo.meta_title != null ? flsAboutdata.seo.meta_title : flsAboutdata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={flsAboutdata.seo != null && flsAboutdata.seo.meta_key != null ? flsAboutdata.seo.meta_key : flsAboutdata.title} />

                <meta name="description" content={flsAboutdata.seo != null && flsAboutdata.seo.meta_description != null ? flsAboutdata.seo.meta_description : flsAboutdata.title} />

                <meta property="og:title" content={flsAboutdata.seo != null && flsAboutdata.seo.meta_title != null ? flsAboutdata.seo.meta_title : flsAboutdata.title} />

                <meta property="og:description" content={flsAboutdata.seo != null && flsAboutdata.seo.meta_description != null ? flsAboutdata.seo.meta_description : flsAboutdata.title} />

                <meta property="og:image" content={flsAboutdata.seo != null && flsAboutdata.seo.og_image != null ? flsAboutdata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {flsAboutdata.seo != null && flsAboutdata.seo.schema_list != null ? flsAboutdata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>

            <ScrollToTop />

            <InnerBanner
                style="digi-inner-banner"
                image={flsAboutdata.banner != null ? flsAboutdata.banner.media : `${process.env.PUBLIC_URL}/images/go-digital-learning-banner-min.jpg`}>

                <div className='text-center'>
                    <CommonHeading
                        mainHeading={flsAboutdata.banner != null ? flsAboutdata.banner.heading : flsAboutdata.title} />
                    <p>
                        {flsAboutdata.banner != null ? flsAboutdata.banner.subheading : ''}
                    </p>
                </div>
            </InnerBanner>

            {flsAboutdata.overview != null ?
                <AboutOverview
                    style="delivery-about-sec"
                    image={flsAboutdata.overview.gallery != null ? flsAboutdata.overview.gallery[0].media : ''}
                    fullContentRequire={aboutfullcontent != null ? 'yes-require' : ''} fullContent={aboutfullcontent} 
                    star={Number(flsAboutdata?.star)} rating={Number(flsAboutdata?.rating)}
                    >

                    <CommonHeading mainHeading={flsAboutdata.overview.heading} />
                    <div className="detail-wrapper" dangerouslySetInnerHTML={{ __html: flsAboutdata.overview.content }} />
                </AboutOverview>
                : ''}

            {flsAboutdata.why_choose_us != null ?
                <FlsChooseUs
                    heading={flsAboutdata.why_choose_us_section.heading}
                    subHeading={flsAboutdata.why_choose_us_section.subheading}
                    galleryImg={flsAboutdata.why_choose_us_section.gallery != null ? flsAboutdata.why_choose_us_section.gallery[0].media : ''}
                    galleryImgAlt={flsAboutdata.why_choose_us_section.gallery != null ? flsAboutdata.why_choose_us_section.gallery[0].alt : ''} >
                    {flsAboutdata.why_choose_us.map((val) => {
                        return (
                            <FlsChooseUsItems
                                icon={val.thumb_image}
                                iconAlt={val.thumb_alt != null ? val.thumb_alt : val.heading}
                                title={val.heading}
                            />
                        )
                    })}
                </FlsChooseUs>
                : ''}

            <FlsGetQuote />
        </>
    )
}

export default FlsAbout