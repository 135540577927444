import { useContext } from "react";
import { CommonHeading } from "../other/Headingtext-Btn";
import HomeContext from "../Data/Context";
import ServiceItem from "./ServiceItem";
const ServiceSection = ()=>{
    const serviceSecData = useContext(HomeContext)
    const datapath = serviceSecData.our_services_section
    return(
        <>
            <section className="service-sec-heading common-spacing-top-bottom">
                <div className="container text-center">
                    <CommonHeading mainHeading={datapath.heading} subHeading={datapath.subheading} />
                </div>
            </section>  
            <section className="services">               
                {datapath.Services.map((val)=>{
                    return(                    
                        <ServiceItem    
                            key={val.id}                        
                            image={val.media}
                            title = {val.heading}
                            shorDesc = {val.content}
                            link= {val.button_link}
                        />
                    )
                })}
                
                
            </section>   
        </>
    )
}
export default ServiceSection;
