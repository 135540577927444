import CommonSec from "../other/CommonSec";
import CommonBtn, { CommonHeading } from "../other/Headingtext-Btn";

const FlsIndustriesWhy = ({commonHeading,innerText,rightImg,children,styleClass, anchorLink}) => {
    return (
        <>
            <CommonSec sectionstyle={`flsIndustriesWhy darkGradiantBg ${styleClass}`} innerspace="inner-container">
                <div className="items d-flex align-items-center">
                    <div className="content-wrapper">
                        <div className="heading-wrapper">
                            <CommonHeading mainHeading={commonHeading}></CommonHeading>
                            <p>{innerText}</p>
                        </div>
                        
                        <div className={`listing-items d-flex mt-60`}>
                            {children}
                        </div>

                        <div className="btn-wrapper d-flex mt-30">
                            <CommonBtn link={anchorLink} text="Read More"></CommonBtn>
                        </div>
                    </div>
                    <div className="img-wrapper">
                        <img src={rightImg} alt="Choose Us" />
                    </div>
                </div>
            </CommonSec>
        </>
    )
}

const FlsIndustriesWhyItems = ({ title }) => {
    return (
        <>
            <div className="listing-item d-flex align-items-center">
                <p>{ title }</p>
            </div>
        </>
    )
}

export default FlsIndustriesWhy
export  {FlsIndustriesWhyItems}