import CommonSec from "../other/CommonSec";
import { CommonHeading } from "../other/Headingtext-Btn";

const FlsChooseUs = ({heading,subHeading,galleryImg,galleryImgAlt,children,styleClass})=>{
    return (
        <CommonSec sectionstyle={`flsChooseUs gradiant-bg ${styleClass}`} innerspace="inner-container">
            <div className="items d-flex align-items-center">
                <div className="content-wrapper">
                    <div className="heading-wrapper">
                        <CommonHeading mainHeading={heading}></CommonHeading>
                        <p className={subHeading === null ? 'mt-0' : ''}>{subHeading}</p>
                    </div>
                    
                    <div className={`listing-items d-flex mt-60`}>
                        {children}
                    </div>
                </div>
                <div className="img-wrapper">
                    <img src={galleryImg} alt={galleryImgAlt} />
                </div>
            </div>
        </CommonSec>
    )
}

const FlsChooseUsItems = ({ title, icon,iconAlt }) => {
    return (
        <>
            <div className="listing-item d-flex align-items-center">
                <div className="icon-wrapper">
                    <img src={icon} alt={iconAlt} />
                </div>
                <p>{ title }</p>
            </div>
        </>
    )
}
export default FlsChooseUs;
export {FlsChooseUsItems};