import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import HomeData from '../../../components/Data/HomeData'
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from '../../../components/other/Headingtext-Btn'
import AboutOverview from '../../../components/about/AboutOverview'
import UspsSection from '../../../components/other/UspsSection'
import VinsysJourney from '../../../components/about/VinsysJourney'
import { PartnersOtherModule } from '../../home/PartnersClients'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import AwardsAccolades from '../../../components/about/AwardsAccolades'
import ErrorSection from '../../../components/other/ErrorSection';
import ApiPath from '../../../components/other/ApiPath';
const DigilearnAboutOverviewPage = () => {
    $("body").addClass("inner-page")
    const [aboutoverviewdata, setaboutoverviewdata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const aboutoverviewpagedata = await fetch(`${ApiPath()}/get-about-overview-details`);
                const currentaboutoverviewpagedata = await aboutoverviewpagedata.json();
                setaboutoverviewdata(currentaboutoverviewpagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!aboutoverviewdata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{aboutoverviewdata.seo != null && aboutoverviewdata.seo.meta_title != null ? aboutoverviewdata.seo.meta_title : aboutoverviewdata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={aboutoverviewdata.seo != null && aboutoverviewdata.seo.meta_key != null ? aboutoverviewdata.seo.meta_key : aboutoverviewdata.title} />

                <meta name="description" content={aboutoverviewdata.seo != null && aboutoverviewdata.seo.meta_description != null ? aboutoverviewdata.seo.meta_description : aboutoverviewdata.title} />

                <meta property="og:title" content={aboutoverviewdata.seo != null && aboutoverviewdata.seo.meta_title != null ? aboutoverviewdata.seo.meta_title : aboutoverviewdata.title} />

                <meta property="og:description" content={aboutoverviewdata.seo != null && aboutoverviewdata.seo.meta_description != null ? aboutoverviewdata.seo.meta_description : aboutoverviewdata.title} />

                <meta property="og:image" content={aboutoverviewdata.seo != null && aboutoverviewdata.seo.og_image != null ? aboutoverviewdata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {aboutoverviewdata.seo != null && aboutoverviewdata.seo.schema_list != null ? aboutoverviewdata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail} 
                        </script>
                    )
                }) : ''}
            </Helmet>

            <ScrollToTop />
            {aboutoverviewdata.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={aboutoverviewdata.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={aboutoverviewdata.banner.heading} />
                        <p>
                            {aboutoverviewdata.banner.subheading}
                        </p>
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }
            {aboutoverviewdata.introduction != null
                ?
                <AboutOverview style="delivery-about-sec" image={aboutoverviewdata.introduction.gallery !=null ? aboutoverviewdata.introduction.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} >
                    <CommonHeading mainHeading={aboutoverviewdata.introduction.heading} />
                    <div dangerouslySetInnerHTML={{ __html: aboutoverviewdata.introduction.content }} />
                </AboutOverview>
                :
                ""
            }
            {aboutoverviewdata.usps_section !=null && aboutoverviewdata.usps !=null
                ?
                <UspsSection sectionheading={aboutoverviewdata.usps_section.heading} sectionshortdescription={aboutoverviewdata.usps_section.subheading}>
                    {aboutoverviewdata.usps.map((val, index) => {
                        return (
                            <div class="item" key={index}>
                                <div class="icon-wrapper">
                                    <img src={val.thumb_image} alt={val.thumb_alt != null ? val.thumb_alt : val.heading} />
                                </div>
                                <div class="small-heading">
                                    {val.heading}
                                </div>
                            </div>
                        )
                    })}
                </UspsSection>
                :
                ""
            }
            {aboutoverviewdata.ld_journey !=null && aboutoverviewdata.ld_journey.page_list !=null
                ?
                <VinsysJourney title={aboutoverviewdata.ld_journey.heading} subTitle={aboutoverviewdata.ld_journey.subheading}>
                    {aboutoverviewdata.ld_journey.page_list.map((val, index) => {
                        return (
                            <li className="wow zoomIn" data-wow-delay={`${index}00ms`} key={index}>
                                <div className="content">
                                    <div className="year">{val.heading}</div>
                                    <p>{val.content}</p>
                                </div>
                            </li>
                        )
                    })}
                </VinsysJourney>
                :
                ""
            }
            {aboutoverviewdata.partners_section !=null && aboutoverviewdata.partners_section.partners !=null
                ?
                <PartnersOtherModule heading={aboutoverviewdata.partners_section.heading} subheading={aboutoverviewdata.partners_section.subheading}>
                    {aboutoverviewdata.partners_section.partners.map((val,index) => {
                        return (
                            <div className="item" key={index}>
                                <img src={val.media} alt={val.alt} />
                            </div>
                        )
                    })}
                </PartnersOtherModule>
                :
                ""
            }
            <DigiGetInTouch />
            {/* <AwardsAccolades title="Awards">
                    <li className="item">
                        <div className="content-box">
                            Premium training partner for over 40 Fortune 500 companies.
                        </div>
                    </li>
                    <li className="item">
                        <div className="content-box">
                            Best Distributor Autodesk Education for 3 years in a row; I.e.2015, 2016, 2017.
                        </div>
                    </li>
                    <li className="item">
                        <div className="content-box">
                            IT Governance framework implementation for one the most prestigious organizations in Gulf, “Dubai Customs”.
                        </div>
                    </li>
                    <li className="item">
                        <div className="content-box">
                            Key Project Consultant Organization for State Government, India.
                        </div>
                    </li>
                    <li className="item">
                        <div className="content-box">
                            Elected as Skill India Partner for 3 state governments, India.
                        </div>
                    </li>
                    <li className="item">
                        <div className="content-box">
                            IT Service Management Implementation Partner for Vietnam Stock Exchange.
                        </div>
                    </li>
                    <li className="item">
                        <div className="content-box">
                            Major & vital Projects for Organizations like Saudi Telecom, BOT, Bank Muscat, NBD, IBM, Accenture, Mphasis Exclusive Delivery Partner for Hybris, A SAP Entity
                        </div>
                    </li>
                    <li className="item">
                        <div className="content-box">
                            Selected as ‘The IT & Technical Training Watch List Company 2022’ by Training Industry.
                        </div>
                    </li>
                </AwardsAccolades> */}

        </>
    )
}
export default DigilearnAboutOverviewPage