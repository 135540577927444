import { CommonHeading } from "../other/Headingtext-Btn"
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import ApiPath from "../other/ApiPath";
import Cookies from 'js-cookie';
const BlogDetail = () => {
    const TokensCodes = Cookies.get('access_token');
    let { userId } = useParams();
    const [blogdetaildata, setBlogDetailData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                //Blog Page Data
                const blogdetailspagedata = await fetch(`${ApiPath()}/get_blog_by_slug/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }

                });
                const currentblogdetailspagedata = await blogdetailspagedata.json();
                setBlogDetailData(currentblogdetailspagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);


    console.log("blogdetaildata", blogdetaildata)

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!blogdetaildata) {
        return <div className='text-center'>No data available.</div>;
    }
    const postTitle = blogdetaildata.title;
    const postUrl = window.location.href;
    return (
        <>
            <Helmet>
                <title>{blogdetaildata.meta_title != null ? blogdetaildata.meta_title : blogdetaildata.title}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content={blogdetaildata.meta_key != null ? blogdetaildata.meta_key : blogdetaildata.title} />
                <meta name="description" content={blogdetaildata.meta_description != null ? blogdetaildata.meta_description : blogdetaildata.title} />
                <meta property="og:title" content={blogdetaildata.meta_title != null ? blogdetaildata.meta_title : blogdetaildata.title} />
                <meta property="og:description" content={blogdetaildata.meta_description != null ? blogdetaildata.meta_description : blogdetaildata.title} />
                <meta property="og:image" content={blogdetaildata.seo != null && blogdetaildata.seo.og_image != null ? blogdetaildata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />
                <meta property="og:url" content={window.location.href} />
                {blogdetaildata.blogschema != null
                    ?
                    blogdetaildata.blogschema.map((val) => {
                        return (
                            <script type="application/ld+json">
                                {val.detail}
                            </script>
                        )
                    })
                    : ''}
            </Helmet>
            <section className="blogDetail-sec common-spacing-top-bottom">
                <div className="inner-container">
                    <div className="img-box">
                        <img className="w-100" src={blogdetaildata.banner} alt="Blog" />
                        {/* <div className="label">{blogdetaildata.our_service.title}</div> */}
                    </div>
                    <div className="content-box">
                        <div className="blog-title">
                            {blogdetaildata.tags.map((val) => {
                                return (
                                    <span>{val.title}</span>
                                )
                            })}
                        </div>
                        <CommonHeading mainHeading={blogdetaildata.title} />
                        {/* <div className="shareLinks d-flex align-items-center justify-content-between">
                            <div className="share-sec d-flex align-items-center">
                                <div className="label">Share Now</div>
                                <ul className="d-flex align-items-center">
                                    <li>
                                        <Link to={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postUrl)}`} target="_blank">
                                            <img src={`${process.env.PUBLIC_URL}/images/social-icons/icon-facebook.svg`} alt="Facebook" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`https://twitter.com/share?url=${encodeURIComponent(postUrl)}&text=${encodeURIComponent(postTitle)}`} target="_blank"><img src={`${process.env.PUBLIC_URL}/images/social-icons/icon-twitter.svg`} alt="Twitter" /></Link>
                                    </li>
                                    <li>
                                        <Link to={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(postUrl)}`} target="_blank">
                                            <img src={`${process.env.PUBLIC_URL}/images/social-icons/icon-linkedin.svg`} alt="Linkedin" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={`https://api.whatsapp.com/send?text=${encodeURIComponent(postTitle + " " + postUrl)}`} target="_blank">
                                            <img src={`${process.env.PUBLIC_URL}/images/social-icons/icon-whatsapp.svg`} alt="Whatsapp" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="last-modified-date">{blogdetaildata.modify_date != null ? `Last Modified:${blogdetaildata.modify_date}` : ''} </div>
                        </div> */}
                    </div>
                    <div className="detail-wrapper" dangerouslySetInnerHTML={{ __html: blogdetaildata.content }}>

                    </div>
                    <div className="name-wrapper d-flex">
                        <div className="user-img">
                            {blogdetaildata.author != null ? <img src={blogdetaildata.author.image} alt={blogdetaildata.author.alt != null ? blogdetaildata.author.alt : blogdetaildata.author.name} /> : <img src={`${process.env.PUBLIC_URL}/images/blog-author-default.png`} alt="Vinsys" />}
                        </div>
                        <div className="name">
                            {blogdetaildata.author != null ? blogdetaildata.author.name : "Vinsys"}
                            {blogdetaildata.author !== null && blogdetaildata.author.linkedin !== null && blogdetaildata.author.linkedin !== " " &&  <span><a href={blogdetaildata.author.linkedin} target="_blank">LinkedIn</a></span>}

                            <span>{blogdetaildata.published_date}</span>

                            {/* {blogdetaildata.author != null ? blogdetaildata.author.name : "Vinsys"} {blogdetaildata.author != null && blogdetaildata.linkedin != null && <span><a href={blogdetaildata.author.linkedin} target="_blank">LinkedIn</a></span>} <span>{blogdetaildata.published_date}</span> */}
                            <div className="description" dangerouslySetInnerHTML={{ __html: blogdetaildata.author != null ? blogdetaildata.author.about : '' }} />
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}
export default BlogDetail;