import { CommonHeading } from "../other/Headingtext-Btn";
const JoinTeamSec = ({children,title,subTitle,image})=>{
    return(
        <section className="join-team-sec common-spacing-top-bottom">
            <div className="inner-container d-flex justify-content-between">
                <div className="left">
                    <CommonHeading
                        mainHeading={title}
                        subHeading={subTitle}
                    />
                    {children}                    
                </div>
                <div className="right">
                    <img className="w-100" src={image} alt="" />
                </div>
            </div>
        </section>
    )
}
export default JoinTeamSec;