import CommonSec from "../other/CommonSec";
import { CommonHeading } from "../other/Headingtext-Btn";

const FlsWhatOffer = ({styleClass,commonHeading,innerText,children,bgColor})=>{
    return (
        <CommonSec sectionstyle={`flsWhatOffer common-spacing-top-bottom ${bgColor}`} innerspace="inner-container">
            <div className="heading-wrapper text-center">
                <CommonHeading mainHeading={commonHeading}></CommonHeading>
                <p className={innerText === null ? 'mt-0' : ''}>{innerText}</p>
            </div>
            
            <div className={`items d-flex mt-60 ${styleClass}`}>
                {children}
            </div>
        </CommonSec>
    )
}

const FlsWhatOfferItems = ({ title,icon,iconAlt,itemKey }) => {
    return (
        <>
            <div className={`item item-${itemKey}`}>
                <div className="heading">{ title }</div>
                <div className="img-wrapper">
                    <img src={icon} alt={iconAlt} />
                </div>
            </div>
        </>
    )
}
export default FlsWhatOffer;
export {FlsWhatOfferItems};