import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../../components/other/ScrollToTop"
import { CommonHeading } from "../../../components/other/Headingtext-Btn"
import AboutOverview from "../../../components/about/AboutOverview"
import DigiHomeBanner, { BannerUsps } from "../../../components/digilearn/Home/DigiHomeBanner"
import DigiOurSolution, { DigiOurSolutionItem } from "../../../components/digilearn/Home/OurSolution"
import DigiVideoSec from "../../../components/digilearn/Home/DigiVideoSec"
import { PartnersOtherModule } from "../../home/PartnersClients"
import DigiGetInTouch from "../../../components/digilearn/Home/DigiGetInTouch"
import ApiPath from '../../../components/other/ApiPath';
import ErrorSection from '../../../components/other/ErrorSection';
import RatingAndStar from '../../../components/other/RatingAndStar';

const DigilearnSolutionsHomePage = () => {
    $("body").removeClass("inner-page")
    const [digilearndata, setdigilearndata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const digilearnpagedata = await fetch(`${ApiPath()}/get-digilerning-home-page-details`);
                const currentdigilearnpagedata = await digilearnpagedata.json();
                setdigilearndata(currentdigilearnpagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!digilearndata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }

    console.log("digilearndata",digilearndata,digilearndata.star,digilearndata.rating)
    return (
        <>
            <Helmet>
                <title>{digilearndata.seo != null && digilearndata.seo.meta_title != null ? digilearndata.seo.meta_title : digilearndata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={digilearndata.seo != null && digilearndata.seo.meta_key != null ? digilearndata.seo.meta_key : digilearndata.title} />

                <meta name="description" content={digilearndata.seo != null && digilearndata.seo.meta_description != null ? digilearndata.seo.meta_description : digilearndata.title} />

                <meta property="og:title" content={digilearndata.seo != null && digilearndata.seo.meta_title != null ? digilearndata.seo.meta_title : digilearndata.title} />

                <meta property="og:description" content={digilearndata.seo != null && digilearndata.seo.meta_description != null ? digilearndata.seo.meta_description : digilearndata.title} />

                <meta property="og:image" content={digilearndata.seo != null && digilearndata.seo.og_image != null ? digilearndata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {digilearndata.seo != null && digilearndata.seo.schema_list != null ? digilearndata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            {digilearndata.banner != null
                ?
                <DigiHomeBanner bannerimage={digilearndata.banner.media} bannerimagealt={digilearndata.banner.alt}
                    bannertextshort={<div dangerouslySetInnerHTML={{ __html: digilearndata.banner.subheading }} />}
                    bannertextmain={<div dangerouslySetInnerHTML={{ __html: digilearndata.banner.heading }} />} bannerbtntext={digilearndata.banner.button_text} bannerbtnlink={digilearndata.banner.url}>
                    {digilearndata.usps != null
                        ?

                        digilearndata.usps.map((val, key) => {
                            return (
                                <BannerUsps count={key} uspcount={val.heading} uspicon={val.thumb_image} uspname={<div dangerouslySetInnerHTML={{ __html: val.content }} />} />
                            )
                        })
                        :
                        ""
                    }
                </DigiHomeBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }


            {digilearndata.why_vinsys != null
                ?
                <AboutOverview style="delivery-about-sec" image={digilearndata.why_vinsys.gallery != null ? digilearndata.why_vinsys.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`}  star={Number(digilearndata.star)} rating={Number(digilearndata.rating)}>
                    <CommonHeading mainHeading={digilearndata.why_vinsys.heading} />
                   
                    
                    <div dangerouslySetInnerHTML={{ __html: digilearndata.why_vinsys.content }} />
                </AboutOverview>
                :
                ""
            }

           

            {digilearndata.our_solutions.page_list != null
                ?
                <DigiOurSolution sectionheading={digilearndata.our_solutions.heading} sectionshortdescription={digilearndata.our_solutions.subheading}>
                    {digilearndata.our_solutions.page_list.map((val, index) => {
                        return (
                            <DigiOurSolutionItem key={index} solutionimage={val.media} solutiontitle={val.heading} solutiondesc={val.content} solutionlink={val.url} />
                        )
                    })}
                </DigiOurSolution>
                :
                ""
            }
            {/* <DigiVideoSec videoimage={digilearndata.gif_image.gallery!=null ? digilearndata.gif_image.gallery[0].media : `${process.env.PUBLIC_URL}'/images/digi-video.png`} /> */}
            {digilearndata.partners_section.partners != null
                ?
                <PartnersOtherModule heading={digilearndata.partners_section.heading} subheading={digilearndata.partners_section.subheading}>
                    {digilearndata.partners_section.partners.map((val, index) => {
                        return (
                            <div className="item" key={index}>
                                <img src={val.media} alt={val.alt} />
                            </div>
                        )
                    })}
                </PartnersOtherModule>
                :
                ""
            }
            <DigiGetInTouch />

        </>
    )
}
export default DigilearnSolutionsHomePage