import $, { event } from 'jquery'; 
import ContactTopSec from '../../components/contact/ContactTopSec';
import GlobalPresence from '../../components/contact/GlobalPresence.';
import ScrollToTop from '../../components/other/ScrollToTop';
import ContactDataMain from '../../components/Data/ContactData';
const ContactPage = ()=>{
    $("body").addClass("inner-page") 
    return(
        <>           
            <ContactDataMain>
                <ScrollToTop/>
                <ContactTopSec/>
                <GlobalPresence/>
            </ContactDataMain>
       </>
    )
}

export default ContactPage