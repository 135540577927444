import { Link } from "react-router-dom";
import { CommonHeading } from "../../other/Headingtext-Btn"

const DigiOurSolution = ({children,sectionheading,sectionshortdescription}) => {
    return (
        <section className="digilearn-solution-sec common-spacing-top-bottom">
            <div className="inner-container">
                <div className="text-center">
                    <CommonHeading mainHeading={sectionheading} />
                    <p>
                        {sectionshortdescription}
                    </p>
                </div>
                <ul className="solutions d-flex">
                    {children}
                </ul>
            </div>
        </section>
    )
}
const DigiOurSolutionItem = ({solutionimage,solutiontitle,solutiondesc,solutionlink}) => {
    return (
        <li>
            <div className="image">
                <img className="w-100" src={solutionimage} alt="" />
            </div>
            <div className="title">{solutiontitle}</div>
            <div className="overlay">
                <div className="inner">
                    <div className="solution-name">{solutiontitle}</div>
                    <div className="desc" dangerouslySetInnerHTML={{ __html: solutiondesc}}/>
                </div>
                <div className="arrow">
                    <Link className="btn" to={solutionlink}><img src={`${process.env.PUBLIC_URL}/images/icon-arrow-btn.png`} alt="" /></Link>
                </div>
            </div>
        </li>
    )
}
export default DigiOurSolution;
export {DigiOurSolutionItem}