import React, {useState, useEffect} from "react";
import {EventContext} from './Context';
import ApiPath from "../other/ApiPath";
import Cookies from 'js-cookie';
const EventDetailDataMain = ({children, slugId})=>{
    const TokensCodes = Cookies.get('access_token'); 
    const [eventsDetaildata, seteventsDetaildata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
        try {
            //Events Detail Page Data
            const eventDetailpagedata = await fetch(`${ApiPath()}/get_event_by_slug/${slugId}`, {
                headers: {
                    'Authorization': `Bearer ${TokensCodes}`
                }

            });                      
            const currenteventDetailpagedata = await eventDetailpagedata.json();            
            seteventsDetaildata(currenteventDetailpagedata);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            }
    };
    fetchData();
    }, [TokensCodes]);

    if (loading) {
        return(
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!eventsDetaildata) {
        return <div className='text-center'>No data available.</div>;
    } 
    return(
        <EventContext.Provider value={eventsDetaildata}>
            {children}
        </EventContext.Provider>
    )
}
export default EventDetailDataMain