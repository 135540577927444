import InnerBanner from "../../../components/other/InnerBanner";
import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import ScrollToTop from "../../../components/other/ScrollToTop";
import AboutOverview from "../../../components/about/AboutOverview"
import { CommonHeading } from "../../../components/other/Headingtext-Btn";
import CousreHighlights from "../../../components/digilearn/CousreDetail/CousreHighlights";
import CommonDescription from "../../../components/training/CommonDescription";
import FeatureCourses from "../../../components/digilearn/CousreDetail/FeatureCourses";
import ApiPath from "../../../components/other/ApiPath";
import DigiGetInTouch from "../../../components/digilearn/Home/DigiGetInTouch";
const CousreDetailPage = ()=>{
    $("body").addClass("inner-page")   
    let { courseId } = useParams();
    const [godigitaldetaildata, setgodigitaldetaildata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const godigitaldetailpagedata = await fetch(`${ApiPath()}/get_cource_by_slug/${courseId}`);
                if (!godigitaldetailpagedata.ok) {
                    throw new Error("Request failed"); // You can customize this error message
                }    
                const currentgodigitaldetailpagedata = await godigitaldetailpagedata.json();
                setgodigitaldetaildata(currentgodigitaldetailpagedata);
                setLoading(false);
                
            }
           
            catch (error) {
                console.error('Error fetching data:', error);  
                window.location.href="error/not-found"
                console.log("not load")  

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!godigitaldetaildata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return(
        <>
            <Helmet>
                <title>{godigitaldetaildata.meta_title != null ? godigitaldetaildata.meta_title : godigitaldetaildata.heading}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={godigitaldetaildata.meta_key != null ? godigitaldetaildata.meta_key : godigitaldetaildata.heading} />

                <meta name="description" content={godigitaldetaildata.meta_description != null ? godigitaldetaildata.meta_description : godigitaldetaildata.heading} />

                <meta property="og:title" content={godigitaldetaildata.meta_title != null ? godigitaldetaildata.meta_title : godigitaldetaildata.heading} />

                <meta property="og:description" content={godigitaldetaildata.meta_description != null ? godigitaldetaildata.meta_description : godigitaldetaildata.heading} />

                <meta property="og:image" content={godigitaldetaildata.og_image != null ? godigitaldetaildata.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />                
                {godigitaldetaildata.schema_list != null ? godigitaldetaildata.schema_list.map((val)=>{
                    return(
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
                
            </Helmet>
            <ScrollToTop />  
            
            <InnerBanner style="digi-inner-banner" image={godigitaldetaildata.banner_image}>
                <div className='text-center'>
                    <CommonHeading mainHeading={godigitaldetaildata.banner_text} />                    
                </div>                
            </InnerBanner>
            
            <AboutOverview style="delivery-about-sec" image={godigitaldetaildata.introduction_image} star={Number(godigitaldetaildata?.star)} rating={Number(godigitaldetaildata?.rating)}>
                <CommonHeading mainHeading="Introduction" />
                <div className="description" dangerouslySetInnerHTML={{ __html: godigitaldetaildata.introduction_content}} />                 
            </AboutOverview>
            {godigitaldetaildata.course_highlights !==""
                ?  
                <CousreHighlights sctionTitle="Course Highlights">
                    <div className="description" dangerouslySetInnerHTML={{ __html: godigitaldetaildata.course_highlights}} />  
                </CousreHighlights>   
                :
                ""
            }
            {godigitaldetaildata.feature != null
                ?         
                <FeatureCourses sctionTitle={godigitaldetaildata.feature_heading}>
                    {godigitaldetaildata.feature.map((val,index)=>{
                        return(
                            <li key={index}>
                                <div className="icon d-flex justify-content-center align-items-center"><img src={val.icon} alt=""/></div>
                                <div className="title">{val.title}</div>
                            </li>  
                        )
                    })}
                                
                </FeatureCourses>
                :
                ""
            }
            {godigitaldetaildata.content !==""
                ?  
                <CommonDescription btnreq="no-require">                
                    <div className="description" dangerouslySetInnerHTML={{ __html: godigitaldetaildata.content}} />  
                </CommonDescription>
                :
                ""
            }
            <DigiGetInTouch/>            
        </>
    )
}
export default CousreDetailPage

