import { Link } from "react-router-dom"
import React, { useState, useEffect } from 'react';
import ApiPath from '../components/other/ApiPath';
import Cookies from 'js-cookie';

const DigiLearnMenu = (props) => {

    const TokensCodes = Cookies.get('access_token');
    const [isActive, setIsActive] = useState(false);
    const handleToggle = () => {
        setIsActive(!isActive);
    };
    const [digilearnmenu, setDigiLearnMenu] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {

                //Header Menu Data
                const digiLearnMenu = await fetch(`${ApiPath()}/digilearn_header_menu/`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }

                });
                const Currentdigilearnmenu = await digiLearnMenu.json();
                setDigiLearnMenu(Currentdigilearnmenu);
                setLoading(false);
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [TokensCodes]);
    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }
    if (!digilearnmenu) {
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <div className={props.pagemenu}>
            <div className="close-icon"><img src={`${process.env.PUBLIC_URL}/images/icon-close.png`} alt="close" /></div>
            <div className="top">
                <ul className=" d-flex justify-content-end">
                <li><a href="mailto:enquiry@vinsys.ae" className="email">enquiry@vinsys.ae</a></li>
                <li><a href="tel:+971 -4-3524949" className="number">+971 -4-3524949</a></li>
                </ul>
            </div>
            <div className="bottom">
                <ul className='d-flex justify-content-end'>
                    <li className='single-menu' ><Link to="digital-learning-services/">Home</Link></li>
                    {digilearnmenu.map((val) => {
                        if (val.child_menu === null) {
                            return (
                                <li className='single-menu' key={val.id}><Link to={`digital-learning-services/${val.url}`}>{val.title}</Link></li>
                            )
                        }
                        else {
                            return (
                                
                                <li className='dropdown-menus' key={val.id}><Link to={val.url} >{val.title}</Link>
                                    <span className='arrow-btn' onClick={handleToggle}></span>
                                    <ul className={`dropdown ${isActive ? 'active' : 'non-active'}`}>
                                        {val.child_menu.map((val) => {
                                            return (
                                                <li key={val.id}><Link to={`digital-learning-services/${val.url}`}>{val.title}</Link></li>
                                            )

                                        })}
                                    </ul>
                                </li>
                            )
                        }
                    })}
                </ul>
            </div>
        </div>
    )
}
export default DigiLearnMenu