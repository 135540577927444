import CommonSec from "../../components/other/CommonSec";
import { CommonHeading } from "../../components/other/Headingtext-Btn"
const FlsOffers = ({heading,subHeading,galleryImg,galleryImgAlt,children}) => {
    return (
        <>
            <CommonSec sectionstyle="flsRightContent dark-bg" innerspace="inner-container">
                <div className="heading-wrapper text-center">
                    <CommonHeading mainHeading={heading} />
                    <p>{subHeading}</p>                    
                </div>
                <div className='items d-flex align-items-center mt-60'>
                    <div className='item img-wrapper'>
                        <img src={galleryImg} alt={galleryImgAlt} />
                    </div>
                    <div className='item content-wrapper'>
                        {children}
                    </div>
                </div>
            </CommonSec>
        </>
    )
}

export default FlsOffers