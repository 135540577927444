import React, { useState, useEffect } from 'react';
import HeaderFun from './headerfun';
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import $ from 'jquery';
import EnquiryForm from '../components/other/EnquiryForm';
import TrainingMenu from './TrainingMenu';
import ApiPath from '../components/other/ApiPath';
import DigiLearnMenu from './DigiLearnMenu';
import FlsMenu from './flsmenu';
import NewBreadCrum from '../components/breadcrumb/BreadCrumbs';
import ChildMenu from '../components/childmenu/ChildMenu';

const Header = () => {

    HeaderFun()
    const location = useLocation();
    const { pathname } = location;
    const isCurrentPath = (path) => {
        return location.pathname === path;
    };
    const TokensCodes = Cookies.get('access_token');



    $(window).scroll(function () {
        var sc = $(window).scrollTop()
        if (sc > 100) {
            $("header.header").addClass("small")
        } else {
            $("header.header").removeClass("small")

        }
    });

    /*$(document).ready(function () {
        $('.toggle-icon').on('click', function () {
            $('.nav-sec').addClass('active');
        });
        $('.close-icon,.nav-sec ul li a').on('click', function () {
            $('.nav-sec').removeClass('active');
        });
        $('span.arrow-btn').on('click', function () {
            $(this).closest('.dropdown-menus').find('.dropdown.non-active').slideDown()
            $(this).closest('.dropdown-menus').find('.dropdown.active').slideUp()
        })
    });*/
    // const [isActive, setIsActive] = useState(false);
    // const handleToggle = () => {
    //     setIsActive(!isActive);
    // };
    const [enqPos, setenqPos] = useState(false);
    const [Headerdata, setHeaderData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {

                //Header Menu Data
                const headerdata = await fetch(`${ApiPath()}/header_menu/`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }
                });
                const Currentheaderdata = await headerdata.json();
                setHeaderData(Currentheaderdata);
                setLoading(false);
                
                /*const headerdata = await fetch(`${ApiPath()}/header_menu/`)        
                const Currentheaderdata = await headerdata.json();
                setHeaderData(Currentheaderdata);
                setLoading(false);*/

            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [TokensCodes]);
    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }
    if (!Headerdata) {
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <aside className={`enq d-flex ${isCurrentPath('/contactus') || isCurrentPath('/thank-you') ? 'no-require' : ''}`}>
                <button className="common-btn enq" onClick={() => { setenqPos(true) }}>Enquire Now <i className="icon">&nbsp;</i></button>
                {/* <a className="common-btn " href="/"><img src={`${process.env.PUBLIC_URL}/images/icon-search.png`} alt=''/></a>                */}
            </aside>
            <EnquiryForm style={`enquiry-form-sec ${enqPos == true ? 'active' : ''}`}>
                <div className="heading">ENQUIRE NOW</div>
                <div className="close-btn" onClick={() => { setenqPos(false) }}>X</div>
            </EnquiryForm>
            <header className={`header ${pathname.includes('/training/') || pathname.includes('/digital-learning-services') ? 'megaMenu' : ''} ${pathname.includes('/foreign-language-services') ? 'megaMenu flsMenu' : ''} `}>
                <div className="container d-flex justify-content-between align-items-center">
                    <div className="logo">
                        <Link to=".">
                            <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="" />
                        </Link>
                    </div>
                    <div className="toggle-icon"><img src={`${process.env.PUBLIC_URL}/images/icon-toggle.png`} alt="toggle" /></div>
                    {/* Main Page Menu */}
                    
                    <div className={`nav-sec ${pathname.includes('/training/') || pathname.includes('/digital-learning-services') || pathname.includes('/foreign-language-services') ? 'no-require' : 'require'}`}>
                        <div className="close-icon"><img src={`${process.env.PUBLIC_URL}/images/icon-close.png`} alt="close" /></div>
                        <div className='bottom'>
                            <ul className='d-flex justify-content-end'>
                                {Headerdata.map((val) => {
                                    if (val.child_menu === null) {
                                        return (
                                            <li className='single-menu' key={val.id}><Link to={val.url} target={val.menu_type == 'url' ? '_blank' : ''}>{val.title}</Link></li>
                                        )
                                    }
                                    else {
                                        return (
                                            <ChildMenu val={val} key={val.id} />
                                        )
                                    }
                                })}
                            </ul>
                        </div>
                    </div>
                    
                    {/* Training Page Menu */}
                    <TrainingMenu pagemenu={`nav-sec ${pathname.includes('/training/') ? 'megaMenu require' : 'no-require'}`}/>
                    {/* Digital Page Menu */}
                    <DigiLearnMenu pagemenu={`nav-sec ${pathname.includes('/digital-learning-services') ? 'megaMenu light-theme require' : 'no-require'}`}/>
                    {/* FLS Page Menu */}
                    <FlsMenu pagemenu={`nav-sec ${pathname.includes('/foreign-language-services') ? 'megaMenu require' : 'no-require'}`}/>


                </div>
                
            </header>
            
           
        </>
    )
}
export default Header;