import React, { useState, useEffect } from "react";
import InnerBanner from "../../components/other/InnerBanner"
import ScrollToTop from "../../components/other/ScrollToTop"
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import ApiPath from "../../components/other/ApiPath";
import $ from 'jquery'
const PrivacyPolicy = () => {   
    $("body").addClass("inner-page")
    const TokensCodes = Cookies.get('access_token'); 
    const [privacypolicydata, setPrivacyPolicyData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                //Contact Page Data
                const privacypolicydatas = await fetch(`${ApiPath()}/get-privacy-policy-page-details`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }
    
                });           
                const currentprivacypolicydata = await privacypolicydatas.json();
                setPrivacyPolicyData(currentprivacypolicydata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [TokensCodes]);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!privacypolicydata) {
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{privacypolicydata.seo!=null && privacypolicydata.seo.meta_title!=null ? privacypolicydata.seo.meta_title : privacypolicydata.title}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content={privacypolicydata.seo !=null && privacypolicydata.seo.meta_key!=null ? privacypolicydata.seo.meta_key:privacypolicydata.title } />
                <meta name="description" content={privacypolicydata.seo!=null  && privacypolicydata.seo.meta_description!=null ? privacypolicydata.seo.meta_description : privacypolicydata.title} />
                <meta property="og:title" content={privacypolicydata.seo !=null && privacypolicydata.seo.meta_title != null ? privacypolicydata.seo.meta_title : privacypolicydata.title} />
                <meta property="og:description" content={privacypolicydata.seo !=null && privacypolicydata.seo.meta_description !=null ? privacypolicydata.seo.meta_description : privacypolicydata.title} />               
                <meta property="og:image" content={privacypolicydata.seo!=null && privacypolicydata.seo.og_image!=null ? privacypolicydata.seo.og_image  :  `${process.env.PUBLIC_URL}/images/logo.png`} />
                <meta property="og:url" content={window.location.href} />                       
            </Helmet>
            <ScrollToTop />
            <InnerBanner image={privacypolicydata.banner!=null ? privacypolicydata.banner.media : `${process.env.PUBLIC_URL}/images/banner-Privacy-Policy.jpg`} />
            <section className="textual-pages common-spacing-top-bottom">
                <div className="inner-container">
                    <div className="detail-wrapper" dangerouslySetInnerHTML={{ __html: privacypolicydata.content.content}}/> 
                </div>

            </section>
        </>
    )
}
export default PrivacyPolicy