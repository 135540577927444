import { CommonHeading } from "../other/Headingtext-Btn"
import CountryAddress, { CityAddress } from "./GlobalCityItem"
import { useContext } from "react"
import { ContactContext } from "../Data/Context"
const GlobalPresence = () => {
    const contactData = useContext(ContactContext)
    return (
        <section className="life-at-vinsys career-post-sec global-add common-spacing-top-bottom">
            <div className="inner-container">
                <CommonHeading
                    class="text-center"
                    mainHeading={contactData.our_global_presence.heading}
                />
                <ul className="d-flex">
                    {contactData.our_global_presence.location_list.map((val) => {
                         const countryCode = (`+${val.country.country_code}`);
                        return (
                            <CountryAddress image={val.country.image} title={val.country.name}>
                                {val.location.map((val)=>{
                                    return(
                                        <CityAddress call="" callNo={`${countryCode} ${val.phone_number}`} email={val.email}>
                                           {`${val.address}  ${val.postal_code !== null ?  "-" + val.postal_code : ''}`}
                                        </CityAddress>                                           
                                    )
                                })}                                
                            </CountryAddress>
                        )
                    })}
                </ul>
            </div>
        </section>
    )
}
export default GlobalPresence