
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CountryData, { CityData } from "./FooterData";
import ApiPath from "../components/other/ApiPath";
import axios from 'axios';
import $ from 'jquery'
import Cookies from 'js-cookie';
import { useGetApi } from "../utils/Customhooks/ApiCalls";


const SITE_KEY = process.env.REACT_APP_API_GOOGLE_RECAPTCHA_KEY;

const Footer = () => {



    const {
        data: getdata,
        isLoading:apiloading,
        error,
        doFetch,
      } = useGetApi("");

    const TokensCodes = Cookies.get('access_token');
    const [enqdata, setEnqData] = useState(null);
    const [errormessage, setErrorMessage] = useState(null);

    const [letstalkData, SetletstalkData] = useState({
        name: '',
        email: '',
        country: '',
        message: '',
        page_url: window.location.href
    });
    
    const [isLoading, setIsLoading] = useState(false);
    const HandlesChange = (event) => {
        SetletstalkData({ ...letstalkData, [event.target.name]: event.target.value })
        if (event.target.value.trim() === '') {
            event.target.value = ""
        }
    }
    const [Footerdata, setFooterData] = useState(null);
    const [Generladata, setGeneralData] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            try {
                const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
            
            } catch (error) {

                console.log("error",error)
                
            }
        }

        try {
            // load the script by passing the URL

            loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
                console.log("Script loaded!");
            });

        } catch (error) {
            console.log("error", error)

        }



        const fetchData = async () => {
            try {
                //Footer Menu Data            
                const footerdata = await fetch(`${ApiPath()}/footer_menu/`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }

                });
                const Currentfooterdata = await footerdata.json();
                setFooterData(Currentfooterdata);
                //General Data            
                const generaldata = await fetch(`${ApiPath()}/get-general-settings/`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }

                });
                const Currentgeneraldata = await generaldata.json();
                setGeneralData(Currentgeneraldata);

                const enqpagedata = await fetch(`${ApiPath()}/get_all_counrty`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }

                });
                const currentenqpagedata = await enqpagedata.json();
                setEnqData(currentenqpagedata);

                setLoading(false);
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();


        return () => {
            setErrorMessage(null)
        }

    }, [TokensCodes]);

    if (loading) {
        return (
            console.log("LOading")
        );
    }

    if (!Footerdata, !Generladata) {
        return <div className='text-center'>No data available.</div>;
    }

    const handleOnClick = e => {
        e.preventDefault();
        setIsLoading(true);
        try {
            window.grecaptcha.ready(() => {
                try {
                    window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {

                        if (token) {

                            handleSubmit(token);
                        }
                    });

                } catch (error) {
                    
                    setIsLoading(false);

                    setErrorMessage("Security Check Failed");

                    doFetch("/googl-recaptcha-error")

                }

            })

        } catch (error) {
            
        }

    }

    const handleSubmit = (token) => {
        axios.post(`${ApiPath()}/post-lets-talk`, letstalkData, {
            headers: {
                'Authorization': `Bearer ${TokensCodes}`
            }

        })
            .then((response) => {
                // console.log('Form submitted successfully!', response.data);
                // Perform additional actions on successful submission
                window.location.href = '/thank-you'
                setIsLoading(false);

            })
            .catch((error) => {
                // console.error('Form submission failed!', error);
                // Handle the error state or display an error message
                setIsLoading(false);
            });
    }

    return (
        <footer className="footer">
            <div className="container">
                <div className="top d-flex justify-content-between align-items-center">
                    <div className="form">
                        <div className="title">Let’s Talk</div>
                        <form onSubmit={handleOnClick}>
                            <ul className="d-flex justify-content-between">
                                <li>
                                    <input type="text" onChange={HandlesChange} placeholder="Your Name" id="name" name="name" required />
                                    <input type="email" onChange={HandlesChange} placeholder="Your Email" id="uemail" name="email" required />
                                </li>
                                <li>
                                    <select onChange={HandlesChange} name="country" required>
                                        <option value="">Select Country</option>
                                        {enqdata.map((val) => {
                                            return (
                                                <option value={val.id} key={val.id}>{val.name}</option>
                                            )
                                        })}
                                    </select>
                                    <textarea name="message" onChange={HandlesChange} id="message" rows="2" placeholder="Your Message" required></textarea>
                                    <input type="checkbox" name="fax" style={{ display: 'none' }} />
                                </li>
                                <li className="w-100">
                                    <div className="links">
                                        <button type="submit" className="common-btn" disabled={isLoading}>Submit Now <i className="icon">&nbsp;</i></button>
                                    </div>

                                    <p className="errorMsg">{errormessage}</p>

                                </li>
                            </ul>
                        </form>
                    </div>
                    <div className="image">
                        <img className="w-100" src={`${process.env.PUBLIC_URL}/images/footer-image.png`} alt="" />
                    </div>
                </div>
                <div className="bottom  d-flex justify-content-between">
                    {Footerdata.contact_locaiton.map((val) => {
                        const countryCode = (`+${val.country.country_code}`);
                        return (

                            <CountryData iconImage={val.country.flag} country={val.country.name} key={val.country.id}>
                                {val.location.map((val) => {
                                    return (
                                        <CityData
                                            key={val.id}
                                            cityname={val.title}
                                            phone={`${countryCode} ${val.phone_number}`}
                                            emailText={val.email}
                                            addText={`${val.address}  ${val.postal_code !== null ?  "-" + val.postal_code : ''}`}
                                        />
                                    )
                                })}
                            </CountryData>
                        )
                    })}
                </div>
                <div className="bottom-footer d-flex justify-content-between align-items-center">
                    <div className="left">
                        {Generladata.copyright_text}
                    </div>
                    <div className="social-icons d-flex justify-content-center align-items-center">
                        <p>Follow Us: </p>
                        <a href={Generladata.facebook_url != null ? Generladata.facebook_url : 'https://www.facebook.com/vinsys'} target="_blank"><img src={Generladata.facebook_icon != null ? Generladata.facebook_icon : `${process.env.PUBLIC_URL}/images/social-fb.png`} alt="" /></a>

                        <a href={Generladata.twitter_url != null ? Generladata.twitter_url : 'https://twitter.com/VinsysOfficial'} target="_blank"><img src={Generladata.twitter_icon != null ? Generladata.twitter_icon : `${process.env.PUBLIC_URL}/images/social-twitter.png`} alt="" /></a>

                        <a href={Generladata.linkedin_url != null ? Generladata.linkedin_url : 'https://www.linkedin.com/company/vinsys/'} target="_blank"><img src={Generladata.linkedin_icon != null ? Generladata.linkedin_icon : `${process.env.PUBLIC_URL}/images/social-linkdin.png`} alt="" /></a>

                        <a href={Generladata.youtube_url != null ? Generladata.youtube_url : 'https://www.youtube.com/channel/UCRVoaxoz_NeN6_2EJqm5a3Q'} target="_blank"><img src={Generladata.youtube_icon != null ? Generladata.youtube_icon : `${process.env.PUBLIC_URL}/images/social-youtube.png`} alt="" /></a>
                    </div>
                    <div className="right">
                        <ul className="d-flex">
                            {Footerdata.menu_data.map((val) => {
                                return (
                                    <li key={val.id}><Link to={val.url}>{val.title}</Link></li>
                                )
                            })}

                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;