import React, { useState } from 'react';
import CommonBtn, { CommonHeading } from "../other/Headingtext-Btn"

const CareerPost = ({children, title, subTitle})=>{     
    return(
        <section className="career-post-sec common-spacing-top-bottom" id="posts">
            <div className="inner-container">
                <CommonHeading class="text-center" mainHeading={title} subHeading={subTitle}/>
                <ul className={`d-flex ${React.Children.count(children) === 0 ? 'nodata' : 'data'}`}>
                    {children}     
                </ul>
            </div>
        </section>
        
    )
    
}
export default CareerPost;