import CommonBtn from "../../other/Headingtext-Btn"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
const DigiHomeBanner = ({children,bannerimage,bannerimagealt,bannertextshort,bannertextmain,bannerbtntext,bannerbtnlink}) => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1200 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 1399, min: 1200 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 1199, min: 993 },
            items: 3,
        },
        tabletSmall: {
            breakpoint: { max: 992, min: 768 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 767, min: 0 },
            items: 1,
        }
    };
    return (
        <section className="home-banner digilearnMainBanner">
            <img src={bannerimage} alt={bannerimagealt} className="w-100 digiBannerImg" />
            <div className="banner-text text-center digi-banner">
                <div className="inner-container">
                    <div className="heading digihome-banner">
                        <div>
                            <span>{bannertextshort}</span>
                            {bannertextmain}
                        </div>
                        <div className="links">
                            <CommonBtn link={bannerbtnlink} text={bannerbtntext} />
                        </div>
                    </div>
                    <div className="our-usps">
                        <Carousel responsive={responsive} arrows={false} swipeable={false} draggable={false} showDots={true} autoPlay={true}>
                            {children}
                        </Carousel>
                    </div>
                </div>
            </div>
        </section>
    )
}
const BannerUsps = ({count,uspcount,uspicon,uspname}) => {
    return (
        <div className="item" key={count}>
            <div className="count">{uspcount}</div>
            <div className="icon"><img src={uspicon} alt={uspname} /></div>
            <p>
                {uspname}
            </p>
        </div>
    )
}

export default DigiHomeBanner
export {BannerUsps}