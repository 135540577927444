import CommonSec from "../../other/CommonSec"
import { CommonHeading } from "../../other/Headingtext-Btn"
const WhyVinsys = ({sectionstyle,subheading,children,image,imagealt}) => {
    return (
        <CommonSec sectionstyle={`whyVinsys dark-bg ${sectionstyle}`} innerspace="inner-container">
            <div className="inner-content d-flex">
                <div className="img-wrapper">
                    <img src={image} alt={imagealt}/>
                </div>
                <div className="content-wrapper">
                    <div className="heading-wrapper">
                        <CommonHeading mainHeading="Why Vinsys?"/>                        
                        <p>{subheading}</p>
                    </div>
                    {children}                    
                </div>
            </div>
        </CommonSec>
    )
}
export default WhyVinsys