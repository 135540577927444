import { CommonHeading } from "../other/Headingtext-Btn";
import { useContext } from "react";
import { EventContext } from "../Data/Context";
const EventDetailSummary = ()=>{
    const summaryData = useContext(EventContext)
    return(
        <section className="event-summary-sec common-spacing-top-bottom">
            <div className="inner-container">
                <CommonHeading class="small" mainHeading="Event Summary"/>  
                <div dangerouslySetInnerHTML={{ __html: summaryData.summary }}/>         
                
            </div>
        </section>
    )
}
export default EventDetailSummary;