import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from '../../../components/other/Headingtext-Btn'
import AboutOverview from '../../../components/about/AboutOverview'
import WhyVinsys from '../../../components/digilearn/LearningManagementPlatform/WhyVinsys'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import CommonSec from '../../../components/other/CommonSec'
import ErrorSection from '../../../components/other/ErrorSection';
import ApiPath from '../../../components/other/ApiPath';
const DigilearnPharmaceuticalsPage = () => {
    $("body").addClass("inner-page")
    const [pharmaceuticalspagedata, setpharmaceuticalspagedata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const pharmaceuticalspagepagedata = await fetch(`${ApiPath()}/get-pharmaceuticals-details`);
                const currentpharmaceuticalspagepagedata = await pharmaceuticalspagepagedata.json();
                setpharmaceuticalspagedata(currentpharmaceuticalspagepagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!pharmaceuticalspagedata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{pharmaceuticalspagedata.seo != null && pharmaceuticalspagedata.seo.meta_title != null ? pharmaceuticalspagedata.seo.meta_title : pharmaceuticalspagedata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={pharmaceuticalspagedata.seo != null && pharmaceuticalspagedata.seo.meta_key != null ? pharmaceuticalspagedata.seo.meta_key : pharmaceuticalspagedata.title} />

                <meta name="description" content={pharmaceuticalspagedata.seo != null && pharmaceuticalspagedata.seo.meta_description != null ? pharmaceuticalspagedata.seo.meta_description : pharmaceuticalspagedata.title} />

                <meta property="og:title" content={pharmaceuticalspagedata.seo != null && pharmaceuticalspagedata.seo.meta_title != null ? pharmaceuticalspagedata.seo.meta_title : pharmaceuticalspagedata.title} />

                <meta property="og:description" content={pharmaceuticalspagedata.seo != null && pharmaceuticalspagedata.seo.meta_description != null ? pharmaceuticalspagedata.seo.meta_description : pharmaceuticalspagedata.title} />

                <meta property="og:image" content={pharmaceuticalspagedata.seo != null && pharmaceuticalspagedata.seo.og_image != null ? pharmaceuticalspagedata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {pharmaceuticalspagedata.seo != null && pharmaceuticalspagedata.seo.schema_list != null ? pharmaceuticalspagedata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            {pharmaceuticalspagedata.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={pharmaceuticalspagedata.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={pharmaceuticalspagedata.banner.heading} />
                        <p>
                            {pharmaceuticalspagedata.banner.subheading}
                        </p>
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }
            {pharmaceuticalspagedata.introduction != null
                ?
                <AboutOverview style="delivery-about-sec" image={pharmaceuticalspagedata.introduction.gallery !=null ? pharmaceuticalspagedata.introduction.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} star={Number(pharmaceuticalspagedata?.star)} rating={Number(pharmaceuticalspagedata?.rating)}>
                    <CommonHeading mainHeading={pharmaceuticalspagedata.introduction.heading} />
                    <div dangerouslySetInnerHTML={{ __html: pharmaceuticalspagedata.introduction.content }} />
                </AboutOverview>
                :
                ""
            } 
            { pharmaceuticalspagedata.lms_section !=null && pharmaceuticalspagedata.lms_list !=null
                ?
                <CommonSec sectionstyle="lmsSection" innerspace="inner-container">
                    <div className='heading-wraper text-center'>
                        <CommonHeading mainHeading={pharmaceuticalspagedata.lms_section.heading} />
                        <p>
                            {pharmaceuticalspagedata.lms_section.subheading}
                        </p>
                    </div>
                    <div className='items d-flex mt-60'>
                        {pharmaceuticalspagedata.lms_list.map((val,index) => {
                            return (
                                <div class="item" key={index}>
                                    <div class="icon-wrapper">
                                        <img src={val.thumb_image} alt={val.thumb_alt} />
                                    </div>
                                    <p>{val.heading}</p>
                                </div>
                            )
                        })}

                    </div>
                </CommonSec>
                :
                ""
            }
            {pharmaceuticalspagedata.vinsys_services_section !=null
                ?
                <AboutOverview style="delivery-about-sec" image={pharmaceuticalspagedata.vinsys_services_section.gallery !=null ? pharmaceuticalspagedata.vinsys_services_section.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} star={Number(pharmaceuticalspagedata?.star)} rating={Number(pharmaceuticalspagedata?.rating)}>
                    <CommonHeading mainHeading={pharmaceuticalspagedata.vinsys_services_section.heading} />
                    <div dangerouslySetInnerHTML={{ __html: pharmaceuticalspagedata.vinsys_services_section.content }} />
                </AboutOverview>
                :
                ""
            }
            {pharmaceuticalspagedata.why_vinsys_section !=null && pharmaceuticalspagedata.why_vinsys
                ?
                <WhyVinsys subheading={pharmaceuticalspagedata.why_vinsys_section.subheading} image={pharmaceuticalspagedata.why_vinsys_section.gallery[0].media} imagealt={pharmaceuticalspagedata.why_vinsys_section.gallery[0].alt != null ? pharmaceuticalspagedata.why_vinsys_section.gallery[0].alt : pharmaceuticalspagedata.why_vinsys_section.gallery[0].heading}>
                    <ul className='items d-flex mt-60'>
                        {pharmaceuticalspagedata.why_vinsys.map((val, index) => {
                            return (
                                <li key={index}>
                                    <img src={val.thumb_image} alt={val.heading} />
                                    {val.heading}
                                </li>
                            )
                        })}
                    </ul>
                </WhyVinsys>
                :
                ""
            }
            <DigiGetInTouch />
        </>
    )
}
export default DigilearnPharmaceuticalsPage