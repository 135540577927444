import $ from 'jquery'
import React, { useState, useEffect } from "react"
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../components/other/ScrollToTop"
import InnerBanner from '../../components/other/InnerBanner'
import CommonSec from "../../components/other/CommonSec";
import {CommonHeading} from "../../components/other/Headingtext-Btn"
import { Link } from "react-router-dom";

import axios from 'axios';
import ApiPath from '../../components/other/ApiPath';
import FlsContactForm from '../../components/other/flsContactForm';

const FlsContactUs = () => {
    $(document).ready(function() {
        $("body").addClass("inner-page")  
    })

    const [flsContactData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [flsServicelist, setflsServicelist] = useState([])
    
    useEffect(() => {
        // Define the API endpoint URL
        const apiUrl = `${ApiPath()}/fls-get_contact_page_details`; // Replace with your API URL
    
        // Use Axios to make a GET request
        axios.get(apiUrl)
        .then((response) => {


            setData(response.data);
           


        })
        .catch((error) => {
            console.error('Error fetching data:', error);
        })
        .finally(() => {
            setLoading(false);
        });

        getflsservicedata();
    }, []);

    
    const getflsservicedata = async () => {
        try {

            const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-fls-solution`);
            setflsServicelist(data.our_services_list);

        } catch (error) {
            console.log("error on fls", error)

        }

    }

    

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!flsContactData) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }

    return (
        <>
            <Helmet>
                <title>{flsContactData.seo != null && flsContactData.seo.meta_title != null ? flsContactData.seo.meta_title : flsContactData.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={flsContactData.seo != null && flsContactData.seo.meta_key != null ? flsContactData.seo.meta_key : flsContactData.title} />

                <meta name="description" content={flsContactData.seo != null && flsContactData.seo.meta_description != null ? flsContactData.seo.meta_description : flsContactData.title} />

                <meta property="og:title" content={flsContactData.seo != null && flsContactData.seo.meta_title != null ? flsContactData.seo.meta_title : flsContactData.title} />

                <meta property="og:description" content={flsContactData.seo != null && flsContactData.seo.meta_description != null ? flsContactData.seo.meta_description : flsContactData.title} />

                <meta property="og:image" content={flsContactData.seo != null && flsContactData.seo.og_image != null ? flsContactData.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {flsContactData.seo != null && flsContactData.seo.schema_list != null ? flsContactData.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            
            <ScrollToTop />

            <InnerBanner
                style="digi-inner-banner"
                image={flsContactData.banner != null ? flsContactData.banner.media : `${process.env.PUBLIC_URL}/images/go-digital-learning-banner-min.jpg`}>
                
                <div className='text-center'>
                    <CommonHeading
                        mainHeading={flsContactData.banner != null ? flsContactData.banner.heading : flsContactData.title} />
                    <p>
                        {flsContactData.banner != null ? flsContactData.banner.subheading : ''}
                    </p>
                </div>
            </InnerBanner>

            <CommonSec sectionstyle={`flscontactus`} innerspace="inner-container">
                <div className="inner-content d-flex">
                    <div className='item left-section'>
                        <div className='img-wrapper'>
                            <img src={flsContactData.keep_in_touch.gallery.media} alt={flsContactData.keep_in_touch.gallery.alt} />
                        </div>
                        <div className='addressItems mt-60'>

                            {flsContactData.keep_in_touch.lists.map((val) => {
                                const linkUrl = <Link to={val.url}>{val.button_text}</Link>;
                                const simpleText = <p>{val.subheading}</p>
                                return (
                                    <div className='addressItem d-flex'>
                                        <div className='icon-wrapper'>
                                            <img src={val.thumb_image} alt={val.thumb_alt} />
                                        </div>
                                        <div className='content'>
                                            <div className='heading'>{val.heading}</div>
                                            {val.url === null ? simpleText : linkUrl }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='item right-section'>
                        <div className="heading-wrapper">
                            <CommonHeading mainHeading={flsContactData.keep_in_touch.heading}></CommonHeading>
                            <p className={flsContactData.keep_in_touch.subheading === null ? 'mt-0' : ''}>{flsContactData.keep_in_touch.subheading}</p>
                        </div>

                        <FlsContactForm style="contact-form mt-30" FormStyle="common-from" />
                    </div>
                </div>
            </CommonSec>
        </>
    )
}

export default FlsContactUs