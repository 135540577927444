import $ from 'jquery'
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from '../../../components/other/Headingtext-Btn'
import AboutOverview from '../../../components/about/AboutOverview'
import VinsysServices, { VinsysServicesItem } from '../../../components/digilearn/about/VinsysServices'
import ContributesSection, { ContributesSectionItem } from '../../../components/digilearn/about/ContributesSection'
import WhyVinsys from '../../../components/digilearn/LearningManagementPlatform/WhyVinsys'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import ErrorSection from '../../../components/other/ErrorSection'
import React, { useState, useEffect } from "react";
import ApiPath from '../../../components/other/ApiPath';
import Cookies from 'js-cookie';

const DigilearnManufacturingPage = ()=>{
    $("body").addClass("inner-page")

    const TokensCodes = Cookies.get('access_token'); 
    const [manufacturingdata, setAboutData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                //Contact Page Data
                const manufacturingpagedata = await fetch(`${ApiPath()}/get-manufacturing-details`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }
    
                });           
                const currentmanufacturingpagedata = await manufacturingpagedata.json();
                setAboutData(currentmanufacturingpagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [TokensCodes]);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!manufacturingdata) {
        return <div className='text-center'>No data available.</div>;
    }
    const manufacturingFullOverview = <div className="detail-wrapper" dangerouslySetInnerHTML={{ __html: manufacturingdata.introduction.content}} />;

    return(
        <>
            <ScrollToTop />
            {manufacturingdata.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={manufacturingdata.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={manufacturingdata.banner.heading} />    
                        <p>
                            {manufacturingdata.banner.subheading}
                        </p>                
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }
            {manufacturingdata.introduction != null
                ?
                <AboutOverview style="delivery-about-sec" image={manufacturingdata.introduction.gallery !=null ? manufacturingdata.introduction.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} star={Number(manufacturingdata?.star)} rating={Number(manufacturingdata?.rating)}>
                    <CommonHeading mainHeading="Introduction" />
                    {manufacturingFullOverview}
                </AboutOverview>
                :
                ""
            } 
            {manufacturingdata.how_vinsys_contributes_section !=null && manufacturingdata.how_vinsys_contributes_list
                ?
                <ContributesSection itemview="three-column" sectionheading={manufacturingdata.how_vinsys_contributes_section.heading}>
                    {manufacturingdata.how_vinsys_contributes_list.map((val,index) => {
                        return (
                            <ContributesSectionItem key={index}
                                image={val.media} imagealt={val.alt} 
                                description={val.heading}
                            />
                        )
                    })}
                </ContributesSection>
                :
                ""
            }
            {manufacturingdata.vinsys_services_section !=null && manufacturingdata.vinsys_services_list
                ?
                <VinsysServices sectionheading={manufacturingdata.vinsys_services_section.heading} sectiondescription="">
                    {manufacturingdata.vinsys_services_list.map((val,index) => {
                        return (
                            <VinsysServicesItem key={index} icon={val.thumb_image} image={val.media} imagealt={val.alt} name={val.heading} />
                        )
                    })}
                </VinsysServices>
                :
                ""
            }
            {manufacturingdata.why_vinsys_section !=null && manufacturingdata.why_vinsys_section.gallery !=null && manufacturingdata.why_vinsys
                ?
                <WhyVinsys sectionstyle="white-bg" subheading="" image={manufacturingdata.why_vinsys_section.gallery[0].media}>
                    <ul className='items d-flex mt-60'>

                        {manufacturingdata.why_vinsys.map((val,index) => {
                            return (
                                <li key={index}>
                                    <img src={val.thumb_image} alt={val.thumb_alt} />
                                    {val.heading}
                                </li>
                            )
                        })}
                    </ul>
                </WhyVinsys>
                :
                ""
            }
            <DigiGetInTouch />
        </>
    )
}
export default DigilearnManufacturingPage