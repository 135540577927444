import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PostDetail from '../../components/career/CareerDetail/PostDetail';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../components/other/ScrollToTop';
import ApplyForm from '../../components/career/CareerDetail/ApplyForm';
import JobSummary from '../../components/career/CareerDetail/JobSummary';
import ApiPath from '../../components/other/ApiPath';
import $ from 'jquery'; 
import Cookies from 'js-cookie';

const CareerDetail = ({breadcrumb})=>{

    const TokensCodes = Cookies.get('access_token'); 
    let { userId } = useParams();
    $("body").addClass("inner-page")
    const [careerDataDetail, setCareerDataDetail] = useState(null);    
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
        try {
            //Contact Page Data
            const careerdetailpagedata = await fetch(`${ApiPath()}/get-job-by-slug/${userId}`, {
                headers: {
                    'Authorization': `Bearer ${TokensCodes}`
                }

            });    
            if (!careerdetailpagedata.ok) {
                throw new Error("Request failed"); // You can customize this error message
            }        
            const currentcareerdetailpagedata = await careerdetailpagedata.json();            
            setCareerDataDetail(currentcareerdetailpagedata);
            setLoading(false);
        } catch (error) {           
            console.error('Error fetching data:', error);  
            window.location.href="error/not-found"
            console.log("not load")  
                 
            }
    };
    fetchData();
    }, [TokensCodes]);

    if (loading) {
        return(
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!careerDataDetail) {
        console.log("not load")  
        return <div className='text-center'>No data available.</div>;
    }
    return(
        <>
            <Helmet>
                <title>{careerDataDetail.seo!=null && careerDataDetail.seo.meta_title!=null ? careerDataDetail.seo.meta_title : careerDataDetail.title}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content={careerDataDetail.seo !=null && careerDataDetail.seo.meta_key!=null ? careerDataDetail.seo.meta_key:careerDataDetail.title } />
                <meta name="description" content={careerDataDetail.seo!=null  && careerDataDetail.seo.meta_description!=null ? careerDataDetail.seo.meta_description : careerDataDetail.title} />
                <meta property="og:title" content={careerDataDetail.seo !=null && careerDataDetail.seo.meta_title != null ? careerDataDetail.seo.meta_title : careerDataDetail.title} />
                <meta property="og:description" content={careerDataDetail.seo !=null && careerDataDetail.seo.meta_description !=null ? careerDataDetail.seo.meta_description : careerDataDetail.title} />               
                <meta property="og:image" content={careerDataDetail.seo!=null && careerDataDetail.seo.og_image!=null ? careerDataDetail.seo.og_image :  `${process.env.PUBLIC_URL}/images/logo.png`} />
                <meta property="og:url" content={window.location.href} />                       
            </Helmet>
            <ScrollToTop/>
            <PostDetail heading={careerDataDetail.title} location={careerDataDetail.city}/>
            <section className="job-summary common-spacing-top-bottom">
                <div className="inner-container"> 
                    <JobSummary heading="Roles And Responbilites:" classMain={careerDataDetail.roles_and_responbilites===null ? 'no-require' : 'require'}>
                        <div className='description' dangerouslySetInnerHTML={{__html: careerDataDetail.roles_and_responbilites}}>
                        </div>
                    </JobSummary> 
                    <JobSummary heading="Requirements:" classMain={careerDataDetail.requirements===null ? 'no-require' : 'require'}>
                        <div className='description' dangerouslySetInnerHTML={{__html: careerDataDetail.requirements}}>
                        </div>
                    </JobSummary> 
                    <JobSummary heading="Qualifications:" classMain={careerDataDetail.qualification===null ? 'no-require' : 'require'}>
                        <div className='description' dangerouslySetInnerHTML={{__html: careerDataDetail.qualification}}>
                        </div>
                    </JobSummary>  
                    <JobSummary heading="Experience:" classMain={careerDataDetail.experience===null ? 'no-require' : 'require'}>
                        <div className='description' dangerouslySetInnerHTML={{__html: careerDataDetail.experience}}>
                        </div>
                    </JobSummary>
                    <JobSummary heading="Salary:" classMain={careerDataDetail.salary===null ? 'no-require' : 'require'}>
                        <div className='description' dangerouslySetInnerHTML={{__html: careerDataDetail.salary}}>
                        </div>
                    </JobSummary> 
                    <JobSummary heading="Position:" classMain={careerDataDetail.position===null ? 'no-require' : 'require'}>
                        <div className='description' dangerouslySetInnerHTML={{__html: careerDataDetail.position}}>
                        </div>
                    </JobSummary>                 
                </div>
            </section>
            <ApplyForm post={careerDataDetail.title} id={careerDataDetail.id}>
                {careerDataDetail.job_list.map((val)=>{     
                    return(
                        <option value={val.id} key={val.id}>{val.title}</option>
                    )                      
                })} 
            </ApplyForm>     
        </>
    )
}
export default CareerDetail;