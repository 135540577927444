import CommonSec from "../../other/CommonSec"
import { CommonHeading } from "../../other/Headingtext-Btn"
const LearningManagementTrainings = ({ sectionTitle, sectionSubTitle, children })=>{
    return(
        <CommonSec sectionstyle="digilearning-learning-management-trainings" innerspace="inner-container">
            <div className="text-center">
                <CommonHeading mainHeading={sectionTitle} />
                <p>{sectionSubTitle}</p>
            </div>
            <div className="trainings d-flex">
                {children}
            </div>
        </CommonSec>
    )
}
export default LearningManagementTrainings