// HomeContext.js
import React from 'react';

const HomeContext = React.createContext();
const BlogContext = React.createContext();
const ContactContext = React.createContext();
const EventContext = React.createContext();
const InvestorContext = React.createContext();
const TokenContext = React.createContext();

export default HomeContext;
export {BlogContext,ContactContext,EventContext,InvestorContext,TokenContext}