import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../../components/other/ScrollToTop';
import InnerBanner from '../../../components/other/InnerBanner'
import AboutOverview from "../../../components/about/AboutOverview"
import CommonBtn, { CommonHeading } from "../../../components/other/Headingtext-Btn";
import DigiGetInTouch from "../../../components/digilearn/Home/DigiGetInTouch";
import ConsultancyOfferings, { ConsultancyOfferingsItem } from '../../../components/digilearn/CustomContentDevelopment/ConsultancyOfferings';
import CustomerLDJourney, { CustomerLDJourneyItem } from '../../../components/digilearn/CustomContentDevelopment/CustomerLDJourney';
import ErrorSection from '../../../components/other/ErrorSection';
import ApiPath from '../../../components/other/ApiPath';
const CustomContentDevelopmentPage = ()=>{
    $("body").addClass("inner-page")
    const [customcontentdata, setcustomcontentdata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const customcontentpagedata = await fetch(`${ApiPath()}/get-custom-content-development-page-details`);
                const currentcustomcontentpagedata = await customcontentpagedata.json();
                setcustomcontentdata(currentcustomcontentpagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")
            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!customcontentdata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return(
        <>
            <Helmet>
                <title>{customcontentdata.seo != null && customcontentdata.seo.meta_title != null ? customcontentdata.seo.meta_title : customcontentdata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={customcontentdata.seo != null && customcontentdata.seo.meta_key != null ? customcontentdata.seo.meta_key : customcontentdata.title} />

                <meta name="description" content={customcontentdata.seo != null && customcontentdata.seo.meta_description != null ? customcontentdata.seo.meta_description : customcontentdata.title} />

                <meta property="og:title" content={customcontentdata.seo != null && customcontentdata.seo.meta_title != null ? customcontentdata.seo.meta_title : customcontentdata.title} />

                <meta property="og:description" content={customcontentdata.seo != null && customcontentdata.seo.meta_description != null ? customcontentdata.seo.meta_description : customcontentdata.title} />

                <meta property="og:image" content={customcontentdata.seo != null && customcontentdata.seo.og_image != null ? customcontentdata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {customcontentdata.seo != null && customcontentdata.seo.schema_list != null ? customcontentdata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />  
            {customcontentdata.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={customcontentdata.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={customcontentdata.banner.heading} />                    
                    </div>                
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }      
            {customcontentdata.introduction != null
                ?      
                <AboutOverview style="delivery-about-sec" image={customcontentdata.introduction.gallery != null ? customcontentdata.introduction.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} star={Number(customcontentdata?.star)} rating={Number(customcontentdata?.rating)}>
                    <CommonHeading mainHeading={customcontentdata.introduction.heading} />
                    <div dangerouslySetInnerHTML={{ __html: customcontentdata.introduction.content }}/>
                </AboutOverview>
                :
               ""
            }     
            {customcontentdata.consultancy_offerings !=null && customcontentdata.consultancy_offerings.page_list != null
                ?   
                <ConsultancyOfferings sectiontitle={customcontentdata.consultancy_offerings.heading} sectiondescription={customcontentdata.consultancy_offerings.subheading}>
                    {customcontentdata.consultancy_offerings.page_list.map((val,index)=>{
                        return(
                            <ConsultancyOfferingsItem key={index} image={val.media} imagealt={val.alt}
                            title={val.heading} shortdescription={<div dangerouslySetInnerHTML={{ __html: val.content }}/>} btnlink={val.url}/>                
                        )
                    })}               
                </ConsultancyOfferings>
                :
                ""
            }    
            {customcontentdata.ld_journey!=null && customcontentdata.ld_journey.page_list!=null
                ?
                <CustomerLDJourney sectionheading={customcontentdata.ld_journey.heading} sectionshortdescription={customcontentdata.ld_journey.subheading} >
                    {customcontentdata.ld_journey.page_list.map((val,index)=>{
                        return(
                            <CustomerLDJourneyItem key={index} numvalue={index+1} image={val.media} imagealt={val.alt} itemtitle={val.heading} description={val.content}/>
                        )
                    })}                
                </CustomerLDJourney>
                :
                ""
            }
            <DigiGetInTouch/>
        </>
    )
}
export default CustomContentDevelopmentPage