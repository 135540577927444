import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from '../../../components/other/Headingtext-Btn'
import AboutOverview from '../../../components/about/AboutOverview'
import LmsAutomobile, { TabBodyText, TabItem } from '../../../components/digilearn/about/LmsAutomobile'
import VinsysCaterSection from '../../../components/digilearn/about/VinsysCaterSection'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import ErrorSection from '../../../components/other/ErrorSection';
import ApiPath from '../../../components/other/ApiPath';
const DigilearnAutomobilePage = () => {
    $("body").addClass("inner-page")
    const [tabcount, settabcount] = useState('id-1')
    const [automobilepagedata, setautomobilepagedata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const automobilepagepagedata = await fetch(`${ApiPath()}/get-automobile-details`);
                const currentautomobilepagepagedata = await automobilepagepagedata.json();
                setautomobilepagedata(currentautomobilepagepagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!automobilepagedata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{automobilepagedata.seo != null && automobilepagedata.seo.meta_title != null ? automobilepagedata.seo.meta_title : automobilepagedata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={automobilepagedata.seo != null && automobilepagedata.seo.meta_key != null ? automobilepagedata.seo.meta_key : automobilepagedata.title} />

                <meta name="description" content={automobilepagedata.seo != null && automobilepagedata.seo.meta_description != null ? automobilepagedata.seo.meta_description : automobilepagedata.title} />

                <meta property="og:title" content={automobilepagedata.seo != null && automobilepagedata.seo.meta_title != null ? automobilepagedata.seo.meta_title : automobilepagedata.title} />

                <meta property="og:description" content={automobilepagedata.seo != null && automobilepagedata.seo.meta_description != null ? automobilepagedata.seo.meta_description : automobilepagedata.title} />

                <meta property="og:image" content={automobilepagedata.seo != null && automobilepagedata.seo.og_image != null ? automobilepagedata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {automobilepagedata.seo != null && automobilepagedata.seo.schema_list != null ? automobilepagedata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            {automobilepagedata.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={automobilepagedata.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={automobilepagedata.banner.heading} />
                        <p>
                            {automobilepagedata.banner.subheading}
                        </p>
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            } 
            {automobilepagedata.introduction != null
                ?
                <AboutOverview style="delivery-about-sec" image={automobilepagedata.introduction.gallery !=null ? automobilepagedata.introduction.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} star={Number(automobilepagedata.star)} rating={Number(automobilepagedata.rating)}>
                    <CommonHeading mainHeading={automobilepagedata.introduction.heading} />
                    <div dangerouslySetInnerHTML={{ __html: automobilepagedata.introduction.content }} />
                </AboutOverview>
                :
                ""
            }
            {automobilepagedata.lms_section !=null && automobilepagedata.lms
                ?
                <LmsAutomobile sectionheading={automobilepagedata.lms_section.heading} sectionshortdescription={automobilepagedata.lms_section.subheading}>
                    <div className='tabs-wrapper'>
                        {automobilepagedata.lms.map((val,index)=>{
                            return(
                                <TabItem key={index} settabcount={settabcount} tabcount={tabcount} image={val.thumb_image} imagealt={val.thumb_alt!=null ? val.thumb_alt : val.heading} title={val.heading} tabnum={index+1}/>
                            )
                        })} 
                    </div>
                    <div className='content-wrapper'>
                    {automobilepagedata.lms.map((val,index)=>{
                        return(
                            <TabBodyText key={index} tabcount={tabcount}  tabnum={index+1} image={val.media} imagealt={val.alt!=null ? val.alt : val.heading}>
                                <div dangerouslySetInnerHTML={{ __html: val.content }} />
                            </TabBodyText>
                        )
                    })} 
                    </div>
                </LmsAutomobile>
                :
                ""
            }
            {automobilepagedata.how_vinsys !=null && automobilepagedata.how_vinsys.gallery !=null
                ?
                <VinsysCaterSection image={automobilepagedata.how_vinsys.gallery[0].media} imagealt={automobilepagedata.how_vinsys.gallery[0].alt} title={automobilepagedata.how_vinsys.gallery[0].heading}>
                    <div className='mt-30' dangerouslySetInnerHTML={{ __html: automobilepagedata.how_vinsys.gallery[0].subheading }} />
                </VinsysCaterSection>
                :
                ""
            }
            {automobilepagedata.unique_features !=null && automobilepagedata.unique_features.gallery !=null
                ?
                <AboutOverview style="delivery-about-sec" image={automobilepagedata.unique_features.gallery[0].media} star={Number(automobilepagedata.star)} rating={Number(automobilepagedata.rating)}>
                    <CommonHeading mainHeading={automobilepagedata.unique_features.heading} />
                    <div dangerouslySetInnerHTML={{ __html: automobilepagedata.unique_features.content }} />
                </AboutOverview>
                :
                ""
            }
            <DigiGetInTouch/>
        </>
    )
}
export default DigilearnAutomobilePage