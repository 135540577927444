import CommonSec from "../../other/CommonSec"
import { CommonHeading } from "../../other/Headingtext-Btn"

const PartnersProgramBenifits = ({sectiontitle, sectionsmalldesc, children})=>{
    return(
        <CommonSec sectionstyle="partnersProgram dark-bg" innerspace="inner-container">
            <div className="heading-wrapper text-center">
                <CommonHeading mainHeading={sectiontitle}/>
                <p>
                    {sectionsmalldesc}
                </p>
            </div>
            {children}
        </CommonSec>
    )
}
export default PartnersProgramBenifits