import CommonSec from "../../other/CommonSec"
import { CommonHeading } from "../../other/Headingtext-Btn"

const LmsAutomobile = ({sectionheading,sectionshortdescription,children})=>{
    return(
        <CommonSec sectionstyle="automobileIndustry dark-bg" innerspace="inner-container">
            <div className="heading-wraper text-center">
                <CommonHeading mainHeading={sectionheading}/>
                <p>
                    {sectionshortdescription}
                </p>
            </div>
            <div className="tabbing-wrapper d-flex mt-60">
                {children}
            </div>
        </CommonSec>
    )
}
const TabItem = ({settabcount, tabcount, tabnum, image, imagealt, title})=>{
    return(
        <div onClick={()=>{settabcount(`id-${tabnum}`)}} className={`item ${tabcount===`id-${tabnum}` ? 'active' : ''}`}>
            <div className="icon-wrapper">
                <img src={image} alt={imagealt} />
            </div>
            <p>
                {title}
            </p>
        </div>
    )
}
const TabBodyText = ({tabcount, tabnum, image, imagealt, children})=>{
    return(
        <div className={`inner ${tabcount===`id-${tabnum}` ? 'active' : 'no-require'}`}>
            <div className="img-wrapper">
                <img className="w-100" src={image} alt={imagealt} />
            </div>
            <div className="description">
                {children}
            </div>
        </div>
    )
}
export default LmsAutomobile
export {TabItem, TabBodyText}