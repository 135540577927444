import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from '../../../components/other/Headingtext-Btn'
import AboutOverview from '../../../components/about/AboutOverview'
import CommonSec from '../../../components/other/CommonSec'
import SuiteOfLearningPlatform from "../../../components/digilearn/LearningManagementPlatform/SuiteOfLearningPlatform";
import WhyVinsys from '../../../components/digilearn/LearningManagementPlatform/WhyVinsys'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import ApiPath from '../../../components/other/ApiPath';
const DigilearnSoftwarePage = () => {
    $("body").addClass("inner-page")
    const [softwarepagedata, setsoftwarepagedata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const softwarepagepagedata = await fetch(`${ApiPath()}/get-software-details`);
                const currentsoftwarepagepagedata = await softwarepagepagedata.json();
                setsoftwarepagedata(currentsoftwarepagepagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!softwarepagedata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{softwarepagedata.seo != null && softwarepagedata.seo.meta_title != null ? softwarepagedata.seo.meta_title : softwarepagedata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={softwarepagedata.seo != null && softwarepagedata.seo.meta_key != null ? softwarepagedata.seo.meta_key : softwarepagedata.title} />

                <meta name="description" content={softwarepagedata.seo != null && softwarepagedata.seo.meta_description != null ? softwarepagedata.seo.meta_description : softwarepagedata.title} />

                <meta property="og:title" content={softwarepagedata.seo != null && softwarepagedata.seo.meta_title != null ? softwarepagedata.seo.meta_title : softwarepagedata.title} />

                <meta property="og:description" content={softwarepagedata.seo != null && softwarepagedata.seo.meta_description != null ? softwarepagedata.seo.meta_description : softwarepagedata.title} />

                <meta property="og:image" content={softwarepagedata.seo != null && softwarepagedata.seo.og_image != null ? softwarepagedata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {softwarepagedata.seo != null && softwarepagedata.seo.schema_list != null ? softwarepagedata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            <InnerBanner style="digi-inner-banner" image={softwarepagedata.banner != null ? softwarepagedata.banner.media : `${process.env.PUBLIC_URL}/images/go-digital-learning-banner-min.jpg`}>
                <div className='text-center'>
                    <CommonHeading mainHeading={softwarepagedata.banner != null ? softwarepagedata.banner.heading : softwarepagedata.title} />
                    <p>
                        {softwarepagedata.banner != null ? softwarepagedata.banner.subheading : ''}
                    </p>
                </div>
            </InnerBanner>
            <AboutOverview style="delivery-about-sec" image={softwarepagedata.introduction.gallery[0].media} star={Number(softwarepagedata?.star)} rating={Number(softwarepagedata?.rating)}>
                <CommonHeading mainHeading={softwarepagedata.introduction.heading} />
                <div dangerouslySetInnerHTML={{ __html: softwarepagedata.introduction.content }} />
            </AboutOverview>
            <CommonSec sectionstyle="ourUspIconSection" innerspace="inner-container">
                <div className="heading-wraper text-center">
                    <CommonHeading mainHeading={softwarepagedata.our_usps_section.heading} />
                    <div dangerouslySetInnerHTML={{ __html: softwarepagedata.our_usps_section.content }} />
                </div>
                <div className="items d-flex mt-60">
                    {softwarepagedata.usps.map((val,index) => {
                        return (
                            <div className="item" key={index}>
                                <div className="inner-content">
                                    <div className="icon-wrapper">
                                        <img src={val.thumb_image} alt={val.thumb_alt} />
                                    </div>
                                    <p>
                                        {val.heading}
                                    </p>
                                </div>
                                <div className="right-triangle"></div>
                                <div className="bottom-shape"><span className="num">{index+1}</span><span className="shape"></span></div>
                            </div>
                        )
                    })}

                </div>
            </CommonSec>
            <SuiteOfLearningPlatform sectionstyle="dark-bg" topspace="no-margin">
                <div className="items d-flex justify-content-between">
                    <div className="detail">
                        <CommonHeading mainHeading={softwarepagedata.what_we_do.heading} />
                        <p>
                            {softwarepagedata.what_we_do.subheading}
                        </p>
                        <div dangerouslySetInnerHTML={{ __html: softwarepagedata.what_we_do.content }} />
                    </div>
                    <div className="image">
                        <img src={softwarepagedata.what_we_do.gallery!=null ? softwarepagedata.what_we_do.gallery[0].media :  `${process.env.PUBLIC_URL}/images/suit-learning-image1.jpg`} alt="" />
                        <div className="small-image center">
                            <img src={softwarepagedata.what_we_do.gallery!=null ? softwarepagedata.what_we_do.gallery[1].media : `${process.env.PUBLIC_URL}/images/suit-learning-image1-small.jpg`} alt="" />
                        </div>
                    </div>
                </div>
            </SuiteOfLearningPlatform>
            <WhyVinsys subheading={softwarepagedata.why_vinsys_section.subheading} image={softwarepagedata.why_vinsys_section.gallery[0].media} imagealt={softwarepagedata.why_vinsys_section.gallery[0].alt != null ? softwarepagedata.why_vinsys_section.gallery[0].alt : softwarepagedata.why_vinsys_section.gallery[0].heading}>
                <ul className='items d-flex mt-60'>
                    {softwarepagedata.why_vinsys.map((val,index) => {
                        return (
                            <li key={index}>
                                <img src={val.thumb_image} alt={val.heading} />
                                {val.heading}
                            </li>
                        )
                    })}
                </ul>
            </WhyVinsys>
            <DigiGetInTouch />
        </>
    )
}
export default DigilearnSoftwarePage