import CommonSec from "../../other/CommonSec"
import { CommonHeading } from "../../other/Headingtext-Btn"
const ConsultancyOfferings = ({sectiontitle, sectionsmalldesc, children}) => {
    return (
        <CommonSec sectionstyle="consultingSection dark-bg" innerspace="inner-container">
            <div className='heading-wrapper text-center'>
                <CommonHeading mainHeading={sectiontitle} />
                <p>
                    {sectionsmalldesc}
                </p>
            </div>
            <div className="items d-flex mt-60">
                {children}                
            </div>
        </CommonSec>
    )
}
export default ConsultancyOfferings