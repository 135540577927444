import React from 'react';
import {useState, useEffect} from "react";
import axios from 'axios';
import ApiPath from '../../components/other/ApiPath';
import Cookies from 'js-cookie';
import { useContext } from "react";
import { EventContext} from '../Data/Context';
import $ from 'jquery' 
$(document).ready(function() {
    $('body').bind('paste', function(event) {
        event.preventDefault();  
    });
});
const EventRegisterForm = () => {
    const TokensCodes = Cookies.get('access_token'); 
    const summaryData = useContext(EventContext)
    const [enqdata, setEnqData] = useState(null);   
    const [enqStatedata, setStatedata] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filterVal, SetfilterVal] = useState({           
        event_id: summaryData.id,
        name: '',
        phone_number: '',
        email: '',     
        services: '',   
        country: 0,
        state: '',
        job_title: '',      
        company: ''              
    })    
    const [isLoading, setIsLoading] = useState(false);
    const handleChange = (event) => { 
        SetfilterVal({...filterVal, [event.target.name]: event.target.value});
        if(event.target.value.trim()===''){
            event.target.value=""
        }        
    };     
    useEffect(() => {
        const fetchData = async () => {
        try {           
            const enqpagedata = await fetch(`${ApiPath()}/get_all_counrty`, {
                headers: {
                    'Authorization': `Bearer ${TokensCodes}`
                }

            });                       
            const currentenqpagedata = await enqpagedata.json();
            setEnqData(currentenqpagedata);

            const enqstatedatas = await fetch(`${ApiPath()}/get_state_by_country/${filterVal.country}`, {
                headers: {
                    'Authorization': `Bearer ${TokensCodes}`
                }

            });                       
            const currentenqstatedatas = await enqstatedatas.json();
            setStatedata(currentenqstatedatas);

            setLoading(false);

           
           
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchData();
    },[filterVal]);

    if (loading) {
        return(
            console.log('loading')
            
        );
    }

    if (!enqdata, !enqStatedata) {
        return <div className='text-center'>No data available.</div>;
    }   
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        axios.post(`${ApiPath()}/post-event-registration/`, filterVal, {
            headers: {
                'Authorization': `Bearer ${TokensCodes}`
            }

        })           
        .then((response) => {
            // console.log('Form submitted successfully!', response.data);
            // Perform additional actions on successful submission           
            window.location.href = '/thank-you'
            setIsLoading(false);
                      
        })
        .catch((error) => {
            // console.error('Form submission failed!', error);
            // Handle the error state or display an error message            
            setIsLoading(false);       
        });
    }
    const closeForm = ()=>{
        $('#apply-form').removeClass("show-form")
        $('body').removeClass("popup-added")
    }
    return (

        <div id="apply-form">
            <div className="inner">
                <button type="button" className="close-form" onClick={closeForm}>X</button>
                <div className="heading">Dont't miss the webinar!</div>
                <form  onSubmit={handleSubmit}>
                    <ul className="common-from">
                        <li style={{display:'none'}}>
                            <label>Event Id<sup>*</sup></label>
                            <input type="text" onChange={handleChange} value={summaryData.id} placeholder="event" id="event_id" name="event_id" readOnly/>
                        </li>
                        <li>
                            <label>Name<sup>*</sup></label>
                            <input type="text" pattern="[A-Za-z0-9\s]+" onChange={handleChange} placeholder="Your Name" id="name" name="name" required/>
                        </li>
                        <li>
                            <label>Email<sup>*</sup></label>
                            <input type="email" onChange={handleChange} placeholder="Your Email" id="uemail" name="email" required/>
                        </li>
                        <li>
                            <label>Phone Number<sup>*</sup></label>
                            <input type="number" onChange={handleChange} placeholder="Your Number" id="Number" name="phone_number" maxLength={15} required/>
                            {/* <input type="text" pattern="[0-9]{1}[0-9]{9}" onChange={handleChange} placeholder="Your Number" id="Number" name="phone_number" /> */}
                        </li>
                        <li>
                        <label>Country<sup>*</sup></label>
                        <select onChange={handleChange}  name="country" required>
                            <option value="">Select Country</option>  
                            {enqdata.map((val)=>{
                                return(
                                    <option value={val.id} key={val.id}>{val.name}</option>   
                                )
                            })}                                                                                   
                        </select>
                    </li>
                    <li>
                        <label>State<sup>*</sup></label>
                        <select onChange={handleChange} name="state"  id="state" required>
                            <option value="">Select State</option>  
                            {enqStatedata.map((val)=>{
                            
                                return(
                                    <option value={val.name} key={val.id}>{val.name}</option>   
                                )
                                
                            })}                                              
                        </select>
                    </li>   
                        <li>
                            <label>Job Title<sup>*</sup></label>
                            <input type="text" onChange={handleChange} placeholder="Job Title" id="job_title" name="job_title" required/>
                        </li>
                        <li>
                            <label>Company Name<sup>*</sup></label>
                            <input type="text" onChange={handleChange} placeholder="Company Name" id="company" name="company" required/>
                        </li>
                        <li className="text-center">
                            <div className="links">
                                <button type="submit" className="common-btn" disabled={isLoading}>Register Now <i
                                className="icon">&nbsp;</i></button>
                            </div>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    )
}
export default EventRegisterForm