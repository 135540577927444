import React, {useState, useEffect} from "react";
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import ApiPath from '../components/other/ApiPath';
import { TokenCodeUserName, TokenCodeUserPassword } from "../components/other/ApiPath";
const ToekeDataMain = ()=>{
    const [username, setUserName] = useState(TokenCodeUserName())
    const [password, setUserPassowrd] = useState(TokenCodeUserPassword())
    const  [tokNum, SetTokNum] = useState('') 
    const  [tokNumUpdated, SettokNumUpdated] = useState('')    
    useEffect(()=>{
    
    var formdata = new FormData();
    var key ='Yu6byFqorIIzm5Q8Lt8bWQ=='//key used in Python
    key = CryptoJS.enc.Utf8.parse(key);
    var iv = CryptoJS.enc.Utf8.parse('M11Wiyp5sOtlDnfM')
    
    const timestamp = Date.now() + 900000; // Current timestamp + 1 hour validity
    const dataWithTimestamp = JSON.stringify( {username, timestamp} ); // Combine data and timestamp
    const dataWithTimestampPassword = JSON.stringify( {password, timestamp } ); // Combine data and timestamp

    var encrypted = CryptoJS.AES.encrypt(dataWithTimestamp, key, { iv: iv, mode: CryptoJS.mode.CBC});
    var encryptedPassword = CryptoJS.AES.encrypt(dataWithTimestampPassword, key, { iv: iv, mode: CryptoJS.mode.CBC});

    encrypted =encrypted.toString();
    encryptedPassword =encryptedPassword.toString();  

    formdata.append("username", encrypted);
    formdata.append("password", encryptedPassword);


    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };
    
    fetch(`${ApiPath()}/token`, requestOptions)
    .then(response => response.json())
    .then(result => {
        
        Cookies.remove('access_token');
        Cookies.set('access_token', result.access_token);
        
       

    })
    .catch(error => console.log('error', error)); 
    }, [])
    
}
export default ToekeDataMain