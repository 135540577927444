import $ from 'jquery'
import React, { useState, useEffect } from "react"
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../../components/other/ScrollToTop"
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from "../../../components/other/Headingtext-Btn"
import AboutOverview from "../../../components/about/AboutOverview"
import FlsChooseUs, {FlsChooseUsItems} from "../../../components/fls/FlsChooseUs"
import FlsGetQuote from "../../../components/fls/Home/FlsGetQuote"
import FlsOffers from "../../../components/fls/FlsOffers"

import axios from 'axios';
import ApiPath from '../../../components/other/ApiPath';

const FlsSubtitling = () => {
    $(document).ready(function() {
        $("body").addClass("inner-page")  
    })

    const [flsSubtitlingData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        // Define the API endpoint URL
        const apiUrl = `${ApiPath()}/get-subtitling-page-details`; // Replace with your API URL
    
        // Use Axios to make a GET request
        axios.get(apiUrl)
        .then((response) => {
            setData(response.data);
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
        })
        .finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!flsSubtitlingData) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{flsSubtitlingData.seo != null && flsSubtitlingData.seo.meta_title != null ? flsSubtitlingData.seo.meta_title : flsSubtitlingData.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={flsSubtitlingData.seo != null && flsSubtitlingData.seo.meta_key != null ? flsSubtitlingData.seo.meta_key : flsSubtitlingData.title} />

                <meta name="description" content={flsSubtitlingData.seo != null && flsSubtitlingData.seo.meta_description != null ? flsSubtitlingData.seo.meta_description : flsSubtitlingData.title} />

                <meta property="og:title" content={flsSubtitlingData.seo != null && flsSubtitlingData.seo.meta_title != null ? flsSubtitlingData.seo.meta_title : flsSubtitlingData.title} />

                <meta property="og:description" content={flsSubtitlingData.seo != null && flsSubtitlingData.seo.meta_description != null ? flsSubtitlingData.seo.meta_description : flsSubtitlingData.title} />

                <meta property="og:image" content={flsSubtitlingData.seo != null && flsSubtitlingData.seo.og_image != null ? flsSubtitlingData.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {flsSubtitlingData.seo != null && flsSubtitlingData.seo.schema_list != null ? flsSubtitlingData.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            
            <ScrollToTop />
            <InnerBanner
                style="digi-inner-banner"
                image={flsSubtitlingData.banner != null ? flsSubtitlingData.banner.media : `${process.env.PUBLIC_URL}/images/go-digital-learning-banner-min.jpg`}>
                
                <div className='text-center'>
                    <CommonHeading
                        mainHeading={flsSubtitlingData.banner != null ? flsSubtitlingData.banner.heading : flsSubtitlingData.title} />
                    <p>
                        {flsSubtitlingData.banner != null ? flsSubtitlingData.banner.subheading : ''}
                    </p>
                </div>
            </InnerBanner>
            
            {flsSubtitlingData.overview != null ?
                <AboutOverview
                    style="delivery-about-sec"
                    image={flsSubtitlingData.overview.gallery != null ? flsSubtitlingData.overview.gallery[0].media : ''} star={Number(flsSubtitlingData?.star)} rating={Number(flsSubtitlingData?.rating)}>
                    
                    <CommonHeading mainHeading={flsSubtitlingData.overview.heading} />
                    <div className="detail-wrapper" dangerouslySetInnerHTML={{ __html: flsSubtitlingData.overview.content}} />
                </AboutOverview>
            : ''}

            <FlsOffers 
                heading={flsSubtitlingData.what_we_offer_section.heading}
                subHeading={flsSubtitlingData.what_we_offer_section.subheading}
                galleryImg={flsSubtitlingData.what_we_offer_section.gallery != null ? flsSubtitlingData.what_we_offer_section.gallery[0].media : ''}
                galleryImgAlt={flsSubtitlingData.what_we_offer_section.gallery != null ? flsSubtitlingData.what_we_offer_section.gallery[0].alt : ''} >
                    <div className="detail-wrapper" dangerouslySetInnerHTML={{ __html: flsSubtitlingData.what_we_offer_section.content}} />
            </FlsOffers>
            
            {flsSubtitlingData.why_choose_us != null ?
                <FlsChooseUs
                    heading={flsSubtitlingData.why_choose_us_section.heading}
                    subHeading={flsSubtitlingData.why_choose_us_section.subheading}
                    galleryImg={flsSubtitlingData.why_choose_us_section.gallery != null ? flsSubtitlingData.why_choose_us_section.gallery[0].media : ''}
                    galleryImgAlt={flsSubtitlingData.why_choose_us_section.gallery != null ? flsSubtitlingData.why_choose_us_section.gallery[0].alt : ''} >

                    {flsSubtitlingData.why_choose_us.map((val) => {
                        return(
                            <FlsChooseUsItems 
                                icon={val.thumb_image} 
                                iconAlt={val.thumb_alt != null ? val.thumb_alt : val.heading}
                                title={val.heading}
                            />
                        )
                    })}
                </FlsChooseUs>
            : ''}

            <FlsGetQuote />
            
        </>
    )
}

export default FlsSubtitling