import $ from 'jquery';
import BlogsList from '../../components/blogs/Blogs';
import ScrollToTop from '../../components/other/ScrollToTop';
import InnerBanner from '../../components/other/InnerBanner'
import {CommonHeading} from "../../components/other/Headingtext-Btn"

import { useEffect, useState } from 'react';
import axios from 'axios';
import ApiPath from '../../components/other/ApiPath';

const FlsBlogsPage = () => {

    $("body").addClass("inner-page")

    const[flsBlogBanner, setData] = useState([])
    const[loading, setLoading] = useState(true)

    useEffect(() => {
        const apiUrl = `${ApiPath()}/get-page-banner-seo/?slug=foreign-language-services/blog`;

        axios.get(apiUrl).then((response) => {
            setData(response.data);

        }).catch((error) => {
            console.error('Error fetching data:', error);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!flsBlogBanner) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }

    return (
        <>
            <ScrollToTop />
            <BlogsList innerbanner="no-require" serviceId="4" style="digi-inner-banner" flsBlogBanner={flsBlogBanner}  />
        </>
    )
}
export default FlsBlogsPage