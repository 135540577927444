import CommonSec from "../../other/CommonSec"
import CommonBtn, { CommonHeading } from "../../other/Headingtext-Btn"

const ConsultancyOfferings = ({sectiontitle,sectiondescription,children }) => {
    return (
        <CommonSec sectionstyle="offerConsulting dark-bg" innerspace="inner-container">
            <div className="text-center">
                <CommonHeading mainHeading={sectiontitle} />
                <p>
                    {sectiondescription}
                </p>
            </div>
            <div className="items d-flex mt-60">
                {children}                
            </div>

        </CommonSec>
    )
}
const ConsultancyOfferingsItem = ({image,imagealt,title,shortdescription,btnlink}) => {
    return (
        <div className="item d-flex">
            <div className="img-wrapper">
                <img src={image} alt={imagealt} />
            </div>
            <div className="content-wrapper">
                <div className="small-heading">
                    {title}
                </div>              
                {shortdescription}                
                <div className="btn-wrapper d-flex mt-30">
                    <CommonBtn link={btnlink} text="Learn More" placing="_blank"/>
                </div>
            </div>
        </div>
    )
}
export default ConsultancyOfferings
export { ConsultancyOfferingsItem }