import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import CommonSec from '../../../components/other/CommonSec'
import LearningManagementTrainings from '../../../components/digilearn/LearningManagementPlatform/LearningManagementTrainings'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import { CommonHeading } from '../../../components/other/Headingtext-Btn'
import ErrorSection from '../../../components/other/ErrorSection';
import ApiPath from '../../../components/other/ApiPath';
const ResourcesProductDemoPage = () => {
    $("body").addClass("inner-page")
    const [productdemodata, setproductdemodata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const productdemopagedata = await fetch(`${ApiPath()}/get-product-demo-details`);
                const currentproductdemopagedata = await productdemopagedata.json();
                setproductdemodata(currentproductdemopagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!productdemodata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{productdemodata.seo != null && productdemodata.seo.meta_title != null ? productdemodata.seo.meta_title : productdemodata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={productdemodata.seo != null && productdemodata.seo.meta_key != null ? productdemodata.seo.meta_key : productdemodata.title} />

                <meta name="description" content={productdemodata.seo != null && productdemodata.seo.meta_description != null ? productdemodata.seo.meta_description : productdemodata.title} />

                <meta property="og:title" content={productdemodata.seo != null && productdemodata.seo.meta_title != null ? productdemodata.seo.meta_title : productdemodata.title} />

                <meta property="og:description" content={productdemodata.seo != null && productdemodata.seo.meta_description != null ? productdemodata.seo.meta_description : productdemodata.title} />

                <meta property="og:image" content={productdemodata.seo != null && productdemodata.seo.og_image != null ? productdemodata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {productdemodata.seo != null && productdemodata.seo.schema_list != null ? productdemodata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            {productdemodata.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={productdemodata.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={productdemodata.banner.heading} />
                        <p>
                            {productdemodata.banner.subheading}
                        </p>
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }
            {/* <CommonSec innerspace="inner-container">
                <p>
                    Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.
                </p>
            </CommonSec> */}
            {productdemodata.our_product.gallery != null
                ?
                <LearningManagementTrainings sectionTitle={productdemodata.our_product.heading} sectionSubTitle={productdemodata.our_product.subheading}>
                    {productdemodata.our_product.gallery.map((val,index) => {
                        return (
                            <div className="item" key={index}>
                                <div className="image"><img src={val.media} alt={val.alt!=null ? val.alt : val.heading} /></div>
                                <div className="detail">
                                    <div className="name">{val.heading}</div>
                                </div>
                            </div>
                        )
                    })}
                </LearningManagementTrainings>
                :
                ""
            }
            <DigiGetInTouch />
        </>
    )
}
export default ResourcesProductDemoPage