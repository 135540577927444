import { Link } from "react-router-dom"
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useContext } from "react";
import { InvestorContext } from "../Data/Context";
const InvestorTabs = (props)=>{
    const InvestorTabData =  useContext(InvestorContext);
    const location = useLocation();
    const isCurrentPath = (path) => {
        return location.pathname === path;
    };
    return(
        <>
            <Helmet>
                <title>{InvestorTabData.seo!=null && InvestorTabData.seo.meta_title!=null ? InvestorTabData.seo.meta_title : InvestorTabData.title}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content={InvestorTabData.seo !=null && InvestorTabData.seo.meta_key!=null ? InvestorTabData.seo.meta_key:InvestorTabData.title } />
                <meta name="description" content={InvestorTabData.seo!=null  && InvestorTabData.seo.meta_description!=null ? InvestorTabData.seo.meta_description : InvestorTabData.title} />
                <meta property="og:title" content={InvestorTabData.seo !=null && InvestorTabData.seo.meta_title != null ? InvestorTabData.seo.meta_title : InvestorTabData.title} />
                <meta property="og:description" content={InvestorTabData.seo !=null && InvestorTabData.seo.meta_description !=null ? InvestorTabData.seo.meta_description : InvestorTabData.title} />               
                <meta property="og:image" content={InvestorTabData.seo!=null && InvestorTabData.seo.og_image!=null ? InvestorTabData.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} /> 
                <meta property="og:url" content={window.location.href} />                       
            </Helmet>
            <section className="investor-tabs common-spacing-top-bottom">
                <div className="inner-container">
                    <ul className="tabs d-flex">
                        {InvestorTabData.investor_relation.map((val)=>{   
                            if(val.status==1){                     
                                return(                                
                                    <li className={isCurrentPath(`/investor/${val.slug}`) ? 'active' :  'non-active'}>
                                        <Link to={`/investor/${val.slug}`}>{val.title}</Link>
                                    </li>                                
                                )
                            }
                        })}
                    </ul>
                </div>
            </section>
        </>
    )
}
export default InvestorTabs