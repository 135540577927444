const TrainingMenu = (props) => {
    return (
        <div className={props.pagemenu}>
            <div className="close-icon"><img src={`${process.env.PUBLIC_URL}/images/icon-close.png`} alt="close" /></div>
            <div className="top">
                <ul className=" d-flex justify-content-end">
                    <li><a href="#" className="email">enquiry@vinsys.com</a></li>
                    <li><a href="#" className="number">Tollfree No.: +254 730 730 000</a></li>
                </ul>
            </div>
            <div className="bottom d-flex align-items-center">
                <ul className="navigation d-flex justify-content-end">
                    <li className="dropdown">
                        <a href="javascript:void(0)">Training</a>
                        <div className="dropdown-menu">
                            <div className="inner-content d-flex">
                                <div className="left-section">
                                    <ul className="items">
                                        <li>
                                            <a href="javascript:void(0)" className="menuLink active" data-href="menuTraining-1"><img src={`${process.env.PUBLIC_URL}/images/megaMenu/icon-domain.svg`} alt="" />Course by Domain</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" className="menuLink" data-href="menuTraining-2"><img src={`${process.env.PUBLIC_URL}/images/megaMenu/icon-domain.svg`} alt="" />Courses by Brand</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" className="menuLink" data-href="menuTraining-3"><img src={`${process.env.PUBLIC_URL}/images/megaMenu/icon-domain.svg`} alt="" />Guarantee to Run classes</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" className="menuLink" data-href="menuTraining-4"><img src={`${process.env.PUBLIC_URL}/images/megaMenu/icon-domain.svg`} alt="" />Delivery Format</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" className="menuLink" data-href="menuTraining-5"><img src={`${process.env.PUBLIC_URL}/images/megaMenu/icon-domain.svg`} alt="" />Learning Plans</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" className="menuLink" data-href="menuTraining-6"><img src={`${process.env.PUBLIC_URL}/images/megaMenu/icon-domain.svg`} alt="" />Promotions</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" className="menuLink" data-href="menuTraining-7"><img src={`${process.env.PUBLIC_URL}/images/megaMenu/icon-domain.svg`} alt="" />Testimonials</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="right-section">
                                    <div className="menuContent active" id="menuTraining-1">
                                        <div className="backButton">Back</div>
                                        <ul>
                                            <li className="item">
                                                <a href="#">5G</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cloud Computing</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Leadership and Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Agile and Scrum</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cybersecurity</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Managing Remote Teams</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Analytics and Data
                                                    Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Data Center</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Networking and Wireless</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Application Development</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">DevOps</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">5G</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cloud Computing</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Leadership and Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Agile and Scrum</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cybersecurity</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Managing Remote Teams</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Analytics and Data
                                                    Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Data Center</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Networking and Wireless</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Application Development</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">DevOps</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cybersecurity</a>
                                            </li>
                                        </ul>
                                        <div className="btn-wrapper">
                                            <a href="#" className="common-btn">View All <i className="icon">&nbsp;</i></a>
                                        </div>
                                    </div>

                                    <div className="menuContent" id="menuTraining-2">
                                        <div className="backButton">Back</div>
                                        <ul>
                                            <li className="item">
                                                <a href="#">5G</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cloud Computing</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Leadership and Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Agile and Scrum</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cybersecurity</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Managing Remote Teams</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Analytics and Data
                                                    Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Data Center</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Networking and Wireless</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Application Development</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">DevOps</a>
                                            </li>

                                        </ul>
                                        <div className="btn-wrapper">
                                            <a href="#" className="common-btn">View All <i className="icon">&nbsp;</i></a>
                                        </div>
                                    </div>

                                    <div className="menuContent" id="menuTraining-3">
                                        <div className="backButton">Back</div>
                                        <ul>
                                            <li className="item">
                                                <a href="#">Analytics and Data
                                                    Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Data Center</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Networking and Wireless</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Application Development</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">DevOps</a>
                                            </li>

                                        </ul>
                                        <div className="btn-wrapper">
                                            <a href="#" className="common-btn">View All <i className="icon">&nbsp;</i></a>
                                        </div>
                                    </div>

                                    <div className="menuContent" id="menuTraining-4">
                                        <div className="backButton">Back</div>
                                        <ul>
                                            <li className="item">
                                                <a href="#">5G</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cloud Computing</a>
                                            </li>

                                            <li className="item">
                                                <a href="#">Application Development</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">DevOps</a>
                                            </li>

                                        </ul>
                                        <div className="btn-wrapper">
                                            <a href="#" className="common-btn">View All <i className="icon">&nbsp;</i></a>
                                        </div>
                                    </div>

                                    <div className="menuContent" id="menuTraining-5">
                                        <div className="backButton">Back</div>
                                        <ul>
                                            <li className="item">
                                                <a href="#">5G</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cloud Computing</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Leadership and Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Agile and Scrum</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cybersecurity</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Managing Remote Teams</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Analytics and Data
                                                    Management</a>
                                            </li>
                                        </ul>
                                        <div className="btn-wrapper">
                                            <a href="#" className="common-btn">View All <i className="icon">&nbsp;</i></a>
                                        </div>
                                    </div>

                                    <div className="menuContent" id="menuTraining-6">
                                        <div className="backButton">Back</div>
                                        <ul>
                                            <li className="item">
                                                <a href="#">5G</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cloud Computing</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Leadership and Management</a>
                                            </li>

                                            <li className="item">
                                                <a href="#">Networking and Wireless</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Application Development</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">DevOps</a>
                                            </li>

                                        </ul>
                                        <div className="btn-wrapper">
                                            <a href="#" className="common-btn">View All <i className="icon">&nbsp;</i></a>
                                        </div>
                                    </div>

                                    <div className="menuContent" id="menuTraining-7">
                                        <div className="backButton">Back</div>
                                        <ul>
                                            <li className="item">
                                                <a href="#">5G</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cloud Computing</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Leadership and Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Agile and Scrum</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cybersecurity</a>
                                            </li>
                                        </ul>
                                        <div className="btn-wrapper">
                                            <a href="#" className="common-btn">View All <i className="icon">&nbsp;</i></a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dropdown">
                        <a href="javascript:void(0)">Solutions</a>
                        <div className="dropdown-menu">
                            <div className="inner-content d-flex">
                                <div className="left-section">
                                    <ul className="items">
                                        <li>
                                            <a href="javascript:void(0)" className="menuLink active" data-href="menuSolutions-1">
                                                <img src={`${process.env.PUBLIC_URL}/images/megaMenu/icon-domain.svg`} alt="" />Learning Plans</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" className="menuLink" data-href="menuSolutions-2"><img src={`${process.env.PUBLIC_URL}/images/megaMenu/icon-domain.svg`} alt="" />Promotions</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" className="menuLink" data-href="menuSolutions-3"><img src={`${process.env.PUBLIC_URL}/images/megaMenu/icon-domain.svg`} alt="" />Testimonials</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" className="menuLink " data-href="menuSolutions-4"><img src={`${process.env.PUBLIC_URL}/images/megaMenu/icon-domain.svg`} alt="" />Course by Domain</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" className="menuLink" data-href="menuSolutions-5"><img src={`${process.env.PUBLIC_URL}/images/megaMenu/icon-domain.svg`} alt="" />Courses by Brand</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" className="menuLink" data-href="menuSolutions-6"><img src={`${process.env.PUBLIC_URL}/images/megaMenu/icon-domain.svg`} alt="" />Guarantee to Run classes</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" className="menuLink" data-href="menuSolutions-7"><img src={`${process.env.PUBLIC_URL}/images/megaMenu/icon-domain.svg`} alt="" />Delivery Format</a>
                                        </li>

                                    </ul>
                                </div>
                                <div className="right-section">
                                    <div className="menuContent" id="menuSolutions-4">
                                        <div className="backButton">Back</div>
                                        <ul>
                                            <li className="item">
                                                <a href="#">5G</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cloud Computing</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Leadership and Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Agile and Scrum</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cybersecurity</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Managing Remote Teams</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Analytics and Data
                                                    Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Data Center</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Networking and Wireless</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Application Development</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">DevOps</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">5G</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cloud Computing</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Leadership and Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Agile and Scrum</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cybersecurity</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Managing Remote Teams</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Analytics and Data
                                                    Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Data Center</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Networking and Wireless</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Application Development</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">DevOps</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cybersecurity</a>
                                            </li>
                                        </ul>
                                        <div className="btn-wrapper">
                                            <a href="#" className="common-btn">View All <i className="icon">&nbsp;</i></a>
                                        </div>
                                    </div>

                                    <div className="menuContent" id="menuSolutions-5">
                                        <div className="backButton">Back</div>
                                        <ul>
                                            <li className="item">
                                                <a href="#">5G</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cloud Computing</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Leadership and Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Agile and Scrum</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cybersecurity</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Managing Remote Teams</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Analytics and Data
                                                    Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Data Center</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Networking and Wireless</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Application Development</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">DevOps</a>
                                            </li>

                                        </ul>
                                        <div className="btn-wrapper">
                                            <a href="#" className="common-btn">View All <i className="icon">&nbsp;</i></a>
                                        </div>
                                    </div>

                                    <div className="menuContent" id="menuSolutions-6">
                                        <div className="backButton">Back</div>
                                        <ul>
                                            <li className="item">
                                                <a href="#">Analytics and Data
                                                    Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Data Center</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Networking and Wireless</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Application Development</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">DevOps</a>
                                            </li>

                                        </ul>
                                        <div className="btn-wrapper">
                                            <a href="#" className="common-btn">View All <i className="icon">&nbsp;</i></a>
                                        </div>
                                    </div>

                                    <div className="menuContent" id="menuSolutions-7">
                                        <div className="backButton">Back</div>
                                        <ul>
                                            <li className="item">
                                                <a href="#">5G</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cloud Computing</a>
                                            </li>

                                            <li className="item">
                                                <a href="#">Application Development</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">DevOps</a>
                                            </li>

                                        </ul>
                                        <div className="btn-wrapper">
                                            <a href="#" className="common-btn">View All <i className="icon">&nbsp;</i></a>
                                        </div>
                                    </div>

                                    <div className="menuContent active" id="menuSolutions-1">
                                        <div className="backButton">Back</div>
                                        <ul>
                                            <li className="item">
                                                <a href="#">5G</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cloud Computing</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Leadership and Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Agile and Scrum</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cybersecurity</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Managing Remote Teams</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Analytics and Data
                                                    Management</a>
                                            </li>
                                        </ul>
                                        <div className="btn-wrapper">
                                            <a href="#" className="common-btn">View All <i className="icon">&nbsp;</i></a>
                                        </div>
                                    </div>

                                    <div className="menuContent" id="menuSolutions-2">
                                        <div className="backButton">Back</div>
                                        <ul>
                                            <li className="item">
                                                <a href="#">5G</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cloud Computing</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Leadership and Management</a>
                                            </li>

                                            <li className="item">
                                                <a href="#">Networking and Wireless</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Application Development</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">DevOps</a>
                                            </li>

                                        </ul>
                                        <div className="btn-wrapper">
                                            <a href="#" className="common-btn">View All <i className="icon">&nbsp;</i></a>
                                        </div>
                                    </div>

                                    <div className="menuContent" id="menuSolutions-3">
                                        <div className="backButton">Back</div>
                                        <ul>
                                            <li className="item">
                                                <a href="#">5G</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cloud Computing</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Leadership and Management</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Agile and Scrum</a>
                                            </li>
                                            <li className="item">
                                                <a href="#">Cybersecurity</a>
                                            </li>
                                        </ul>
                                        <div className="btn-wrapper">
                                            <a href="#" className="common-btn">View All <i className="icon">&nbsp;</i></a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </li>
                    <li className="dropdown"><a href="#">Resources</a></li>
                    <li className="dropdown"><a href="#">Schedules</a></li>
                    <li className='dropdown-menus'><a href="#">About</a>
                        <ul className="dropdown">
                            <li><a href="#">Resources</a></li>
                            <li><a href="#">Schedules</a></li>
                        </ul>
                    </li>
                </ul>
                <div className="cartWrapper">
                    <img src={`${process.env.PUBLIC_URL}/images/megaMenu/icon-cart.svg`} alt="Cart Icon" />
                    <span>01</span>
                </div>

            </div>
        </div>
    )
}
export default TrainingMenu