import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../../components/other/ScrollToTop';
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from '../../../components/other/Headingtext-Btn'
import AboutOverview from '../../../components/about/AboutOverview'
import ContributesSection, { ContributesSectionItem } from '../../../components/digilearn/about/ContributesSection'
import CommonSec from '../../../components/other/CommonSec'
import WhyVinsys from '../../../components/digilearn/LearningManagementPlatform/WhyVinsys'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import ErrorSection from '../../../components/other/ErrorSection';
import ApiPath from '../../../components/other/ApiPath';
const DigilearnTelecommunicationPage = () => {
    $("body").addClass("inner-page")
    const [telecommunicationpagedata, settelecommunicationpagedata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const telecommunicationpagepagedata = await fetch(`${ApiPath()}/get-telecommunication-details`);
                const currenttelecommunicationpagepagedata = await telecommunicationpagepagedata.json();
                settelecommunicationpagedata(currenttelecommunicationpagepagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!telecommunicationpagedata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{telecommunicationpagedata.seo != null && telecommunicationpagedata.seo.meta_title != null ? telecommunicationpagedata.seo.meta_title : telecommunicationpagedata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={telecommunicationpagedata.seo != null && telecommunicationpagedata.seo.meta_key != null ? telecommunicationpagedata.seo.meta_key : telecommunicationpagedata.title} />

                <meta name="description" content={telecommunicationpagedata.seo != null && telecommunicationpagedata.seo.meta_description != null ? telecommunicationpagedata.seo.meta_description : telecommunicationpagedata.title} />

                <meta property="og:title" content={telecommunicationpagedata.seo != null && telecommunicationpagedata.seo.meta_title != null ? telecommunicationpagedata.seo.meta_title : telecommunicationpagedata.title} />

                <meta property="og:description" content={telecommunicationpagedata.seo != null && telecommunicationpagedata.seo.meta_description != null ? telecommunicationpagedata.seo.meta_description : telecommunicationpagedata.title} />

                <meta property="og:image" content={telecommunicationpagedata.seo != null && telecommunicationpagedata.seo.og_image != null ? telecommunicationpagedata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {telecommunicationpagedata.seo != null && telecommunicationpagedata.seo.schema_list != null ? telecommunicationpagedata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            {telecommunicationpagedata.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={telecommunicationpagedata.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={telecommunicationpagedata.banner.heading} />
                        <p>
                            {telecommunicationpagedata.banner.subheading}
                        </p>
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }
            {telecommunicationpagedata.introduction != null
                ?
                <AboutOverview style="delivery-about-sec" image={telecommunicationpagedata.introduction.gallery !=null ? telecommunicationpagedata.introduction.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} star={Number(telecommunicationpagedata?.star)} rating={Number(telecommunicationpagedata?.rating)}>
                    <CommonHeading mainHeading={telecommunicationpagedata.introduction.heading} />
                    <div dangerouslySetInnerHTML={{ __html: telecommunicationpagedata.introduction.content }} />
                </AboutOverview>
                :
                ""
            }
            {telecommunicationpagedata.we_help_section !=null && telecommunicationpagedata.we_help !=null
                ?
                <ContributesSection itemview="two-column" sectionheading={telecommunicationpagedata.we_help_section.heading} sectionshortdescription={telecommunicationpagedata.we_help_section.subheading} >
                    {telecommunicationpagedata.we_help.map((val, index) => {
                        return (
                            <ContributesSectionItem key={index}
                                image={val.media} imagealt={val.alt != null ? val.alt : val.heading}
                                description={val.heading}
                            />
                        )
                    })}
                </ContributesSection>
                :
                ""
            }
            {telecommunicationpagedata.what_we_do_section !=null && telecommunicationpagedata.what_we_do !=null
                ?
                <CommonSec sectionstyle="whatWeDoSection" innerspace="inner-container">
                    <div className="heading-wraper text-center">
                        <CommonHeading mainHeading={telecommunicationpagedata.what_we_do_section.heading} />
                        <p>
                            {telecommunicationpagedata.what_we_do_section.subheading}
                        </p>
                    </div>
                    <div className="items d-flex mt-60">
                        {telecommunicationpagedata.what_we_do.map((val,index) => {
                            return (
                                <div className="item" key={index}>
                                    <div className="icon-wrapper">
                                        <img src={val.thumb_image} alt="" />
                                    </div>
                                    <div className="num"><span>{index+1}</span></div>
                                    <p>{val.heading}</p>
                                </div>
                            )
                        })}
                    </div>
                </CommonSec>
                :
                ""
            }
            {telecommunicationpagedata.why_vinsys_section !=null && telecommunicationpagedata.why_vinsys_section.gallery !=null && telecommunicationpagedata.why_vinsys
                ?
                <WhyVinsys subheading={telecommunicationpagedata.why_vinsys_section.subheading} image={telecommunicationpagedata.why_vinsys_section.gallery[0].media} imagealt={telecommunicationpagedata.why_vinsys_section.gallery[0].alt != null ? telecommunicationpagedata.why_vinsys_section.gallery[0].alt : telecommunicationpagedata.why_vinsys_section.gallery[0].heading}>
                    <ul className='items d-flex mt-60'>
                        {telecommunicationpagedata.why_vinsys.map((val,index) => {
                            return (
                                <li key={index}>
                                    <img src={val.thumb_image} alt={val.heading} />
                                    {val.heading}
                                </li>
                            )
                        })}
                    </ul>
                </WhyVinsys>
                :
                ""
            }
            <DigiGetInTouch />
        </>
    )
}
export default DigilearnTelecommunicationPage