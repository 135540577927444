import $ from 'jquery'
import InnerBanner from '../../components/other/InnerBanner'
import {CommonHeading} from "../../components/other/Headingtext-Btn"
import ScrollToTop from '../../components/other/ScrollToTop';
import CaseStudyFilterItems from "../../components/casestudy/caseStudyFilterItems"
import { Helmet } from 'react-helmet';

import { useEffect, useState } from 'react';

import axios from 'axios';
import ApiPath from '../../components/other/ApiPath';



const FlsCaseStudy = () => {

    $("body").addClass("inner-page")

    const[flsCasestudy, setData] = useState([])
    const[loading, setLoading] = useState(true)

    useEffect(() => {

        const apiUrl = `${ApiPath()}/get-page-banner-seo/?slug=foreign-language-services/case-study`;

        axios.get(apiUrl).then((response) => {
            setData(response.data);

            
        }).catch((error) => {
            console.error('Error fetching data:', error);
        }).finally(() => {
            setLoading(false);
        });
    }, []);




    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!flsCasestudy) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }

    return(
        <>




            <Helmet>
                <title>{flsCasestudy.seo!=null && flsCasestudy.seo.meta_title!=null ? flsCasestudy.seo.meta_title : flsCasestudy.title}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content={flsCasestudy.seo !=null && flsCasestudy.seo.meta_key!=null ? flsCasestudy.seo.meta_key : flsCasestudy.title } />
                <meta name="description" content={flsCasestudy.seo!=null  && flsCasestudy.seo.meta_description!=null ? flsCasestudy.seo.meta_description : flsCasestudy.title} />
                <meta property="og:title" content={flsCasestudy.seo !=null && flsCasestudy.seo.meta_title != null ? flsCasestudy.seo.meta_title : flsCasestudy.title} />
                <meta property="og:description" content={flsCasestudy.seo !=null && flsCasestudy.seo.meta_description !=null ? flsCasestudy.seo.meta_description : flsCasestudy.title} />               
                <meta property="og:image" content={flsCasestudy.seo!=null && flsCasestudy.seo.og_image!=null ?  flsCasestudy.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} /> 
                <meta property="og:url" content={window.location.href} />   
                
                {flsCasestudy.seo.schema_list!=null 
                ?
                flsCasestudy.seo.schema_list.map((val)=>{
                    return(
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                })               
                : ''}
            </Helmet>

            <ScrollToTop />

            <InnerBanner
                style="digi-inner-banner"
                image={flsCasestudy.banner != null ? flsCasestudy.banner.media : `${process.env.PUBLIC_URL}/images/go-digital-learning-banner-min.jpg`}>
                
                <div className='text-center'>
                    <CommonHeading
                        mainHeading={flsCasestudy.banner != null ? flsCasestudy.banner.heading : flsCasestudy.title} />
                    <p>
                        {flsCasestudy.banner != null ? flsCasestudy.banner.subheading : ''}
                    </p>
                </div>
            </InnerBanner>

            <CaseStudyFilterItems moduleID="4" />
        </>
    )
}

export default FlsCaseStudy