import $ from 'jquery'
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from '../../../components/other/Headingtext-Btn'
import IndustriesSection, { IndustriesItem } from '../../../components/other/IndustriesSection'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch';
import { useGetApi } from '../../../utils/Customhooks/ApiCalls'
import { Helmet } from 'react-helmet'

const DigilearnIndustriesPage = () => {
    $("body").addClass("inner-page");

    const { data: digilearnindutriesdata, isLoading, error } = useGetApi("/get-industries-details");



    if (isLoading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (error !== null) {
        return (
            <>
                <h1>{JSON.stringify(error)}</h1>
            </>
        )
    }


    return (
        <>

            <Helmet>
                <title>{digilearnindutriesdata?.title}</title>
            </Helmet>



            <ScrollToTop />
            <InnerBanner style="digi-inner-banner" image={digilearnindutriesdata?.banner?.media ?? `${process.env.PUBLIC_URL}/images/go-digital-learning-banner-min.jpg`}>
                <div className='text-center'>
                    <CommonHeading mainHeading={digilearnindutriesdata?.banner?.heading ?? "Industries"} />
                </div>
            </InnerBanner>
            <IndustriesSection secttionheading={digilearnindutriesdata?.industries_section?.heading ?? "Our Industries"} sectionshortdescription="" styletype="our-industries-ul">

                {
                    digilearnindutriesdata?.industries_list.map((p) => (
                        <>
                            <IndustriesItem key={p.id} datereuire="no-require" shortdescriptionrequire="no-require" image={p?.thumb_image ?? `${process.env.PUBLIC_URL}/images/digitalLearning/industries/Automobile.jpg`} imagealt={p?.thumb_alt ?? "thumb alt"}
                                itemtitle={p?.heading} btnlink={p?.url} btntext={p?.button_text ?? "Learn More"} />
                        </>
                    ))
                }
            </IndustriesSection>
            <DigiGetInTouch />
        </>
    )
}
export default DigilearnIndustriesPage