import React, { useState, useEffect } from "react";
import InnerBanner from "../../components/other/InnerBanner"
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../components/other/ScrollToTop"
import Cookies from 'js-cookie';
import ApiPath from "../../components/other/ApiPath";
import $ from 'jquery'
import ErrorSection from "../../components/other/ErrorSection";
const TermsAndCondtion = () => {
    $("body").addClass("inner-page")
    const [dataTag, setDataTag] = useState(1)
    const TokensCodes = Cookies.get('access_token');
    const [termsandcondtiondata, setTermsAndCondtionData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                //Contact Page Data
                const termsandcondtiondatas = await fetch(`${ApiPath()}/get-term-conditions-page-details`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }

                });
                const currenttermsandcondtiondata = await termsandcondtiondatas.json();
                setTermsAndCondtionData(currenttermsandcondtiondata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [TokensCodes]);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!termsandcondtiondata) {
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{termsandcondtiondata.seo != null && termsandcondtiondata.seo.meta_title != null ? termsandcondtiondata.seo.meta_title : termsandcondtiondata.title}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content={termsandcondtiondata.seo != null && termsandcondtiondata.seo.meta_key != null ? termsandcondtiondata.seo.meta_key : termsandcondtiondata.title} />
                <meta name="description" content={termsandcondtiondata.seo != null && termsandcondtiondata.seo.meta_description != null ? termsandcondtiondata.seo.meta_description : termsandcondtiondata.title} />
                <meta property="og:title" content={termsandcondtiondata.seo != null && termsandcondtiondata.seo.meta_title != null ? termsandcondtiondata.seo.meta_title : termsandcondtiondata.title} />
                <meta property="og:description" content={termsandcondtiondata.seo != null && termsandcondtiondata.seo.meta_description != null ? termsandcondtiondata.seo.meta_description : termsandcondtiondata.title} />
                <meta property="og:image" content={termsandcondtiondata.seo != null && termsandcondtiondata.seo.og_image != null ? termsandcondtiondata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />
                <meta property="og:url" content={window.location.href} />
            </Helmet>
            <ScrollToTop />

            {
                termsandcondtiondata.banner === null ? <>
                    <ErrorSection errorheading="Add Banner" />
                </> : <>
                    <InnerBanner image={termsandcondtiondata.banner != null ? termsandcondtiondata.banner.media : `${process.env.PUBLIC_URL}/images/banner-Terms-Conditions.jpg`} />
                </>
            }

            <section className="textual-pages common-spacing-top-bottom">
                <div className="inner-container">
                    <div className="detail-wrapper" dangerouslySetInnerHTML={{ __html: termsandcondtiondata.content.content }} />
                </div>

            </section>
        </>
    )
}
export default TermsAndCondtion