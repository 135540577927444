import $ from 'jquery'
import React, { useState, useEffect, useRef } from "react";
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../../components/other/ScrollToTop"
import HomeData from "../../../components/Data/HomeData"
import FlsHomeBanner from "../../../components/fls/Home/FlsHomeBanner"
import FlsServices, { FlsServicesItems } from "../../../components/fls/Home/FlsServices"
import FlsCounter, { FlsCounterItems } from "../../../components/fls/Home/FlsCounter"
import FlsGetQuote from "../../../components/fls/Home/FlsGetQuote"
import FlsProcessWrapper, { FlsProcessItems } from "../../../components/fls/Home/FlsProcess"
import FlsIndustries, { FlsIndustriesItems } from "../../../components/fls/Home/FlsIndustries"
import FlsChooseUs, { FlsChooseUsItems } from "../../../components/fls/FlsChooseUs"
import FlsDataSecurity from "../../../components/fls/Home/FlsDataSecurity"
import FmcgCenterImageSection, { FmcgCenterImageSectionItem } from '../../../components/digilearn/about/FmcgCenterImageSection';
import parse from 'html-react-parser';

import axios from 'axios';
import ApiPath from '../../../components/other/ApiPath';
import { Button } from 'react-scroll';

const FlsLandingPage = () => {

    $(document).ready(function () {
        $("body").removeClass("inner-page");
    });

    const scrollRef = useRef(null)

    const [flsdata, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const scrollToQuote = () => {
        scrollRef.current.scrollIntoView();
    }




    useEffect(() => {
        // Define the API endpoint URL
        const apiUrl = `${ApiPath()}/get-fls-home-page-details`; // Replace with your API URL

        // Use Axios to make a GET request
        axios.get(apiUrl)
            .then((response) => {
                setData(response.data);

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setLoading(false);
            });

    }, []);




    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!flsdata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }





    return (
        <>
            <Helmet>
                <title>{flsdata.seo != null && flsdata.seo.meta_title != null ? flsdata.seo.meta_title : flsdata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={flsdata.seo != null && flsdata.seo.meta_key != null ? flsdata.seo.meta_key : flsdata.title} />

                <meta name="description" content={flsdata.seo != null && flsdata.seo.meta_description != null ? flsdata.seo.meta_description : flsdata.title} />

                <meta property="og:title" content={flsdata.seo != null && flsdata.seo.meta_title != null ? flsdata.seo.meta_title : flsdata.title} />

                <meta property="og:description" content={flsdata.seo != null && flsdata.seo.meta_description != null ? flsdata.seo.meta_description : flsdata.title} />

                <meta property="og:image" content={flsdata.seo != null && flsdata.seo.og_image != null ? flsdata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {flsdata.seo != null && flsdata.seo.schema_list != null ? flsdata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>


            <ScrollToTop />
            <HomeData>
                {flsdata.banner != null ?
                    <FlsHomeBanner
                        heading={parse(flsdata.banner.heading)}
                        subheading={parse(flsdata.banner.subheading)}
                        btnText={flsdata.banner.button_text}
                        btnLink={flsdata.banner.url}
                        media={flsdata.banner.media}
                        mediaAlt={flsdata.banner.alt} 
                        scrollToQuote={scrollToQuote}>

                    </FlsHomeBanner>
                    : ''}

                {flsdata.our_services_list != null ?
                    <FlsServices
                        heading={flsdata.our_services_section.heading}
                        subHeading={flsdata.our_services_section.subheading} >

                        {flsdata.our_services_list.map((val) => {
                            return (
                                <FlsServicesItems
                                    listHeading={val.heading}
                                    listURL={val.url}
                                    listIcon={val.media}
                                    listIconAlt={val.alt != null ? val.alt : val.heading}
                                />
                            )
                        })}
                    </FlsServices>
                    : ''}

                {flsdata.speak_for_themselvess_list != null ?
                    <FlsCounter
                        mainHeading={flsdata.speak_for_themselvess_section.heading}
                        subHeading={flsdata.speak_for_themselvess_section.subheading} >

                        {flsdata.speak_for_themselvess_list.map((val) => {
                            return (
                                <FlsCounterItems
                                    listIcon={val.thumb_image}
                                    listIconAlt={val.thumb_alt != null ? val.thumb_alt : val.heading}
                                    listNum={val.heading}
                                    listDesc={val.subheading}
                                />
                            )
                        })}
                    </FlsCounter>
                    : ''}

                <div ref={scrollRef}>
                    <FlsGetQuote />
                </div>




                {flsdata.our_process_list != null ?
                    <FlsProcessWrapper
                        styleClass="gradiant-bg"
                        heading={flsdata.our_process_section.heading}
                        subHeading={flsdata.our_process_section.subheading} >

                        {flsdata.our_process_list.map((val, index) => {
                            return (
                                <FlsProcessItems
                                    itemKey={index + 1}
                                    icon={val.thumb_image}
                                    iconAlt={val.thumb_alt != null ? val.thumb_alt : val.heading}
                                    title={val.heading}
                                />
                            )
                        })}
                    </FlsProcessWrapper>
                    : ''}

                {flsdata.industries_list != null ?
                    <FlsIndustries
                        sectionStyle="flsIndustries"
                        heading={flsdata.industries_section.heading}
                        subHeading={flsdata.industries_section.subheading} >

                        {flsdata.industries_list.slice(0, 14).map((val) => {
                            return (
                                <FlsIndustriesItems
                                    itemImg={val.thumb_image}
                                    itemImgAlt={val.thumb_alt != null ? val.thumb_alt : val.heading}
                                    itemDesc={val.heading}
                                />
                            )
                        })}
                    </FlsIndustries>
                    : ''}

                {flsdata.why_choose_us != null ?
                    <FlsChooseUs
                        heading={flsdata.why_choose_us_section.heading}
                        subHeading={flsdata.why_choose_us_section.subheading}
                        galleryImg={flsdata.why_choose_us_section.gallery != null ? flsdata.why_choose_us_section.gallery[0].media : ''}
                        galleryImgAlt={flsdata.why_choose_us_section.gallery != null ? flsdata.why_choose_us_section.gallery[0].alt : ''} >

                        {flsdata.why_choose_us.map((val) => {
                            return (
                                <FlsChooseUsItems
                                    icon={val.thumb_image}
                                    iconAlt={val.thumb_alt != null ? val.thumb_alt : val.heading}
                                    title={val.heading}
                                />
                            )
                        })}
                    </FlsChooseUs>
                    : ''}

                {flsdata.security_section.security_list != null ?
                    <FlsDataSecurity>
                        <FmcgCenterImageSection
                            sectionstyles="flsDataSecurity dark-bg"
                            sectiontitle={flsdata.security_section.heading}
                            sectionshortdescription={flsdata.security_section.subheading}
                            image={flsdata.security_section.gallery != null ? flsdata.security_section.gallery.media : ''}
                            imagealt={flsdata.security_section.gallery != null ? flsdata.security_section.gallery.alt : ''} >

                            {flsdata.security_section.security_list.map((val) => {
                                return (
                                    <FmcgCenterImageSectionItem
                                        icon={val.thumb_image}
                                        title={val.heading}
                                    />
                                )
                            })}
                        </FmcgCenterImageSection>
                    </FlsDataSecurity>
                    : ''}
            </HomeData>
        </>
    )
}

export default FlsLandingPage