import $, { event } from 'jquery'; 
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import InnerBanner from '../../components/other/InnerBanner';
import JoinTeamSec from '../../components/career/JoinTeamSec';
import CareerPost from '../../components/career/CareerPost';
import SendResumeSec from '../../components/career/SendResume';
import LifeAtVinsys from '../../components/career/LifeAtVinsys';
import CommonBtn,{ CommonHeading } from '../../components/other/Headingtext-Btn';
import ScrollToTop from '../../components/other/ScrollToTop';
import ApiPath from '../../components/other/ApiPath';
import Cookies from 'js-cookie';
const CareerPage = ({breadcrumb})=>{        

    $("body").addClass("inner-page")
    const TokensCodes = Cookies.get('access_token'); 
    const [careerData, setCareerData] = useState(null);
    const [inputValue, setInputValue] = useState('');   
    const [inputValueLocation, setInputValueLocation] = useState('');   
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
        try {
            //Contact Page Data
            const careerpagedata = await fetch(`${ApiPath()}/get-career-page`, {
                headers: {
                    'Authorization': `Bearer ${TokensCodes}`
                }

            });                       
            const currentcareerpagedata = await careerpagedata.json();            
            setCareerData(currentcareerpagedata);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            }
    };
    fetchData();
    }, [TokensCodes]);

    if (loading) {
        return(
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!careerData) {
        return <div className='text-center'>No data available.</div>;
    } 
    const filteredData = careerData.job_list_section.jobs_liste.filter(item => {
        const title = item.title || "";
        const city = item.city || "";
        const intro = item.intro || "";
    
        const lowercaseTitle = title.toLowerCase();
        const lowercaseCity = city.toLowerCase();
        const lowercaseIntro = intro.toLowerCase();
    
        const includesTitle = lowercaseTitle.includes(inputValue);
        const includesCity = lowercaseCity.includes(inputValueLocation);
        const includesIntro = lowercaseIntro.includes(inputValue);
    
        return (includesTitle && includesCity) || (includesIntro && includesCity);
    });  
    const handleClick = () => {        
        setInputValue(inputValue.toLowerCase());
        setInputValueLocation(inputValueLocation.toLowerCase());       
    };
    const handleChange = (event) => {
        setInputValue(event.target.value.toLowerCase());
    };
    const handleChangeLocation = (event) => {  
        setInputValueLocation(event.target.value);       
    }; 
    return(
        <>
           <Helmet>
                <title>{careerData.seo!=null && careerData.seo.meta_title!=null ? careerData.seo.meta_title : careerData.heading}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content={careerData.seo !=null && careerData.seo.meta_key!=null ? careerData.seo.meta_key:careerData.heading } />
                <meta name="description" content={careerData.seo!=null  && careerData.seo.meta_description!=null ? careerData.seo.meta_description : careerData.heading} />
                <meta property="og:title" content={careerData.seo !=null && careerData.seo.meta_title != null ? careerData.seo.meta_title : careerData.heading} />
                <meta property="og:description" content={careerData.seo !=null && careerData.seo.meta_description !=null ? careerData.seo.meta_description : careerData.heading} />               
                <meta property="og:image" content={careerData.seo!=null && careerData.seo.og_image!=null ? careerData.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} /> 
                <meta property="og:url" content={window.location.href} />                       
            </Helmet>
            <ScrollToTop/>

            <InnerBanner style="career-page" image={careerData.banner.media}>   

                     
                <div className='inner'>
                    <CommonHeading mainHeading={careerData.banner.heading} />
                    <div className='jobs-filter d-flex justify-content-between align-items-center'>
                        <div className='item input'>
                            <input type="text" value={inputValue} onChange={handleChange} placeholder="Search by Job , or Keyword" id="name" name="name" />
                        </div>
                        <div className='item select'>
                            <select value={inputValueLocation} onChange={handleChangeLocation} name="location" id="location">
                                <option value="">Location</option>
                                {careerData.job_city_location.map((val)=>{                                 
                                    return(  
                                        <option value={val.city.toLowerCase()} key={val.id}>{val.city}</option>
                                    )                         
                                })}                           
                            </select>
                        </div>
                        <div className='item submit'>
                            <a href="#posts" className="common-btn">Find Job</a>
                        </div>
                    </div>
                </div>              
            </InnerBanner>
           


            <JoinTeamSec title={careerData.page_intro.heading} subTitle={careerData.page_intro.subheading} image={careerData.page_intro.image.media}>
                <div className="description" dangerouslySetInnerHTML={{__html: careerData.page_intro.intro}}></div>
            </JoinTeamSec>              
            <CareerPost title={careerData.job_list_section.heading} subTitle={careerData.job_list_section.subheading}>
                {filteredData.map((val)=>{
                    return(
                        <li key={val.id}>
                            <div className="location">{val.city}</div>
                            <div className="heading">{val.title}</div>
                            {/* <div className="decription">
                                <p>
                                    {val.intro.slice(0, 100) + '...'}
                                </p>
                            </div> */}
                            <div className="links">
                                <CommonBtn link={`${val.slug}`} text="Apply Now" />
                            </div>
                        </li>     
                    )
                    
                })}                
            </CareerPost>
            <SendResumeSec
                image={careerData.send_your_resume_section.image.media}
                title={careerData.send_your_resume_section.subheading}
                subTitle={careerData.send_your_resume_section.heading}
                emailPhone={careerData.send_your_resume_section.intro}
            />
            {/* <LifeAtVinsys/> */}
        </>
    )
}
export default CareerPage;