import $, { data } from 'jquery'
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import { Helmet } from 'react-helmet';
import { CommonHeading } from '../../../components/other/Headingtext-Btn'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import { useEffect, useState } from 'react'
import ApiPath from '../../../components/other/ApiPath';
import ContactDataMain from '../../../components/Data/ContactData';
import GlobalPresence from '../../../components/contact/GlobalPresence.';
import ErrorSection from '../../../components/other/ErrorSection';
const DigilearnLocationPage = () => {
    $("body").addClass("inner-page")
    const [location, setLocation] = useState(null)
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const locationData = await fetch(`${ApiPath()}/get-location-details`);
                const locationDataJson = await locationData.json();
                setLocation(locationDataJson);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")
            }
        };
        fetchData();
    }, []);
    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!location) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
        <Helmet>
            <title>{location.seo != null && location.seo.meta_title != null ? location.seo.meta_title : location.title}</title>

            <link rel="canonical" href={window.location.href} />

            <meta name="keywords" content={location.seo != null && location.seo.meta_key != null ? location.seo.meta_key : location.title} />

            <meta name="description" content={location.seo != null && location.seo.meta_description != null ? location.seo.meta_description : location.title} />

            <meta property="og:title" content={location.seo != null && location.seo.meta_title != null ? location.seo.meta_title : location.title} />

            <meta property="og:description" content={location.seo != null && location.seo.meta_description != null ? location.seo.meta_description : location.title} />

            <meta property="og:image" content={location.seo != null && location.seo.og_image != null ? location.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

            <meta property="og:url" content={window.location.href} />

            {location.seo != null && location.seo.schema_list != null ? location.seo.schema_list.map((val) => {
                return (
                    <script type="application/ld+json">
                        {val.detail}
                    </script>
                )
            }) : ''}
        </Helmet>
            <ScrollToTop />
            {location.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={location.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={location.banner.heading} />
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }
            {/* <CommonSec sectionstyle="digi-learn-location-sec" innerspace="inner-container">
                <div className='heading-wraper text-center'>
                    <CommonHeading mainHeading={location.location_section.heading} />
                    <p>{location.location_section.subheading} </p>
                </div>
                <div className='items d-flex'>
                    {
                        location.locations_list.map((list_val, index) => {
                            return (
                                <div className='item'>
                                    <div className='image'>
                                        <img src={list_val.media} alt={list_val.alt} />
                                    </div>
                                    <div className='detail'>
                                        <div className='country d-flex justify-content-between align-items-center'>
                                            <div className='icon'>
                                                <img src={list_val.thumb_image} alt={list_val.alt} />
                                            </div>
                                            <div className='title'>{list_val.heading}</div>
                                        </div>
                                        <div className='address'>
                                            <p>
                                                {list_val.subheading == null ? "addresss" : list_val.subheading}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </CommonSec> */}
            <ContactDataMain>                
                <GlobalPresence/>
            </ContactDataMain>
            <DigiGetInTouch />
        </>
    )
}
export default DigilearnLocationPage