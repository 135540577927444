import { Link } from "react-router-dom"
import React from "react"
import CommonBtn from "../other/Headingtext-Btn"

const FlsCaseStudyItemWrapper = ({ children }) => {
    return (
        <>
            <ul className={`items d-flex ${React.Children.count(children) === 0 ? 'nodata' : 'data'}`}>
                {children}
            </ul>
        </>
    )
}


const FlsCaseStudyItem = ({ image, imageAlt, UserImage, userImageAlt, userName, caseStudyDate, caseStudyTitle, caseStudyDesc, link, listKey }) => {
    return (
        <>
            <li className="case-study-section">
                <Link className="item wow zoomIn" data-wow-delay="100ms" to={link} key={listKey}>
                    <div className="img-wrapper">
                        <img src={image} alt={imageAlt} />
                    </div>
                    <div className="name-wrapper d-flex align-items-center">
                        <div className="userImg">
                            <img src={UserImage} alt={userImageAlt} />
                        </div>
                        <div className="text">
                            <span className="name">{userName}</span>
                            <span className="date">{caseStudyDate}</span>
                        </div>
                    </div>
                    <div className="content">
                        <div className="caseStudyTitle">{caseStudyTitle}</div>
                        <p dangerouslySetInnerHTML={{ __html: caseStudyDesc }} />
                        {
                            caseStudyDesc.length > 500 && <>
                                <div className={ 'btn-wrapper mt-30 d-flex'}>
                                    <CommonBtn text="Read More"  link={link}/>
                                </div>

                            </>
                        }
                    </div>
                </Link>
            </li>
        </>
    )
}

export default FlsCaseStudyItem
export { FlsCaseStudyItemWrapper }