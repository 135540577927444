import $ from 'jquery'
import ScrollToTop from '../../../components/other/ScrollToTop'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from '../../../components/other/Headingtext-Btn'
import AboutOverview from '../../../components/about/AboutOverview'
import VinsysServices, { VinsysServicesItem } from '../../../components/digilearn/about/VinsysServices'
import ContributesSection, { ContributesSectionItem } from '../../../components/digilearn/about/ContributesSection'
import WhyVinsys from '../../../components/digilearn/LearningManagementPlatform/WhyVinsys'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import ErrorSection from '../../../components/other/ErrorSection';
import ApiPath from '../../../components/other/ApiPath';
const DigilearnBFSIPage = ()=>{
    $("body").addClass("inner-page")
    const [bfsipagedata, setbfsipagedata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const bfsipagepagedata = await fetch(`${ApiPath()}/get-bfsi-details`);
                const currentbfsipagepagedata = await bfsipagepagedata.json();
                setbfsipagedata(currentbfsipagepagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!bfsipagedata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return(
        <>
            <Helmet>
                <title>{bfsipagedata.seo != null && bfsipagedata.seo.meta_title != null ? bfsipagedata.seo.meta_title : bfsipagedata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={bfsipagedata.seo != null && bfsipagedata.seo.meta_key != null ? bfsipagedata.seo.meta_key : bfsipagedata.title} />

                <meta name="description" content={bfsipagedata.seo != null && bfsipagedata.seo.meta_description != null ? bfsipagedata.seo.meta_description : bfsipagedata.title} />

                <meta property="og:title" content={bfsipagedata.seo != null && bfsipagedata.seo.meta_title != null ? bfsipagedata.seo.meta_title : bfsipagedata.title} />

                <meta property="og:description" content={bfsipagedata.seo != null && bfsipagedata.seo.meta_description != null ? bfsipagedata.seo.meta_description : bfsipagedata.title} />

                <meta property="og:image" content={bfsipagedata.seo != null && bfsipagedata.seo.og_image != null ? bfsipagedata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {bfsipagedata.seo != null && bfsipagedata.seo.schema_list != null ? bfsipagedata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            {bfsipagedata.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={bfsipagedata.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={bfsipagedata.banner.heading} />
                        <p>
                            {bfsipagedata.banner.subheading}
                        </p>
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }
            <AboutOverview style="delivery-about-sec" image={bfsipagedata.introduction.gallery[0].media} star={Number(bfsipagedata?.star)} rating={Number(bfsipagedata?.rating)}>
                <CommonHeading mainHeading={bfsipagedata.introduction.heading} />
                <div dangerouslySetInnerHTML={{ __html: bfsipagedata.introduction.content }} />
            </AboutOverview>
            <VinsysServices sectionheading={bfsipagedata.vinsys_services_section.heading} sectiondescription={bfsipagedata.vinsys_services_section.subheading}>
                {bfsipagedata.vinsys_services_list.map((val,index)=>{
                    return(
                        <VinsysServicesItem key={index} icon={val.media} image={val.thumb_image} imagealt={val.thumb_alt!=null ? val.thumb_alt : val.heading} name={val.heading} />   
                    )
                })}                             
            </VinsysServices>
            <ContributesSection itemview="three-column" sectionheading={bfsipagedata.how_vinsys_contributes_section.heading} sectionshortdescription={bfsipagedata.how_vinsys_contributes_section.subheading}>
                {bfsipagedata.how_vinsys_contributes_list.map((val,index)=>{
                    return(
                        <ContributesSectionItem  key={index}
                        image={val.media} imagealt={val.alt} 
                        description={val.heading}
                    /> 
                    )
                })}                   
            </ContributesSection>
            <WhyVinsys subheading={bfsipagedata.why_vinsys_section.subheading} image={bfsipagedata.why_vinsys_section.gallery[0].media} imagealt={bfsipagedata.why_vinsys_section.gallery[0].alt != null ? bfsipagedata.why_vinsys_section.gallery[0].alt : bfsipagedata.why_vinsys_section.gallery[0].heading}>
                <ul className='items d-flex mt-60'>
                    {(bfsipagedata.why_vinsys!=null) 
                        ?
                        bfsipagedata.why_vinsys.map((val, index) => {
                            return (
                                <li key={index}>
                                    <img src={val.thumb_image} alt={val.heading} />
                                    {val.heading}
                                </li>
                            )
                        })
                        : ""                        
                    }
                </ul>
            </WhyVinsys>
            <DigiGetInTouch />
        </>
    )
}
export default DigilearnBFSIPage