import { useContext } from 'react';
import { CommonHeading } from '../other/Headingtext-Btn';
import SpeakerIcon from '../event/image/speaker-icon.svg';
import FacebookIcon from '../image/icon-fb.svg';
import TwitterIcon from '../image/icon-tw.svg';
import InstagramIcon from '../image/icon-insta.svg';
import LinkdinIcon from '../image/icon-link.svg';
import PrintestIcon from '../image/icon-pin.svg';
import { SocialIcon } from '../other/SocialFollowUs';
import { EventContext } from '../Data/Context';
const EventDetailAgenda = ()=>{
    const agendaData = useContext(EventContext)
    return(
        <section className="agenda-sec">
            <div className="inner-container">
                <div className="inner-content">
                    <CommonHeading className="small" mainHeading="Agenda"/>                      
                    <div dangerouslySetInnerHTML={{ __html: agendaData.agenda }}/> 
                    {/* <div className="about-speaker">
                        <div className=" d-flex inner-section align-items-center">
                            <div className="speaker-detail">
                                <div className="img-wrapper">
                                    <img src={`${process.env.PUBLIC_URL}/images/event/speaker-img.png`} alt=""/>
                                    <span className="speaker-icon"><img src={SpeakerIcon} alt=""/></span>
                                </div>
                                <div className="speaker-name">
                                    Swapnajit Patra<span>Backend Developer</span>
                                </div>
                                <div className="d-flex social-icons">
                                    <SocialIcon link="" icon={LinkdinIcon} />
                                    <SocialIcon link="" icon={TwitterIcon} />
                                </div>
                                <div className="speaker d-flex">
                                    <img src={SpeakerIcon} alt="" /> Speaker
                                </div>
                            </div>
                            <div className="speaker-about">
                                <CommonHeading className="small-heading bottom-line" mainHeading="About Swapnajit Patra"/>                                
                                <p>An Autodesk Certified Instructor (Silver), dedicated, resourceful and innovative offering an extensive of more than 11+ years in the domain of industrial designing, training, and operations in the design engineering industry, with a high degree of discipline and involvement. Autodesk certified Professional in AutoCAD, Inventor Professionals, Revit Architecture, Structure and MEP, and Autodesk Certified User in Fusion 360. Certified Master trainer from NASSCOM for product design engineer quality pack.</p>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    )
}
export default EventDetailAgenda;