import InnerBanner from "../../components/other/InnerBanner"
import EventDetailOverview from "../../components/event/EventDetailOverview"
import EventDetailSummary from "../../components/event/EventDetailSummary"
import EventDetailAgenda from "../../components/event/EventDetailAgenda"
import SocialFollowUs from "../../components/other/SocialFollowUs"
import ScrollToTop from '../../components/other/ScrollToTop';
import EventRegisterForm from "../../components/event/EventRegisterForm"
import { useParams } from 'react-router-dom';
import $ from 'jquery'
import EventDetailDataMain from "../../components/Data/EventsDetailData"
const EventDetailPage = ()=>{
    $("body").addClass("inner-page")     
    let { eventsId } = useParams();
    return(
        <>
            <EventDetailDataMain slugId={eventsId}>
                <ScrollToTop/>
                <InnerBanner image={`${process.env.PUBLIC_URL}/images/event/eventDetailBg.webp`} textNeed="no-need" />
                <EventDetailOverview/>
                <EventDetailSummary/>
                <EventDetailAgenda/>
                <SocialFollowUs/>
                <EventRegisterForm/>
            </EventDetailDataMain>
        </>
    )
}

export default EventDetailPage