import { useState, useEffect } from "react";
import Events from "../../components/event/Events";
import { Helmet } from 'react-helmet';
import InnerBanner from "../../components/other/InnerBanner";
import { CommonHeading } from "../../components/other/Headingtext-Btn";
import CommonBtn from "../../components/other/Headingtext-Btn";
import ScrollToTop from '../../components/other/ScrollToTop';
import ApiPath from "../../components/other/ApiPath";
import Cookies from 'js-cookie';
import $ from 'jquery'
import ErrorSection from "../../components/other/ErrorSection";
const EventsPage = () => {
    $("body").addClass("inner-page")
    const TokensCodes = Cookies.get('access_token');
    const [eventsdata, setEventsData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                //Blog Page Data
                const eventspagedata = await fetch(`${ApiPath()}/get-event-list-page`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }

                });
                const currenteventspagedata = await eventspagedata.json();
                setEventsData(currenteventspagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [TokensCodes]);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!eventsdata) {
        return <div className='text-center'>No data available.</div>;
    }
    function curformatDateToCustomFormat() {
        const inputDate = new Date();
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(inputDate.getDate()).padStart(2, '0');

        return `${year}${month}${day}`;
    }
    const dateStr = eventsdata.evnet_list.date
    const curDtae = curformatDateToCustomFormat()
    return (
        <>
            <ScrollToTop />
            <Helmet>
                <title>{eventsdata.seo != null && eventsdata.seo.meta_title != null ? eventsdata.seo.meta_title : eventsdata.title}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content={eventsdata.seo != null && eventsdata.seo.meta_key != null ? eventsdata.seo.meta_key : eventsdata.title} />
                <meta name="description" content={eventsdata.seo != null && eventsdata.seo.meta_description != null ? eventsdata.seo.meta_description : eventsdata.title} />
                <meta property="og:title" content={eventsdata.seo != null && eventsdata.seo.meta_title != null ? eventsdata.seo.meta_title : eventsdata.title} />
                <meta property="og:description" content={eventsdata.seo != null && eventsdata.seo.meta_description != null ? eventsdata.seo.meta_description : eventsdata.title} />
                <meta property="og:image" content={eventsdata.seo != null && eventsdata.seo.og_image != null ? eventsdata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />
                <meta property="og:url" content={window.location.href} />
            </Helmet>

            {
                eventsdata.banner === null ? <>
                    <ErrorSection errorheading="Add Banner" />
                </> : <>
                    <InnerBanner image={eventsdata.banner.media} />
                </>
            }

            <Events>
                {eventsdata.evnet_list.map((val) => {
                    return (
                        <li className='item d-flex'>
                            <div className="img-wrapper">
                                <img src={val.thumb_image} alt="" />
                            </div>
                            <div className="content-box">
                                <div className="event-heading">{val.title}</div>
                                <div className='description' dangerouslySetInnerHTML={{ __html: val.intro }} />
                                <div className="date-wrapper d-flex">
                                    <div className="event-name">{val.event_type}</div>
                                    <div className="date d-flex align-items-center">{val.date}</div>
                                    <div className="event-name">{curDtae <= val.date_formate ? 'Upcoming' : 'Past'}</div>
                                    <CommonBtn class="short" text="View More" link={val.slug} />
                                </div>
                            </div>
                        </li>
                    )
                })}

            </Events>
        </>

    )
}
export default EventsPage;