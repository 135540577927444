import $ from 'jquery'
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from '../../../components/other/Headingtext-Btn'
import AboutOverview from '../../../components/about/AboutOverview'
import VinsysServices, { VinsysServicesItem } from '../../../components/digilearn/about/VinsysServices'
import ContributesSection, { ContributesSectionItem } from '../../../components/digilearn/about/ContributesSection'
import WhyVinsys from '../../../components/digilearn/LearningManagementPlatform/WhyVinsys'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import ErrorSection from '../../../components/other/ErrorSection'
import ApiPath from '../../../components/other/ApiPath'
const DigilearnGovernmentPage = () => {
    $("body").addClass("inner-page")
    const [goverment, setgoverment] = useState(null)
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const govermentData = await fetch(`${ApiPath()}/get-government-details`);
                const govermentDataJson = await govermentData.json();
                setgoverment(govermentDataJson);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")
            }
        };
        fetchData();
    }, []);
    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!goverment) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    console.log(goverment.introduction.gallery[0].media)
    return (
        <>
        <Helmet>
            <title>{goverment.seo != null && goverment.seo.meta_title != null ? goverment.seo.meta_title : goverment.title}</title>

            <link rel="canonical" href={window.location.href} />

            <meta name="keywords" content={goverment.seo != null && goverment.seo.meta_key != null ? goverment.seo.meta_key : goverment.title} />

            <meta name="description" content={goverment.seo != null && goverment.seo.meta_description != null ? goverment.seo.meta_description : goverment.title} />

            <meta property="og:title" content={goverment.seo != null && goverment.seo.meta_title != null ? goverment.seo.meta_title : goverment.title} />

            <meta property="og:description" content={goverment.seo != null && goverment.seo.meta_description != null ? goverment.seo.meta_description : goverment.title} />

            <meta property="og:image" content={goverment.seo != null && goverment.seo.og_image != null ? goverment.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

            <meta property="og:url" content={window.location.href} />

            {goverment.seo != null && goverment.seo.schema_list != null ? goverment.seo.schema_list.map((val) => {
                return (
                    <script type="application/ld+json">
                        {val.detail}
                    </script>
                )
            }) : ''}
        </Helmet>
            <ScrollToTop />
            {goverment.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={goverment.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={goverment.banner.heading} />
                        <p>
                            {goverment.banner.subheading}
                        </p>
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }
            {goverment.introduction != null
                ?
                <AboutOverview style="delivery-about-sec" image={goverment.introduction.gallery !=null ? goverment.introduction.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} star={Number(goverment?.star)} rating={Number(goverment?.rating)}>
                    <CommonHeading mainHeading={goverment.introduction.heading} />
                    <div dangerouslySetInnerHTML={{ __html: goverment.introduction.content }} />
                </AboutOverview>
                :
                ""
            } 
            {goverment.how_vinsys_contributes_section != null && goverment.how_vinsys_contributes_list
                ?          
                <ContributesSection itemview="three-column" sectionheading={goverment.how_vinsys_contributes_section.heading} sectionshortdescription={goverment.how_vinsys_contributes_section.subheading} >
                    {
                        goverment.how_vinsys_contributes_list.map((list_value, index) => (
                            <ContributesSectionItem
                                image={list_value.media} imagealt=""
                                description={list_value.heading}
                                key={index}
                            />
                        ))
                    }
                </ContributesSection>
                :
                ""
             }
             {goverment.vinsys_services_section != null && goverment.vinsys_services_list
                ?
                <VinsysServices sectionheading={goverment.vinsys_services_section.heading} sectiondescription={goverment.vinsys_services_section.subheading}>
                    {
                        goverment.vinsys_services_list.map((list_value, index) => (
                            <VinsysServicesItem icon={list_value.thumb_image} image={list_value.media} imagealt={list_value.alt} name={list_value.heading} key={index} />
                        ))
                    }
                </VinsysServices>
                :
                ""
            }
            {goverment.why_vinsys_section !=null && goverment.why_vinsys
                ?
                <WhyVinsys subheading={goverment.why_vinsys_section.subheading} image={goverment.why_vinsys_section.gallery[0].media} imagealt={goverment.why_vinsys_section.gallery[0].alt != null ? goverment.why_vinsys_section.gallery[0].alt : goverment.why_vinsys_section.gallery[0].heading}>
                    <ul className='items d-flex mt-60'>                   
                        {goverment.why_vinsys.map((val, index) => {
                            return (
                                <li key={index}>
                                    <img src={val.thumb_image} alt={val.heading} />
                                    {val.heading}
                                </li>
                            )
                        })}                       
                        
                    </ul>
                </WhyVinsys>
                :
                ""
            }
            <DigiGetInTouch />
        </>
    )
}
export default DigilearnGovernmentPage