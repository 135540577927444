import React, {useState, useEffect} from "react";
import HomeContext from './Context';
import ApiPath from "../other/ApiPath";
import Cookies from 'js-cookie';
const HomeData = ({children})=>{
    const TokensCodes = Cookies.get('access_token'); 
    const [homedata, setHomeData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {   
            await new Promise(resolve => setTimeout(resolve, 2000));         
        try {
            //Home Page Data
                const homepagedata = await fetch(`${ApiPath()}/get_home_page_details`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }
                }); 
            const currenthomepagedata = await homepagedata.json();
            setHomeData(currenthomepagedata);
            setLoading(false);
        } 
        catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchData();
    }, []);

    if (loading) {
        return(
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!homedata) {
        return <div className='text-center'>No data available.</div>;
    } 
    return(
        <HomeContext.Provider value={homedata}>
            {children}
        </HomeContext.Provider>
    )
}
export default HomeData