import $ from 'jquery'
import ScrollToTop from '../../../components/other/ScrollToTop'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import InnerBanner from '../../../components/other/InnerBanner'
import AboutOverview from '../../../components/about/AboutOverview'
import CommonBtn, { CommonHeading } from '../../../components/other/Headingtext-Btn'
import LearningManagementTrainings from '../../../components/digilearn/LearningManagementPlatform/LearningManagementTrainings'
import CustomerLDJourney, { CustomerLDJourneyItem } from '../../../components/digilearn/CustomContentDevelopment/CustomerLDJourney'
import WhyVinsys from '../../../components/digilearn/LearningManagementPlatform/WhyVinsys'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import ApiPath from '../../../components/other/ApiPath';
import ErrorSection from '../../../components/other/ErrorSection';
import UspsSection from '../../../components/other/UspsSection';
const PerformanceSupportPage = () => {
    $("body").addClass("inner-page")
    let { ccdId } = useParams();
    const [performancesupportdata, setperformancesupportdata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const performancesupportpagedata = await fetch(`${ApiPath()}/get_content_child_by_slug/${ccdId}`);
                // if (!performancesupportdata.ok) {
                //     throw new Error("Request failed"); // You can customize this error message
                // }   
                const currentperformancesupportpagedata = await performancesupportpagedata.json();
                setperformancesupportdata(currentperformancesupportpagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                window.location.href="error/not-found"
                console.log("not load") 
            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!performancesupportdata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{performancesupportdata.seo != null && performancesupportdata.seo.meta_title != null ? performancesupportdata.seo.meta_title : performancesupportdata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={performancesupportdata.seo != null && performancesupportdata.seo.meta_key != null ? performancesupportdata.seo.meta_key : performancesupportdata.title} />

                <meta name="description" content={performancesupportdata.seo != null && performancesupportdata.seo.meta_description != null ? performancesupportdata.seo.meta_description : performancesupportdata.title} />

                <meta property="og:title" content={performancesupportdata.seo != null && performancesupportdata.seo.meta_title != null ? performancesupportdata.seo.meta_title : performancesupportdata.title} />

                <meta property="og:description" content={performancesupportdata.seo != null && performancesupportdata.seo.meta_description != null ? performancesupportdata.seo.meta_description : performancesupportdata.title} />

                <meta property="og:image" content={performancesupportdata.seo != null && performancesupportdata.seo.og_image != null ? performancesupportdata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {performancesupportdata.seo != null && performancesupportdata.seo.schema_list != null ? performancesupportdata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            {performancesupportdata.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={performancesupportdata.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={performancesupportdata.banner != null ? performancesupportdata.banner.heading : performancesupportdata.title} />
                        <p>
                            {performancesupportdata.banner.subheading}
                        </p>
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }
            {performancesupportdata.introduction!=null
            ?
                <AboutOverview style="delivery-about-sec" image={performancesupportdata.introduction.gallery !=null ? performancesupportdata.introduction.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} star={Number(performancesupportdata?.star)} rating={Number(performancesupportdata?.rating)}>
                    <CommonHeading mainHeading={performancesupportdata.introduction.heading} />
                    <div dangerouslySetInnerHTML={{ __html: performancesupportdata.introduction.content }} />
                </AboutOverview>
                :
                ''
            }
            {performancesupportdata.technique_offering_section != null && performancesupportdata.technique_offering_list != null
                ?
                <UspsSection sectionheading={performancesupportdata.technique_offering_section.heading} sectionshortdescription={performancesupportdata.technique_offering_section.subheading}>
                    {performancesupportdata.technique_offering_list.map((val, index) => {
                        return (
                            <div class="item" key={index}>
                                <div class="icon-wrapper">
                                    <img src={val.thumb_image} alt={val.thumb_alt != null ? val.thumb_alt : val.heading} />
                                </div>
                                <div class="small-heading">
                                    {val.heading}
                                </div>
                            </div>
                        )
                    })}
                </UspsSection>
                :
                ''
            }
            {performancesupportdata.offering_feature!=null && performancesupportdata.offering_feature.gallery!=null
                ?
                <LearningManagementTrainings sectionTitle={performancesupportdata.offering_feature.heading} sectionSubTitle={performancesupportdata.offering_feature.subheading}>
                    {performancesupportdata.offering_feature.gallery.map((val,index)=>{
                        return(                      
                        <div className="item" key={index}>
                            <div className="image"><img src={val.media} alt={val.alt} /></div>
                            <div className="detail">
                                <div className="name">{val.heading}</div>
                                <p>
                                    {val.subheading}
                                </p>
                            </div>
                        </div>
                        )
                    })}
                </LearningManagementTrainings>
                :
                ''
            }
            {performancesupportdata.ld_journey != null && performancesupportdata.ld_journey.page_list!=null
                ?
                    <CustomerLDJourney sectionheading={performancesupportdata.ld_journey.heading} sectionshortdescription={performancesupportdata.ld_journey.subheading}>
                        {performancesupportdata.ld_journey.page_list.map((val,index)=>{
                            return(
                                <CustomerLDJourneyItem key={index} numvalue={index+1} image={val.media} imagealt={val.heading} itemtitle={val.heading} description={val.content}/>
                            )
                        })}                
                    </CustomerLDJourney>
                :
                ""
            }
            {performancesupportdata.why_vinsys != null && performancesupportdata.why_vinsys_list!=null
                ?
                    <WhyVinsys subheading={performancesupportdata.why_vinsys.subheading} image={performancesupportdata.why_vinsys.gallery[0].media} imagealt={performancesupportdata.why_vinsys.gallery[0].alt != null ? performancesupportdata.why_vinsys.gallery[0].alt : performancesupportdata.why_vinsys.gallery[0].heading}>
                        <ul className='items d-flex mt-60'>
                            {performancesupportdata.why_vinsys_list.map((val,index) => {
                                return (
                                    <li key={index}>
                                        <img src={val.thumb_image} alt={val.heading} />
                                        {val.heading}
                                    </li>
                                )
                            })}
                        </ul>
                    </WhyVinsys>
                :
                ''
            }
            <DigiGetInTouch />
        </>
    )
}
export default PerformanceSupportPage