import CommonSec from "../../other/CommonSec"
import { CommonHeading } from "../../other/Headingtext-Btn"
const VinsysServices = ({ sectionheading, sectiondescription, children }) => {
    return (
        <CommonSec sectionstyle="vinsysServiceSection" innerspace="inner-container">
            <div className="heading-wraper text-center">
                <CommonHeading mainHeading={sectionheading} />
                <p>
                    {sectiondescription}
                </p>
            </div>
            <div className="items d-flex mt-60">
                {children}
            </div>
        </CommonSec>
    )
}
const VinsysServicesItem = ({icon, image, imagealt, name}) => {
    return (
        <div class="item">
            <div class="icon-wrapper">
                <img src={icon} alt="" />
            </div>
            <p>{name}</p>
            <div class="img-wrapper">
                <img src={image} alt={imagealt} />
            </div>
        </div>
    )
}
export default VinsysServices
export { VinsysServicesItem }