import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const ChildMenu = ({ val}) => {
    
    const [isActive, setIsActive] = useState(false);

    const handleToggle = () => {
        setIsActive(!isActive);
    };



    return (
        <>
            <li className='dropdown-menus' key={val.id}><Link to={val.url} target={val.menu_type == 'url' ? '_blank' : ''}>{val.title}</Link>
                <span className='arrow-btn' onClick={handleToggle}></span>

                <ul className={isActive ? `dropdown  d-block` : `dropdown d-none`} >
                    {val.child_menu.map((val) => {
                        return (
                            <li key={val.id}><Link to={val.url} target={val.menu_type == 'url' ? '_blank' : ''}>{val.title}</Link></li>

                            // <LiChildMenu val={val}/>
                        )

                    })}
                </ul>
            </li>

        </>
    )
}

const LiChildMenu = ({val}) =>{

    return(
        <>
         <li key={val.id}><Link to={val.url} target={val.menu_type == 'url' ? '_blank' : ''}>{val.title}</Link></li>

        </>
    )

}

export default ChildMenu