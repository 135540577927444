import CommonSec from "../../other/CommonSec"
import CommonBtn, { CommonHeading } from "../../other/Headingtext-Btn"
const CustomerLDJourney = ({ sectionheading,sectionshortdescription,children }) => {
    return (
        <CommonSec sectionstyle="customerJourney" innerspace="inner-container">
            <div className="text-center">
                <CommonHeading mainHeading={sectionheading} />
                <p>{sectionshortdescription}</p>
            </div>
            <div className="items d-flex mt-60">
                {children}
            </div>
        </CommonSec>
    )
}
const CustomerLDJourneyItem = ({numvalue,image,imagealt,itemtitle,description}) => {
    return (
        <div className="item d-flex">
            <div className="left-section">
                <div className="number">
                    {numvalue}
                </div>
                <div className="img-wrapper">
                    <img src={image} alt={imagealt} />
                </div>
            </div>
            <div className="right-section">
                <div className="sm-heading">
                    {itemtitle}
                </div>
                <div dangerouslySetInnerHTML={{ __html: description }}/>
            </div>
        </div>
    )
}
export default CustomerLDJourney
export { CustomerLDJourneyItem }