import CommonSec from "../../other/CommonSec"
import { CommonHeading } from "../../other/Headingtext-Btn"

const FeatureCourses = ({topStyle, sctionTitle,children})=>{
    return(
        <CommonSec sectionstyle={`digilearn-feature-courses ${topStyle}`} innerspace="inner-container">
            <CommonHeading class="text-center" mainHeading={sctionTitle}/>
            <ul className="d-flex">
                {children}
            </ul>
        </CommonSec>
    )
}
export default FeatureCourses