import InnerBanner from "../../../components/other/InnerBanner";
import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../../components/other/ScrollToTop";
import AboutOverview from "../../../components/about/AboutOverview"
import { CommonHeading } from "../../../components/other/Headingtext-Btn";
import DigiGetInTouch from "../../../components/digilearn/Home/DigiGetInTouch";
import DigitalCourses, { DigitalCoursesItem } from "../../../components/digilearn/GoDigitalLearning/DigitalCourses";
import FmcgCenterImageSection, { FmcgCenterImageSectionItem } from '../../../components/digilearn/about/FmcgCenterImageSection'
import ErrorSection from "../../../components/other/ErrorSection";
import ApiPath from "../../../components/other/ApiPath";
const GoDigitalLearningPage = () => {
    $("body").addClass("inner-page")
    const [godigitaldata, setgodigitaldata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const godigitalpagedata = await fetch(`${ApiPath()}/get-ready-to-go-digilearn-page-details`);
                const currentgodigitalpagedata = await godigitalpagedata.json();
                setgodigitaldata(currentgodigitalpagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!godigitaldata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{godigitaldata.seo != null && godigitaldata.seo.meta_title != null ? godigitaldata.seo.meta_title : godigitaldata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={godigitaldata.seo != null && godigitaldata.seo.meta_key != null ? godigitaldata.seo.meta_key : godigitaldata.title} />

                <meta name="description" content={godigitaldata.seo != null && godigitaldata.seo.meta_description != null ? godigitaldata.seo.meta_description : godigitaldata.title} />

                <meta property="og:title" content={godigitaldata.seo != null && godigitaldata.seo.meta_title != null ? godigitaldata.seo.meta_title : godigitaldata.title} />

                <meta property="og:description" content={godigitaldata.seo != null && godigitaldata.seo.meta_description != null ? godigitaldata.seo.meta_description : godigitaldata.title} />

                <meta property="og:image" content={godigitaldata.seo != null && godigitaldata.seo.og_image != null ? godigitaldata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {godigitaldata.seo != null && godigitaldata.seo.schema_list != null ? godigitaldata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            {godigitaldata.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={godigitaldata.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={godigitaldata.banner.heading} />
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }
            {godigitaldata.digital_learning_content != null
                ?
                <AboutOverview style="delivery-about-sec" image={godigitaldata.digital_learning_content.gallery != null ? godigitaldata.digital_learning_content.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} star={Number(godigitaldata?.star)} rating={Number(godigitaldata?.rating)}>
                    <CommonHeading mainHeading={godigitaldata.digital_learning_content.heading} />
                    <div dangerouslySetInnerHTML={{ __html: godigitaldata.digital_learning_content.content }} />
                </AboutOverview>
                :
                ""
            }
            {godigitaldata.cource_list != null
                ?
                <DigitalCourses sectionheading={godigitaldata.our_cource_section.heading} sectionshortdescription={godigitaldata.our_cource_section.subheading}>
                    {godigitaldata.cource_list.map((val, index) => {
                        return (
                            <DigitalCoursesItem image={val.thumb_image} imagealt={val.thumb_alt} title={val.heading} description={val.short_content} url={val.slug} />
                        )
                    })}
                </DigitalCourses>
                :
                ""
            }
            {godigitaldata.offer_feature != null
                ?
                <FmcgCenterImageSection sectionstyles="aviationSection offers-feature dark-bg"
                    sectiontitle={godigitaldata.offer_feature_section.heading} sectionshortdescription={godigitaldata.offer_feature_section.subheading}
                    image={`${process.env.PUBLIC_URL}/images/digi-laptop-image.png`} imagealt="" videoimage={godigitaldata.offer_feature_section.gallery !=null ? godigitaldata.offer_feature_section.gallery[0].media : `${process.env.PUBLIC_URL}/images/digi-video.png`}>
                    {godigitaldata.offer_feature.map((val, index) => {
                        return (
                            <FmcgCenterImageSectionItem
                                icon={val.thumb_image}
                                heading={val.heading}
                                title={<div className="description" dangerouslySetInnerHTML={{ __html: val.content }} />}
                            />
                        )
                    })}
                </FmcgCenterImageSection>
                :
                ""
            }
            <DigiGetInTouch />
        </>
    )
}
export default GoDigitalLearningPage;