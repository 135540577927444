import { Link } from "react-router-dom"
import React, { useState, useEffect } from 'react';
import ApiPath from '../components/other/ApiPath';
import Cookies from 'js-cookie';
import ChildMenu from "../components/childmenu/ChildMenu";

const FlsMenu = (props) => {

    const TokensCodes = Cookies.get('access_token');
    const [isActive, setIsActive] = useState(false);
    const handleToggle = () => {
        setIsActive(!isActive);
    };
    const [digilearnmenu, setDigiLearnMenu] = useState(null);
    const [flsHeaderContent, setFlsHeaderContent] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {

                //Header Menu Data
                const digiLearnMenu = await fetch(`${ApiPath()}/fls_header_menu/`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }

                });
                const Currentdigilearnmenu = await digiLearnMenu.json();

                setDigiLearnMenu(Currentdigilearnmenu);

                //Header Menu Data
                const flsHeaderContent = await fetch(`${ApiPath()}/fls-get_header_content_details/`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }

                });
                const currentFlsHeaderContent = await flsHeaderContent.json();

                setFlsHeaderContent(currentFlsHeaderContent);

                setLoading(false);
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [TokensCodes]);

    
    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!digilearnmenu, !flsHeaderContent) {
        return <div className='text-center'>No data available.</div>;
    }

    return (
        <div className={props.pagemenu}>
            <div className="close-icon"><img src={`${process.env.PUBLIC_URL}/images/icon-close.png`} alt="close" /></div>
            <div className="top">
                <ul className=" d-flex justify-content-end">
                    {flsHeaderContent.contact_list.map((val) => {
                        return (
                            <>
                                <li><a href={val.url} className="icon"><img src={val.thumb_image} alt={val.thumb_alt} />{val.button_text}</a></li>
                            </>
                        )
                    })}
                </ul>
            </div>
            <div className="bottom">
                <ul className='d-flex justify-content-end'>
                    {/* <li className='single-menu' ><Link to="digital-learning-services/">Home</Link></li> */}
                    {digilearnmenu.map((val) => {
                        if (val.child_menu === null) {
                            return (
                                <>
                                    {
                                        val.url === "/" ? <li className='single-menu'><Link to={`foreign-language-services`}>{val.title}</Link></li> : <li className='single-menu' key={val.id}><Link to={`foreign-language-services/${val.url}`}>{val.title}</Link></li>
                                    }

                                </>
                            )
                        }
                        
                        else {
                            return (
                                <ChildMenu val={val} key={val.id} />
                            )
                        }
                    })}
                </ul>
            </div>
        </div>
    )
}
export default FlsMenu