import $ from 'jquery';
import CommonSec from "../other/CommonSec";
import { CommonHeading } from "../other/Headingtext-Btn";

const FlsIndustriesArea = ({commonHeading,innerText,styleClass,centerImg,children}) => {
    $(document).ready(function () {
        var radius = 390;
        var fields = $('.flsIndustriesArea .item'), container = $('.itemsWrapper'), width = container.width(), height = container.height();
        var angle = 0, step = (2*Math.PI) / fields.length;
        fields.each(function() {
            var x = Math.round(width/2 + radius * Math.cos(angle) - $(this).width()/2);
            var y = Math.round(height/2 + radius * Math.sin(angle) - $(this).height()/2);
            $(this).css({
                left: x + 'px',
                top: y + 'px'
            });
            angle += step;
        });
    })
    
    return (
        <CommonSec sectionstyle={`flsIndustriesArea ${styleClass}`} innerspace="inner-container">
            <div className="heading-wrapper text-center">
                <CommonHeading mainHeading={commonHeading}></CommonHeading>
                <p>{innerText}</p>
            </div>
            
            <div className="inner-content d-flex justify-content-center mt-60">
                <div className="itemsWrapper">
                    <div className="img-wrapper">
                        <img src={centerImg} alt="Choose Us" />

                        <span class="border-animation ba1"></span>
                        <span class="border-animation ba2"></span>
                        <span class="border-animation ba3"></span>
                    </div>
                    {children}
                </div>
            </div>
        </CommonSec>
    )
}

const FlsIndustriesAreaItems = ({icon,title, content}) => {
    return (
        <div className="item">
            <div className='icon-wrapper'>
                <img src={icon} alt="icon-1" />
            </div>
            <div className="heading">{title}</div>
            <p>{content}</p>
        </div>
    )
}

export default FlsIndustriesArea
export {FlsIndustriesAreaItems}