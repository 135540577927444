import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import AboutOverview from '../../../components/about/AboutOverview'
import CommonBtn, { CommonHeading } from '../../../components/other/Headingtext-Btn'
import PartnersProgramBenifits from '../../../components/digilearn/Partners/PartnersProgramBenifits'
import ErrorSection from '../../../components/other/ErrorSection';
import ApiPath from '../../../components/other/ApiPath';
const PartnersPage = () => {
    $("body").addClass("inner-page")
    const [tabid, setTabid] = useState('tab-1')
    const [partnerspagedata, setpartnerspagedata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const partnerspagepagedata = await fetch(`${ApiPath()}/get-partner-page`);
                const currentpartnerspagepagedata = await partnerspagepagedata.json();
                setpartnerspagedata(currentpartnerspagepagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!partnerspagedata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{partnerspagedata.seo != null && partnerspagedata.seo.meta_title != null ? partnerspagedata.seo.meta_title : partnerspagedata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={partnerspagedata.seo != null && partnerspagedata.seo.meta_key != null ? partnerspagedata.seo.meta_key : partnerspagedata.title} />

                <meta name="description" content={partnerspagedata.seo != null && partnerspagedata.seo.meta_description != null ? partnerspagedata.seo.meta_description : partnerspagedata.title} />

                <meta property="og:title" content={partnerspagedata.seo != null && partnerspagedata.seo.meta_title != null ? partnerspagedata.seo.meta_title : partnerspagedata.title} />

                <meta property="og:description" content={partnerspagedata.seo != null && partnerspagedata.seo.meta_description != null ? partnerspagedata.seo.meta_description : partnerspagedata.title} />

                <meta property="og:image" content={partnerspagedata.seo != null && partnerspagedata.seo.og_image != null ? partnerspagedata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {partnerspagedata.seo != null && partnerspagedata.seo.schema_list != null ? partnerspagedata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            {partnerspagedata.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={partnerspagedata.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={partnerspagedata.banner.heading} />
                        <p>
                            {partnerspagedata.banner.subheading}
                        </p>
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }
            {partnerspagedata.introduction !=null
                ?
                <AboutOverview style="delivery-about-sec" image={partnerspagedata.introduction.gallery != null ? partnerspagedata.introduction.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} star={Number(partnerspagedata?.star)} rating={Number(partnerspagedata?.rating)}>
                    <CommonHeading mainHeading={partnerspagedata.introduction.heading} />
                    <div dangerouslySetInnerHTML={{ __html: partnerspagedata.introduction.content }} />
                </AboutOverview>
                :
                ''
            }            
            <PartnersProgramBenifits sectiontitle={partnerspagedata.program_benifits.heading} sectionsmalldesc={partnerspagedata.program_benifits.subheading}>
                {partnerspagedata.program_benifits !=null 
                    ?
                    <div className='top-section d-flex mt-60'>
                        <div className="img-wrapper">
                            <img src={partnerspagedata.program_benifits.gallery !=null ? partnerspagedata.program_benifits.gallery[0].media : `${process.env.PUBLIC_URL}/images/No_Image_Available.jpg`} alt={partnerspagedata.program_benifits.gallery !=null ? partnerspagedata.program_benifits.gallery[0].alt : ""} />
                        </div>
                        <div className="content-wrapper">
                            <div dangerouslySetInnerHTML={{ __html: partnerspagedata.program_benifits.content }} />
                        </div>
                    </div>
                    :
                    ""                
                }
                {partnerspagedata.partners !=null 
                    ?
                    <div className="tabs-wrapper mt-60">
                        <div className="tab-items">
                            {partnerspagedata.partners.map((val, index) => {
                                return (
                                    <div key={index} onClick={() => { setTabid(`tab-${index + 1}`) }} className={`item ${tabid === `tab-${index + 1}` ? 'active' : ''}`}>
                                        <img src={val.media} alt={val.alt} />
                                    </div>
                                )
                            })}
                        </div>
                        <div className="content">
                            {partnerspagedata.partners.map((val,index) => {
                                return (
                                    <div key={index} className={`${tabid === `tab-${index + 1}` ? 'active' : 'no-require'}`}>
                                        <div className="sm-heading">
                                            {val.heading}
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: val.content }} />
                                        {/* <div className="btn-wrapper d-flex">
                                            <CommonBtn link={val.broucher} text="Download Broucher"/>
                                        </div> */}
                                    </div>
                                )
                            })}


                        </div>
                    </div>
                    :
                    ""
                }
            </PartnersProgramBenifits>
        </>

    )
}
export default PartnersPage;