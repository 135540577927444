import $ from 'jquery'
import FlsCaseStudyItem from "./flsCaseStudyItem"
import { useEffect, useState } from 'react';
import axios from 'axios';
import ApiPath from '../../components/other/ApiPath';

const LatestCaseStudy = () => {
    $("body").addClass("inner-page")

    const [latestdata, setData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const apiUrl = `${ApiPath()}/get-case-study-list`;

        axios.get(apiUrl).then((response) => {
            setData(response.data)
        }).catch((error) => {
            console.error('Error fetching data:', error);
        }).finally(() => {
            setLoading(false);
        });

    }, [])

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!latestdata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }

    return (
        <>
            {latestdata.case_study_list.slice(0, 5).map((val) => {
                return (
                    <FlsCaseStudyItem
                        key={val.id}
                        link={val.slug}
                        image={val.banner}
                        imageAlt={val.banner_alt != null ? val.banner_alt : val.title}
                        UserImage={val.author.image}
                        userImageAlt={val.author.alt != null ? val.author.alt : val.author.name}
                        userName={val.author.name}
                        caseStudyDate={val.modify_date != null ? val.modify_date : val.published_date}
                        caseStudyTitle={val.title}
                        caseStudyDesc={val.content && val.content.length > 350 ? val.content.slice(0, 350) + '..' : val.content} />
                )
            })}
        </>
    )
}

export default LatestCaseStudy