const Events = ({children})=>{
    return(
        <section class="eventslisting-sec common-spacing-top-bottom">
            <div class="inner-container">                
                <ul class="event-items">
                    {children} 
                </ul>               
            </div>
        </section>
    )
}
export default Events;