import CommonBtn from '../other/Headingtext-Btn';
import { Helmet } from 'react-helmet';
import DateIcon from '../event/image/e-date.svg';
import EventTypeIcon from '../event/image/event-type.svg';
import { useContext } from 'react';
import { EventContext } from '../Data/Context';
import $ from 'jquery'
import { useState } from 'react';
const EventDetailOverview = ()=>{ 
    const popupForm = ()=>{
        $('#apply-form').addClass("show-form")
        $('body').addClass("popup-added")
    }
    const EventDataOverview = useContext(EventContext)
    function curformatDateToCustomFormat() {       
        const inputDate = new Date();
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(inputDate.getDate()).padStart(2, '0');
        
        return `${year}${month}${day}`;
    }  
    function formatDateToCustomFormat(dateString) {       
        const inputDate = new Date(dateString.replace(/(\d+)(st|nd|rd|th)/, '$1'));
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(inputDate.getDate()).padStart(2, '0');        
        return `${year}${month}${day}`;
    }   
    function formatDateToCustomFormatOutlook(dateString) {       
        const inputDate = new Date(dateString.replace(/(\d+)(st|nd|rd|th)/, '$1'));
        const year = inputDate.getFullYear();
        const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(inputDate.getDate()).padStart(2, '0');        
        return `${year}-${month}-${day}`;
    } 
    const dateStr = EventDataOverview.date    
    const curDtae = curformatDateToCustomFormat()
    const formattedDate = formatDateToCustomFormat(dateStr);
    const formattedDateOutlook = formatDateToCustomFormatOutlook(dateStr);
    const [eventsdetail, SetEventsDetail] = useState({
        eventName : EventDataOverview.title,
        location: `${EventDataOverview.state}  ${EventDataOverview.country}`,
        dateTime: formattedDate,
        dateTimeOutlook: formattedDateOutlook
    }) 
    return(
        <>
        <Helmet>
            <title>{EventDataOverview.seo!=null && EventDataOverview.seo.meta_title!=null ? EventDataOverview.seo.meta_title : EventDataOverview.heading}</title>
            <link rel="canonical" href={window.location.href} />
            <meta name="keywords" content={EventDataOverview.seo !=null && EventDataOverview.seo.meta_key!=null ? EventDataOverview.seo.meta_key:EventDataOverview.heading } />
            <meta name="description" content={EventDataOverview.seo!=null  && EventDataOverview.seo.meta_description!=null ? EventDataOverview.seo.meta_description : EventDataOverview.heading} />
            <meta property="og:title" content={EventDataOverview.seo !=null && EventDataOverview.seo.meta_title != null ? EventDataOverview.seo.meta_title : EventDataOverview.heading} />
            <meta property="og:description" content={EventDataOverview.seo !=null && EventDataOverview.seo.meta_description !=null ? EventDataOverview.seo.meta_description : EventDataOverview.heading} />               
            <meta property="og:image" content={EventDataOverview.seo!=null && EventDataOverview.seo.og_image!=null ?  EventDataOverview.seo.og_image  : `${process.env.PUBLIC_URL}/images/logo.png`} />
            <meta property="og:url" content={window.location.href} />                       
        </Helmet>
        <section className="event-detail-sec">
            <div className="inner-container">
                <div className="inner-content">
                    <div className="event-detail d-flex align-items-center">
                        <div className="event-name">
                            <span>Featured Event</span>{EventDataOverview.title}
                        </div>
                        {/* <div className="event-type d-flex align-items-center">
                            <img src={EventTypeIcon} alt=""/> Virtual Event
                        </div> */}
                    </div>
                    <ul className="event-timings d-flex">
                        <li className="d-flex align-items-center">
                            <img src={DateIcon} alt=""/>
                            <div className="timing"><span>Event Date</span>{EventDataOverview.date}</div>
                        </li>
                        <li className="d-flex align-items-center">
                            <img src={DateIcon} alt=""/>
                            <div className="timing"><span>Event Time</span>10:00 AM - 12:00 PM ( EST )</div>
                        </li>
                        <li className="d-flex align-items-center">
                            <img src={DateIcon} alt=""/>
                            <div className="timing"><span>Event Location</span>{`${EventDataOverview.state}, ${EventDataOverview.country}`}</div>
                        </li>
                    </ul>
                    <div className="btn-wrapper d-flex">   
                        <ul>
                            <li className={`common-btn light-bg ${curDtae <= eventsdetail.dateTime ? 'require' : 'not-require'}`}>Add To My Calendar
                            <ul>
                                <li className='common-btn light-bg'><a href={`https://calendar.google.com/calendar/render?action=TEMPLATE&text=${eventsdetail.eventName}&details=Book My Events&location=${eventsdetail.location}&trp=true&dates=${eventsdetail.dateTime}/${eventsdetail.dateTime}`} target='_blank'>Google</a></li>
                                <li className='common-btn light-bg'><a href={`https://outlook.office.com/owa/?path=2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${eventsdetail.dateTimeOutlook}&enddt=${eventsdetail.dateTimeOutlook}&subject=${eventsdetail.eventName}&body=Book My Events&location=${eventsdetail.location}`} target='_blank'>OutLook</a></li>
                            </ul>
                            
                            </li>   
                        </ul>                     
                       {/* <CommonBtn class={`light-bg ${curDtae <= eventsdetail.dateTime ? 'require' : 'not-require'}`} placing="_blank" link={`https://calendar.google.com/calendar/render?action=TEMPLATE&text=${eventsdetail.eventName}&details=Book My Events&location=${eventsdetail.location}&trp=true&dates=${eventsdetail.dateTime}/${eventsdetail.dateTime}`} text="Add To My Calendar"/>  */}
                       <button className={`common-btn ${curDtae <= eventsdetail.dateTime ? 'require' : 'not-require'}`} type='button' onClick={popupForm}>Register Now <i className="icon">&nbsp;</i></button>                   
                    </div>
                </div>
            </div>           
        </section>
        </>
    )
   
    
}


export default EventDetailOverview;