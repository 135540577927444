import $ from 'jquery'
import React, { useState, useEffect } from "react"
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../../components/other/ScrollToTop"
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from "../../../components/other/Headingtext-Btn"
import AboutOverview from "../../../components/about/AboutOverview"
import FlsWhatOffer, {FlsWhatOfferItems} from "../../../components/fls/FlsWhatOffer"
import FlsChooseUs, {FlsChooseUsItems} from "../../../components/fls/FlsChooseUs"
import FlsGetQuote from "../../../components/fls/Home/FlsGetQuote"
import FlsProcessWrapper, {FlsProcessItems} from "../../../components/fls/Home/FlsProcess"
import axios from 'axios';
import ApiPath from '../../../components/other/ApiPath';

const FlsVoiceOver = () => {
    $(document).ready(function() {
        $("body").addClass("inner-page")  
    })

    const [flsVoiceoverdata, setData] = useState([]);
    const [addClass, setAddClass] = useState(false);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        // Define the API endpoint URL
        const apiUrl = `${ApiPath()}/get-voiceover-page-details`; // Replace with your API URL
    
        // Use Axios to make a GET request
        axios.get(apiUrl)
        .then((response) => {
            setData(response.data);

            // Check if any item's index is greater than 4
            const shouldAddClass = response.data.what_we_offer_list.some((item, index) => index >= 4);
            setAddClass(shouldAddClass);
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
        })
        .finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!flsVoiceoverdata) {
      
        return <div className='text-center'>No data available.</div>;
    }

    return(
        <>
            <Helmet>
                <title>{flsVoiceoverdata.seo != null && flsVoiceoverdata.seo.meta_title != null ? flsVoiceoverdata.seo.meta_title : flsVoiceoverdata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={flsVoiceoverdata.seo != null && flsVoiceoverdata.seo.meta_key != null ? flsVoiceoverdata.seo.meta_key : flsVoiceoverdata.title} />

                <meta name="description" content={flsVoiceoverdata.seo != null && flsVoiceoverdata.seo.meta_description != null ? flsVoiceoverdata.seo.meta_description : flsVoiceoverdata.title} />

                <meta property="og:title" content={flsVoiceoverdata.seo != null && flsVoiceoverdata.seo.meta_title != null ? flsVoiceoverdata.seo.meta_title : flsVoiceoverdata.title} />

                <meta property="og:description" content={flsVoiceoverdata.seo != null && flsVoiceoverdata.seo.meta_description != null ? flsVoiceoverdata.seo.meta_description : flsVoiceoverdata.title} />

                <meta property="og:image" content={flsVoiceoverdata.seo != null && flsVoiceoverdata.seo.og_image != null ? flsVoiceoverdata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {flsVoiceoverdata.seo != null && flsVoiceoverdata.seo.schema_list != null ? flsVoiceoverdata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            
            <ScrollToTop />
            <InnerBanner
                style="digi-inner-banner"
                image={flsVoiceoverdata.banner != null ? flsVoiceoverdata.banner.media : `${process.env.PUBLIC_URL}/images/go-digital-learning-banner-min.jpg`}>
                
                <div className='text-center'>
                    <CommonHeading
                        mainHeading={flsVoiceoverdata.banner != null ? flsVoiceoverdata.banner.heading : flsVoiceoverdata.title} />
                    <p>
                        {flsVoiceoverdata.banner != null ? flsVoiceoverdata.banner.subheading : ''}
                    </p>
                </div>
            </InnerBanner>

            {flsVoiceoverdata.overview != null ?
            <AboutOverview
                style="delivery-about-sec"
                image={flsVoiceoverdata.overview.gallery != null ? flsVoiceoverdata.overview.gallery[0].media : ''} star={Number(flsVoiceoverdata?.star)} rating={Number(flsVoiceoverdata?.rating)}>
                
                <CommonHeading mainHeading={flsVoiceoverdata.overview.heading} />

                <div className="detail-wrapper" dangerouslySetInnerHTML={{ __html: flsVoiceoverdata.overview.content}} />
            </AboutOverview>
            : ''}

            {/* {flsVoiceoverdata.what_we_offer.list != null ?
                <FlsWhatOffer
                    styleClass={addClass ? 'five-column' : 'two-column'}
                    bgColor="dark-bg"
                    commonHeading={flsVoiceoverdata.what_we_offer.heading}
                    innerText={flsVoiceoverdata.what_we_offer.subheading} >
                
                    {flsVoiceoverdata.what_we_offer.list.map((val, index) => {
                        return (
                            <FlsWhatOfferItems 
                                icon={val.thumb_image}
                                iconAlt={val.thumb_alt != null ? val.thumb_alt : val.heading}
                                title={val.heading}
                                itemKey={index+1}
                            />
                        )
                    })}
                </FlsWhatOffer>
            : ''} */}

            {flsVoiceoverdata.our_process_list != null ? 
                <FlsProcessWrapper
                    styleClass="flsLocalProcess darkGradiantBg"
                    heading={flsVoiceoverdata.our_process_section.heading}
                    subHeading={flsVoiceoverdata.our_process_section.subheading} >

                    {flsVoiceoverdata.our_process_list.map((val, index) => {
                        return(
                            <FlsProcessItems 
                                itemKey={index+1}
                                icon={val.media}
                                iconAlt={val.alt != null ? val.alt : val.heading}
                                title={val.heading}
                            />
                        )
                    })}
                </FlsProcessWrapper>
            : ''}

            {flsVoiceoverdata.why_choose_us != null ?
                <FlsChooseUs 
                    heading={flsVoiceoverdata.why_choose_us_section.heading}
                    subHeading={flsVoiceoverdata.why_choose_us_section.subheading}
                    galleryImg={flsVoiceoverdata.why_choose_us_section.gallery != null ? flsVoiceoverdata.why_choose_us_section.gallery[0].media : ''}
                    galleryImgAlt={flsVoiceoverdata.why_choose_us_section.gallery != null ? flsVoiceoverdata.why_choose_us_section.gallery[0].alt : ''} >

                    {flsVoiceoverdata.why_choose_us.map((val) => {
                        return(
                            <FlsChooseUsItems 
                                icon={val.thumb_image} 
                                iconAlt={val.thumb_alt != null ? val.thumb_alt : val.heading}
                                title={val.heading}
                            />
                        )
                    })}
                </FlsChooseUs>
            : ''}

            <FlsGetQuote />
        </>
        
    )
}
export default FlsVoiceOver