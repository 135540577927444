import $ from 'jquery'
import InnerBannerGradiant from "../../components/other/InnerBannerGradiant";
import CommonSec from "../../components/other/CommonSec";
import ScrollToTop from "../../components/other/ScrollToTop";
import { CommonHeading } from "../../components/other/Headingtext-Btn";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ApiPath from '../../components/other/ApiPath';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import InnerBanner from '../../components/other/InnerBanner';
import BreadCrumbs from '../../components/breadcrumb/BreadCrumbs';

const FlsCaseStudyDetail = () => {
    $("body").addClass("inner-page")

    const TokensCodes = Cookies.get('access_token');
    let { userId } = useParams();
    const [casestudydetaildata, setBlogDetailData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                //Blog Page Data
                const blogdetailspagedata = await fetch(`${ApiPath()}/get-case-study-by-slug/${userId}`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }

                });
                const currentblogdetailspagedata = await blogdetailspagedata.json();
                setBlogDetailData(currentblogdetailspagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!casestudydetaildata) {
        return <div className='text-center'>No data available.</div>;
    }

    return (
        <>
            <Helmet>
                <title>{casestudydetaildata.seo.meta_title != null ? casestudydetaildata.seo.meta_title : casestudydetaildata.title}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content={casestudydetaildata.seo.meta_key != null ? casestudydetaildata.seo.meta_key : casestudydetaildata.title} />
                <meta name="description" content={casestudydetaildata.seo.meta_description != null ? casestudydetaildata.seo.meta_description : casestudydetaildata.title} />
                <meta property="og:title" content={casestudydetaildata.seo.meta_title != null ? casestudydetaildata.seo.meta_title : casestudydetaildata.title} />
                <meta property="og:description" content={casestudydetaildata.seo.meta_description != null ? casestudydetaildata.seo.meta_description : casestudydetaildata.title} />
                <meta property="og:image" content={casestudydetaildata.seo != null && casestudydetaildata.seo.og_image != null ? casestudydetaildata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />
                <meta property="og:url" content={window.location.href} />

                {casestudydetaildata.seo.schema_list != null
                    ?
                    casestudydetaildata.seo.schema_list.map((val) => {
                        return (
                            <script type="application/ld+json">
                                {val.detail}
                            </script>
                        )
                    })
                    : ''}
            </Helmet>
            <ScrollToTop />


            <InnerBannerGradiant>
                <CommonHeading class="text-center" mainHeading="Our Case Studies" />
            </InnerBannerGradiant>
            <BreadCrumbs />

            <CommonSec sectionstyle={`flsCaseStudyListing`} innerspace="inner-container">
                <div className="img-wrapper">
                    <img className="w-100" src={casestudydetaildata.banner} alt="Blog" />
                </div>
                <div className="content-box mt-30">

                    <div className="caseStudyTitle">{casestudydetaildata.title}</div>
                    <div className="detail-wrapper mt-30">
                        <div dangerouslySetInnerHTML={{ __html: casestudydetaildata.content }} />

                        <div class="name-wrapper d-flex align-items-center mt-30">
                            <div class="userImg">
                                <img src={casestudydetaildata.author.image} alt={casestudydetaildata.author.alt} />
                            </div>
                            <div class="text">
                                <span class="name">{casestudydetaildata.author.name}</span><span class="date">{casestudydetaildata.published_date}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </CommonSec>
        </>
    )
}

export default FlsCaseStudyDetail;