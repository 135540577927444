import CommonSec from "../../other/CommonSec"
import { CommonHeading } from "../../other/Headingtext-Btn"
const FmcgCenterImageSection = ({sectionstyles, sectiontitle, sectionshortdescription, image, imagealt,videoimage, children}) => {
    return (
        <CommonSec sectionstyle={sectionstyles} innerspace="inner-container">
            <div className="heading-wraper text-center">
                <CommonHeading mainHeading={sectiontitle} />
                <p>
                    {sectionshortdescription}
                </p>
            </div>
            <div className='inner-content mt-60'>
                <div className="img-wrapper">
                    <img src={image} alt={imagealt}/>
                    <div class={`video-sec no-require`}>
                        <img src={videoimage} alt=""/>
                    </div>
                </div>
                <div className="items d-flex">
                    {children}
                </div>
            </div>
        </CommonSec>
    )
}
const FmcgCenterImageSectionItem = ({icon,heading,title})=>{
    return(
        <div class="item">
            <div class="icon-wrapper">
                <img src={icon} alt=""/>
            </div>   
            <div className="heading no-require">
                {heading}    
            </div>         
            {title}            
        </div>
    )
}
export default FmcgCenterImageSection
export {FmcgCenterImageSectionItem}