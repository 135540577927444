import $ from 'jquery'
const PostDetail = (props)=>{
    const popupForm = ()=>{
        $('#apply-form').addClass("show-form")
        $('body').addClass("popup-added")
    }
    return(
        <>
            <section className="postdetail-banner">
                <div className="inner-container d-flex justify-content-between align-items-center">
                    <div className="left d-flex justify-content-between align-items-center">
                        <div className="image">
                            <img src={`${process.env.PUBLIC_URL}/images/icon-job-summary.png`} alt=""/>
                        </div>
                        <div className="detail career-post-sec">                       
                            <div className="heading">{props.heading}</div>
                            <div className="location">{props.location}</div>
                        </div>
                    </div>
                    <div className="right">
                        <button type="button" className="common-btn apply-job" onClick={popupForm}>Apply Now</button>                       
                    </div>                    
                </div>
            </section>      
             
        </>
        
    )
    
}
export default PostDetail;