import { useContext } from "react";
import { CommonHeading } from "../other/Headingtext-Btn";
import WhatsNewService from "./WhatsNewItem";
import HomeContext from "../Data/Context";
import { useLocation } from "react-router-dom";
const WhatsNewSec = ()=>{
    const location = useLocation();
    const { pathname } = location;
    const WhatsNewData = useContext(HomeContext)
    const datapath = WhatsNewData.what_new_section
    return(
        <section className="whats-new-sec common-spacing-top-bottom">
            <div className="container">
                <CommonHeading class="text-center" mainHeading={datapath.heading} subHeading={datapath.subheading}/>
                <ul className="d-flex justify-content-between">
                    {datapath.whats_new.map((val)=>{
                        return(
                            <WhatsNewService 
                                key= {val.ordering}
                                image= {val.media}
                                alt= {val.alt}
                                title= {val.heading}
                                description= {val.subheading}    
                                link= {val.url}               
                            />                     
                        )
                    })}
                    
                </ul>
            </div>
        </section>
    )
}
export default WhatsNewSec;
