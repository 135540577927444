import { CommonHeading } from "../../other/Headingtext-Btn"
import { useState } from "react"
import DigiContactUsForm from "../../other/DigiContactUsForm"
import DigiRequestQuoteForm from "../../other/DigiRequestQuoteForm"


const DigiGetInTouch = () => {
    const [datatag, setdatatag] = useState("requestQuote")
    return (
        <section className="generate-quota-form common-spacing-top-bottom digi-get-in-touch" id="getintouchform">
            <div className="inner-container">
                <div className="text-center">
                    <CommonHeading mainHeading="Get In Touch" />
                    <p>
                    Please drop in your details in the form below, and our experts will call you back shortly! Thank you. 
                    </p>
                </div>

                <div className="forms-wrapper mt-60">
                    <ul className="tabs-wrapper d-flex">
                        <li onClick={()=>{setdatatag("requestQuote")}} className={`item ${datatag=="requestQuote" ? 'active' : 'non-active' }`}  data-tag="requestQuote">
                            Request A Demo
                        </li>
                        <li onClick={()=>{setdatatag("contactUsForm")}} className={`item ${datatag=="contactUsForm" ? 'active' : 'non-active' }`}  data-tag="contactUsForm">
                            Contact Us
                        </li>
                    </ul>

                    <div className="content-wrapper">
                        <div className={`inner ${datatag=="requestQuote" ? 'active' : 'non-active' }`}  data-tag="requestQuote">
                            <CommonHeading mainHeading="Request A Demo" />
                            <DigiRequestQuoteForm />
                        </div>

                        <div className={`inner ${datatag=="contactUsForm" ? 'active' : 'non-active' }`}  data-tag="requestQuote">
                            <CommonHeading mainHeading="Contact Us" />
                            <DigiContactUsForm />
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    )
}
export default DigiGetInTouch