const CountryAddress = ({children, image, title})=>{
    return(
        <li>
            <div className="image">
                <img className="w-100" src={image} alt=""/>
            </div>
            <div className="detail">
                <div className="heading">
                    {title}
                </div>
               {children}               
            </div>
        </li>
    )
}
const CityAddress = ({children, call, callNo, email})=>{
    return(
        <div className="city">
            <div className={`call${call}`}><a href={`tel:${callNo}`}>{callNo}</a></div>
            <div className="call email"><a href={`mailto:${email}`}>{email}</a></div>
            <p>
                {children}
            </p>
        </div>
    )
}
export default CountryAddress;
export {CityAddress}