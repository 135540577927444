import React, { useState, useEffect } from "react";
import axios from 'axios';
import $ from 'jquery'
import ApiPath from "../../other/ApiPath";
import FormData from 'form-data';
import Cookies from 'js-cookie';
import { useGetApi } from "../../../utils/Customhooks/ApiCalls";
$(document).ready(function () {
    $('body').bind('paste', function (event) {
        event.preventDefault();
    });
});

const SITE_KEY = process.env.REACT_APP_API_GOOGLE_RECAPTCHA_KEY;

const ApplyForm = ({ children }) => {



    const {
        data: getdata,
        isLoading: apiloading,
        error,
        doFetch,
    } = useGetApi("");


    const TokensCodes = Cookies.get('access_token');
    const [resumeerror, setResumeerror] = useState(null);

    const [applyData, setApplyData] = useState({
        name: '',
        email: '',
        phone_number: '',
        position_applied_id: '',
        years_of_experience: '',
        curreent_ctc: '',
        expected_ctc: '',
        resume: null,
        page_url: window.location.href
    });
    const [errormessage, setErrorMessage] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
            console.log("Script loaded!");
        });

        return () => {
            setErrorMessage(null)
        }
    }, []);

    const handleChange = (event) => {
        const { name, value, files } = event.target;
        setApplyData({ ...applyData, [name]: name === 'resume' ? files[0] : value });

        if (name === "resume") {


            const fileType = files[0]?.type;

            if (fileType !== 'application/msword' && fileType !== 'application/pdf' && fileType !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
              
                setResumeerror('Invalid file type. Please upload a .doc or .pdf file.')
                return;
            }

           
            const fileSize = files[0]?.size / 1024; 
            if (fileSize > 200) {
                setResumeerror('File size exceeds 200 KB. Please upload a smaller file.')
               
                return;
            }

            setResumeerror(null)


        }



        if (event.target.value.trim() === '') {
            event.target.value = ""
        }
    };

    const handleOnClick = e => {
        e.preventDefault();
        if(resumeerror !== null){
            return;
        }
        setIsLoading(true);
       

        window.grecaptcha.ready(() => {
            try {
                window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {

                    if (token) {
                        
                        handleSubmit(token);
                    }

                });

            } catch (error) {
                setIsLoading(false);
                setErrorMessage("Security Check Failed");
                doFetch("/googl-recaptcha-error")
            }

        });
    }

    const handleSubmit = async (token) => {
        const formData = new FormData();
        for (const key in applyData) {
            formData.append(key, applyData[key]);
        }

        try {
            const response = await axios.post(`${ApiPath()}/post-job-inquiry/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${TokensCodes}`
                },

            });

            // Perform additional actions on successful submission
            window.location.href = '/thank-you';
        } catch (error) {
            // Handle the error state or display an error message
            console.error('Form submission failed!', error);
        } finally {
            setIsLoading(false);
        }
    };

    const closeForm = () => {
        $('#apply-form').removeClass("show-form")
        $('body').removeClass("popup-added")
    }

    return (
        <div id="apply-form">
            <div className="inner">
                <button type="button" className="close-form" onClick={closeForm}>X</button>
                <div className="heading">Apply for Job Position</div>
                <form onSubmit={handleOnClick}>
                    <ul className="common-from">
                        <li>
                            {/* <label>Name<sup>*</sup></label> */}
                            <input type="text" onChange={handleChange} placeholder="Your Name" pattern="[A-Za-z0-9\s]+" id="name" name="name" required />
                        </li>
                        <li>
                            {/* <label>Email<sup>*</sup></label> */}
                            <input type="email" onChange={handleChange} placeholder="Your Email" id="uemail" name="email" required />
                        </li>
                        <li>
                            {/* <label>Phone Number<sup>*</sup></label> */}
                            <input type="number" onChange={handleChange} placeholder="Your Number" id="Number" name="phone_number" maxLength={15} required />
                            {/* <input type="text" pattern="[0-9]{1}[0-9]{9}" onChange={handleChange} placeholder="Your Number" id="Number" name="phone_number" /> */}
                        </li>
                        <li>
                            {/* <label>Position Applied For<sup>*</sup></label> */}
                            <select name="position_applied_id" onChange={handleChange} id="position" required>
                                <option value="">Select Position</option>
                                {children}
                            </select>
                        </li>
                        <li>
                            {/* <label>Years Of Experience<sup>*</sup></label> */}
                            <select name="years_of_experience" onChange={handleChange} id="exp" required>
                                <option value="">Select Experience</option>
                                <option value="1years">1 Years</option>
                                <option value="2years">2 Years</option>
                                <option value="3years">3 Years</option>
                                <option value="4years">4 Years</option>
                            </select>
                        </li>
                        <li className="d-flex justify-content-between">
                            <div className="half">
                                {/* <label>Curreent CTC<sup>*</sup></label> */}
                                <input className="ctc" onChange={handleChange} type="number" placeholder="Curreent CTC" id="curreentctc" name="curreent_ctc" required />
                            </div>
                            <div className="half">
                                {/* <label>Expected CTC<sup>*</sup></label> */}
                                <input className="ctc" onChange={handleChange} type="number" placeholder="Expected CTC" id="expectedctc" name="expected_ctc" required />
                            </div>
                        </li>
                        <li>
                            <label>Resume<sup>*</sup></label>
                            <input type="file" onChange={handleChange} id="resume" name="resume" required />
                        </li>
                        {
                            resumeerror && resumeerror !== null &&  <p className="errorMsg">{resumeerror}</p>
                        }
                        <li className="text-center">
                            <div className="links">
                                <button type="submit" className="common-btn" disabled={isLoading}>Submit <i className="icon">&nbsp;</i></button>
                            </div>
                            <p className="errorMsg">{errormessage}</p>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    );
};

const BrouchreDownloadForm = ({ pdfpath }) => {
    const [enqdata, setEnqData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const TokensCodes = Cookies.get('access_token');
    const [brouchredata, setbrouchredata] = useState({
        name: '',
        email: '',
        country: '',
        phone_number: '',
    });


    useEffect(() => {
        const fetchData = async () => {
            try {

                const enqpagedata = await fetch(`${ApiPath()}/get_all_counrty`, {
                    headers: {
                        'Authorization': `Bearer ${TokensCodes}`
                    }

                });
                const currentenqpagedata = await enqpagedata.json();
                setEnqData(currentenqpagedata);

                setLoading(false);
            }
            catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            console.log("LOading")
        );
    }

    if (!enqdata) {
        return <div className='text-center'>No data available.</div>;
    }
    const handleChange = (event) => {
        setbrouchredata({ ...brouchredata, [event.target.name]: event.target.value })
        if (event.target.value.trim() === '') {
            event.target.value = ""
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        axios.post(`${ApiPath()}/broucher-download`, brouchredata, {
            headers: {
                'Authorization': `Bearer ${TokensCodes}`
            }

        })
            .then((response) => {
                // console.log('Form submitted successfully!', response.data);
                // Perform additional actions on successful submission
                window.open(pdfpath, '_blank')
                window.location.href = `/digital-learning-services/resources/brochures/digilearn-thank-you`
                setIsLoading(false);

            })
            .catch((error) => {
                // console.error('Form submission failed!', error);
                // Handle the error state or display an error message
                setIsLoading(false);
            });
    };

    const closeForm = () => {
        $('#apply-form').removeClass("show-form")
        $('body').removeClass("popup-added")
    }

    return (
        <div id="apply-form">
            <div className="inner">
                <button type="button" className="close-form" onClick={closeForm}>X</button>
                <div className="heading">Download Brouchre</div>
                <form onSubmit={handleSubmit}>
                    <ul className="common-from">
                        <li>
                            {/* <label>Name<sup>*</sup></label> */}
                            <input type="text" onChange={handleChange} placeholder="Your Name" pattern="[A-Za-z0-9\s]+" id="name" name="name" required />
                        </li>
                        <li>
                            {/* <label>Email<sup>*</sup></label> */}
                            <input type="email" onChange={handleChange} placeholder="Your Email" id="uemail" name="email" required />
                        </li>
                        <li>
                            {/* <label>Phone Number<sup>*</sup></label> */}
                            <input type="number" onChange={handleChange} placeholder="Your Number" id="Number" name="phone_number" maxLength={15} required />
                            {/* <input type="text" pattern="[0-9]{1}[0-9]{9}" onChange={handleChange} placeholder="Your Number" id="Number" name="phone_number" /> */}
                        </li>
                        <li>
                            <select onChange={handleChange} name="country" required>
                                <option value="">Select Country</option>
                                {enqdata.map((val) => {
                                    return (
                                        <option value={val.id} key={val.id}>{val.name}</option>
                                    )
                                })}
                            </select>
                        </li>
                        <li className="text-center">
                            <div className="links">
                                <button type="submit" className="common-btn" disabled={isLoading}>Submit <i className="icon">&nbsp;</i></button>
                            </div>
                        </li>
                    </ul>
                </form>
            </div>
        </div>
    );
};

export default ApplyForm;
export { BrouchreDownloadForm }
