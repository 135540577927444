import $ from 'jquery'
import InnerBannerGradiant from "../../components/other/InnerBannerGradiant";
import ScrollToTop from '../../components/other/ScrollToTop';
import InvestorTabs from '../../components/investor/Tabs';
import { CommonHeading } from "../../components/other/Headingtext-Btn";
import InvestorDataMain from '../../components/Data/InvestorPageData';
import AllInvestorSec from './AllInvestorSec';
import NewsEventsSec from '../../components/investor/NewsEventsSec';
const InvestorPage = ()=>{
    $("body").addClass("inner-page")       
    return(
        <>  <InvestorDataMain>
                <ScrollToTop/> 
                <InnerBannerGradiant>
                    <CommonHeading class="text-center" mainHeading="Vinsys Investor Relations"/>
                </InnerBannerGradiant>
                <InvestorTabs/>  
                <AllInvestorSec/>
                {/* <NewsEventsSec/> */}
            </InvestorDataMain> 
        </>
    )
}
export default InvestorPage;