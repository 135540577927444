import CommonSec from "../../other/CommonSec"
import { CommonHeading } from "../../other/Headingtext-Btn"
const ContributesSection = ({ itemview, sectionheading, sectionshortdescription, children}) => {
    return (
        <CommonSec sectionstyle={`vinsysContriSection dark-bg ${itemview}`} innerspace="inner-container">
            <div className="heading-wraper text-center">
                <CommonHeading mainHeading={sectionheading} />
                <p>
                    {sectionshortdescription}
                </p>
            </div>
            <div className="items d-flex mt-60">
                {children}
            </div>
        </CommonSec>
    )
}
const ContributesSectionItem = ({image, imagealt, description}) => {
    return (
        <div className="item">
            <div className="img-wrapper">
                <img src={image} alt={imagealt} />
            </div>
            <p>
                {description}
            </p>
        </div>
    )
}
export default ContributesSection
export {ContributesSectionItem}