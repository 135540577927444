import React, {useState, useEffect} from "react";
import {ContactContext} from './Context';
import ApiPath from "../other/ApiPath";
import Cookies from 'js-cookie';
const ContactDataMain = ({children})=>{
    const TokensCodes = Cookies.get('access_token'); 
    const [contactdata, setContactData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
        try {
            //Contact Page Data
            const contactpagedata = await fetch(`${ApiPath()}/get_contact_page_details`, {
                headers: {
                    'Authorization': `Bearer ${TokensCodes}`
                }

            });              
            const currentcontactpagedata = await contactpagedata.json();            
            setContactData(currentcontactpagedata);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            }
    };
    fetchData();
    }, [TokensCodes]);

    if (loading) {
        return(
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!contactdata) {
        return <div className='text-center'>No data available.</div>;
    } 
    return(
        <ContactContext.Provider value={contactdata}>
            {children}
        </ContactContext.Provider>
    )
}
export default ContactDataMain