import React, { useState } from 'react';
import $ from 'jquery';
import { CommonHeading } from '../../components/other/Headingtext-Btn';
import BlogsList from '../../components/blogs/Blogs';
import TrendingBlogs from '../../components/blogs/TrendingBlogs';
import ScrollToTop from '../../components/other/ScrollToTop';
const BlogsPage = () => {
    $("body").addClass("inner-page")
    return (
        <>
            

            <ScrollToTop />            
            <BlogsList serviceId=""/>
            {/* <TrendingBlogs/> */}

        </>
    )
}
export default BlogsPage