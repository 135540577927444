import CommonBtn from "../other/Headingtext-Btn";
import { useContext } from "react";
import { Helmet } from 'react-helmet';
import ReactPlayer from "react-player";
import $ from 'jquery'
import HomeContext from "../Data/Context";
const HomeBanner = ()=>{
    const homeBanerData = useContext(HomeContext)    
    const homeSchema = `
{
    "@context": "http://schema.org",
"@type": "Organization",
"name": "Vinsys",
"url": "https://www.vinsys.com/training",
"sameAs": [
"https://www.facebook.com/vinsys",
"https://www.twitter.com/vinsystraining",
"https://www.youtube.com/c/VinsysOfficial",
"https://www.linkedin.com/company/vinsys",
"https://in.pinterest.com/vinsys/"
],
"logo": "https://www.vinsys.com/images/logo.png",
"legalName": "Vinsys",
"address": [
{
    "@type": "PostalAddress",
"addressCountry": "India",
"addressLocality": "Pune",
"addressRegion": "Maharashtra",
"postalCode": "411038",
"streetAddress": "Shivaji Niketan, Tejas Society, Behind Kothrud Bus Stand, Near Mantri Park, Kothrud"
},
{
    "@type": "PostalAddress",
"addressCountry": "UAE",
"addressLocality": "Dubai",
"addressRegion": "Sheikh Zayed Road",
"postalCode": "213279",
"streetAddress": "304, City Tower 2, Near Crown Plaza, Sheikh Zayed Road."
},
{
    "@type": "PostalAddress",
"addressCountry": "India",
"addressLocality": "Hyderabad",
"addressRegion": "Madhapur",
"postalCode": "500081",
"streetAddress": "1st floor, Sai Prithvi Cyber Arcade, Hitech City Rd, above Bata show room, Megha Hills, Sri Rama Colony, Madhapur"
},
{
    "@type": "PostalAddress",
"addressCountry": "KW",
"addressLocality": "Kuwait",
"addressRegion": "Capital TowerTunis Street",
"postalCode": "030006",
"streetAddress": " Capital TowerTunis Street, Fifth-floor, Office number 10, Hawally, Kuwait"
},
{
    "@type": "PostalAddress",
"addressCountry": "USA",
"addressLocality": "New York",
"addressRegion": "132 West 31st Street",
"postalCode": "10001",
"streetAddress": "132 West 31st Street, First Floor"
},
{
    "@type": "PostalAddress",
"addressCountry": "US",
"addressLocality": "WILMINGTON",
"addressRegion": "DE",
"postalCode": "19801",
"streetAddress": "1209 ORANGE STREET, WILMINGTON"
},
{
    "@type": "PostalAddress",
"addressCountry": "US",
"addressLocality": "San Jose",
"addressRegion": "CA",
"postalCode": "95117",
"streetAddress": "3163 Loma Verde Drive, Unit 10"
}
],
"contactPoint": [
{
    "@type": "ContactPoint",
"telephone": "+91-20-67444700",
"contactType": "Customer Service",
"contactOption": "TollFree",
"areaServed": "IN"
},
{
    "@type": "ContactPoint",
"telephone": "+971-4-3524949",
"contactType": "Customer Service",
"contactOption": "TollFree",
"areaServed": "UAE"
},
{
    "@type": "ContactPoint",
"telephone": "+91 040 23114305",
"contactType": "Customer Service",
"contactOption": "TollFree",
"areaServed": "IN"
},
{
    "@type": "ContactPoint",
"telephone": "+965 9744 1944",
"contactType": "Customer Service",
"contactOption": "TollFree",
"areaServed": "Kuwait"
},
{
    "@type": "ContactPoint",
"telephone": "+1-8445180061",
"contactType": "Customer Service",
"contactOption": "TollFree",
"areaServed": "USA"
}
]
}`
const homeSchema1 = `
{
    "@context": "https://schema.org",
"@type": "LocalBusiness",
"name": "Vinsys",
"image": "https://www.vinsys.com/images/logo.png",
"@id": "https://www.vinsys.com/",
"url": "https://www.vinsys.com/",
"telephone": "+91-20-67444700",
"address": {
    "@type": "PostalAddress",
"streetAddress": "Shivaji Niketan, Tejas Society, Behind Kothrud Bus Stand, Near Mantri Park, Kothrud,",
"addressLocality": "Pune",
"postalCode": "411038",
"addressCountry": "IN" 
},
"geo": {
    "@type": "GeoCoordinates",
"latitude": 18.50014081530139,
"longitude": 73.81028299458153 
},
"openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
"dayOfWeek": [
"Monday",
"Tuesday",
"Wednesday",
"Thursday",
"Friday",
"Saturday",
"Sunday"
],
"opens": "00:00",
"closes": "23:59" 
} 
}
`
const homeSchema2 = `
{"@context":"http://schema.org", 
"@type":"ItemList", 
"itemListElement": 
[ 
{"@type": "SiteNavigationElement", 
"position": 1, 
"name": "IT Services", 
"description": "Empower your business with the latest technology offered by Vinsys - DevOps, ERP, Software Services, CRM, HRMS, LMS, BPM & more.", 
"url":"https://www.vinsys.com/training" 
}, 
{"@type": "SiteNavigationElement", 
"position": 2, 
"name": "Digital Learning", 
"description": "Provide digital services, elearning platform solutions across 500+ clients.", 
"url":" https://digilearn.vinsys.com" 
}, 
{"@type": "SiteNavigationElement", 
"position": 3, 
"name": "Training", 
"description": "Vinsys professional learning platform to boost your career with the help of industry expert trainers having 20+ years of experience.", 
"url":"https://www.vinsys.com/training" 
}, 
{"@type": "SiteNavigationElement", 
"position": 4, 
"name": "Language Translation Services", 
"description": "Vinsys IT Services (I) Ltd - Foreign Language Services (FLS) is an ISO 9001:2015 certified Business Unit of the MNC Vinsys IT Services (I) Ltd. Having 25 centres across the globe Vinsys is active as a Language Service Provider (LSP) since more than a decade.", 
"url":"https://fls.vinsys.com" 
}, 
{"@type": "SiteNavigationElement", 
"position": 5, 
"name": "Resourcing & Recruitment", 
"description": "Vikvins Consultants armed with a headcount of 1000, stands to be one of the key Outsourcing Service Provider.", 
"url":"https://www.vikvins.com" 
} 
] 
} 
`
    return(
        <>      
            <Helmet>
                <title>{homeBanerData.seo!=null && homeBanerData.seo.meta_title!=null ? homeBanerData.seo.meta_title : homeBanerData.title}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content={homeBanerData.seo !=null && homeBanerData.seo.meta_key!=null ? homeBanerData.seo.meta_key:homeBanerData.title } />
                <meta name="description" content={homeBanerData.seo!=null  && homeBanerData.seo.meta_description!=null ? homeBanerData.seo.meta_description : homeBanerData.title} />
                <meta property="og:title" content={homeBanerData.seo !=null && homeBanerData.seo.meta_title != null ? homeBanerData.seo.meta_title : homeBanerData.title} />
                <meta property="og:description" content={homeBanerData.seo !=null && homeBanerData.seo.meta_description !=null ? homeBanerData.seo.meta_description : homeBanerData.title} />               
                <meta property="og:image" content={homeBanerData.seo!=null && homeBanerData.seo.og_image!=null ? homeBanerData.seo.og_image :  `${process.env.PUBLIC_URL}/images/logo.png`} /> 
                <meta property="og:url" content={window.location.href} />  
                <script type="application/ld+json">
                    {homeSchema}
                </script>   
                <script type="application/ld+json">
                    {homeSchema1}
                </script>
                <script type="application/ld+json">
                    {homeSchema2}
                </script> 
            </Helmet>
            <section className={`home-banner ${(($(window).width() > 1023) && homeBanerData.banner[0].media_type=='vedio') || (($(window).width() < 1023) && homeBanerData.mobile_banner[0].media_type=='vedio') ? 'video-banner' : 'image-banner'}`}>                
                {
                   (($(window).width() > 1023) && homeBanerData.banner[0].media_type=='vedio') || (($(window).width() < 1023) && homeBanerData.mobile_banner[0].media_type=='vedio') ?    
                   
                   <ReactPlayer
                        url={($(window).width() > 1023) ? homeBanerData.banner[0].media : homeBanerData.mobile_banner[0].media}
                        className="video-banner"
                        playing={true}        
                        playsinline={true}
                        loop={true}
                        muted={true}
                        width="100%"
                        height="auto"
                    />
                                  
                   : 
                   <img src={($(window).width() > 1023) ? homeBanerData.banner[0].media : homeBanerData.mobile_banner[0].media} alt="" className="w-100" />

                }
                
                <div className="banner-text">
                    <div className="container">
                        <div className="heading">
                            <div dangerouslySetInnerHTML={($(window).width() > 1023) ? { __html: homeBanerData.banner[0].heading } : { __html: homeBanerData.mobile_banner[0].heading }} />                        
                        </div>                       
                        <div className="description" dangerouslySetInnerHTML={($(window).width() > 1023) ? { __html: homeBanerData.banner[0].subheading } : { __html: homeBanerData.mobile_banner[0].subheading }} >     
                          
                        </div>
                        <div className="links">
                            <CommonBtn class="" link={($(window).width() > 1023) ? homeBanerData.banner[0].url : homeBanerData.mobile_banner[0].url} text={($(window).width() > 1023) ? homeBanerData.banner[0].button_text : homeBanerData.mobile_banner[0].button_text}   />
                        </div>                    
                    </div>
                </div>
            </section>
        </>
    )
}
export default HomeBanner;
