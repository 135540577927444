import "../src/assets/Main.scss";
import "../src/assets/InnerPage.scss";
import "../src/assets/trainingpage.scss";
import "../src/assets/digilearnpage.scss";
import "../src/assets/resource-recruitment.scss";
import "../src/assets/flspage.scss";
import "../src/assets/Responsive.scss";
import "../src/assets/digilearnResponsive.scss";
import "../src/assets/flsResponsive.scss";
import React from "react";
import { Routes, Route, BrowserRouter, Link, Navigate } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import Layout from '../src/assets/Layout';
import CareerPage from "./pages/career/CareerPage";
import CareerDetail from "./pages/career/CareerDetailPage";
import ContactPage from "./pages/contact/ContactPage";
import AboutPage from "./pages/about/AboutPage";
import EventsPage from "./pages/events/EventsPage";
import EventDetailPage from "./pages/events/EventDetailPage";
import BlogDetailPage from "./pages/blogs/BlogDetailPage";
import BlogsPage from "./pages/blogs/BlogsPage";
import InvestorPage from "./pages/investor/InvestorPage";
import PrivacyPolicy from "./pages/OtherPages/PrivacyPolicy";
import TermsAndCondtion from "./pages/OtherPages/TermsAndCondtion";
import ThankYouPage from "./pages/OtherPages/ThankYou";
import NotFoundPage from "./pages/OtherPages/NotFoundPage";
import TrainingHome from './pages/training/home/TrainingHome';
import CertificationTraining from './pages/training/CertificationTraining/CertificationTraining';
import DeliveryModePage from './pages/training/deliveryMode/DeliveryMode';
import ApplicationDevelopment from './pages/training/ApplicationDevelopment/ApplicationDevelopment';
import RunningClassesPage from "./pages/training/RunningClasses/RunningClasses";
import PromotionPage from './pages/training/promotion/PromotionPage';
import CourseDetailPage from "./pages/training/CourseDetail/CourseDetailPage";
import IndividualTrainingPage from "./pages/training/IndividualTraining/IndividualTrainingPage";
import GroupTrainingPage from "./pages/training/GroupTraining/GroupTrainingPage";
import GetObligationPage from "./pages/training/GetObligation/GetObligationPage";
import CorporateTrainingPage from "./pages/training/CorporateTraining/CorporateTrainingPage";
import GovernmentTrainingPage from "./pages/training/GovernmentTraining/GovernmentTrainingPage";
import WebinarsPage from "./pages/training/Webinars/WebinarsPage";
import TestimonialPage from "./pages/testimonial/TestimonialPage";
import GenerateQuotaPage from "./pages/training/GenerateQuota/GenerateQuota";
import CaseStudiesPage from "./pages/training/CaseStudies/CaseStudiesPage";
import WhitePapersPage from "./pages/training/WhitePapers/WhitePapersPage";
import DigilearnSolutionsHomePage from "./pages/digilearn/Home/DigilearnSolutions";
import GoDigitalLearningPage from "./pages/digilearn/GoDigitalLearning/GoDigitalLearningPage";
import CousreDetailPage from "./pages/digilearn/CousreDetail/CousreDetailPage";
import LearningManagementPlatformPage from "./pages/digilearn/LearningManagementPlatform/LearningManagementPlatformPage";
import CartsPage from "./pages/training/Carts/CartsPage";
import CustomContentDevelopmentPage from "./pages/digilearn/CustomContentDevelopment/CustomContentDevelopmentPage";
import CustomELearningPage from "./pages/digilearn/CustomELearning/CustomELearning";
import MicroLearningPage from "./pages/digilearn/MicroLearning/MicroLearningPage";
import PerformanceSupportPage from "./pages/digilearn/PerformanceSupport/PerformanceSupportPage";
import InteractiveVideosPage from "./pages/digilearn/InteractiveVideos/InteractiveVideosPage";
import ConsultancyPage from "./pages/digilearn/Consultancy/ConsultancyPage";
import PartnersPage from "./pages/digilearn/Partners/PartnersPage";
import ResourcesWebinarsPage from "./pages/digilearn/Resources/WebinarsPage";
import ResourcesBrochuresPage from "./pages/digilearn/Resources/ResourcesBrochures";
import ResourcesProductDemoPage from "./pages/digilearn/Resources/ResourcesProductDemoPage";
import ResourcesWebinarsDetailPage from "./pages/digilearn/Resources/WebinarsDetailPage";
import DigilearnAboutOverviewPage from "./pages/digilearn/AboutUs/DigilearnAboutOverviewPage";
import DigilearnContactPage from "./pages/digilearn/AboutUs/DigilearnContactPage";
import DigilearnLocationPage from "./pages/digilearn/AboutUs/DigilearnLocationPage";
import DigilearnIndustriesPage from "./pages/digilearn/AboutUs/DigilearnIndustriesPage";
import DigilearnAutomobilePage from "./pages/digilearn/AboutUs/DigilearnAutomobilePage";
import DigilearnPOEPage from "./pages/digilearn/AboutUs/DigilearnPOEPage";
import DigilearnPharmaceuticalsPage from "./pages/digilearn/AboutUs/DigilearnPharmaceuticalsPage";
import DigilearnBFSIPage from "./pages/digilearn/AboutUs/DigilearnBFSIPage";
import DigilearnGovernmentPage from "./pages/digilearn/AboutUs/DigilearnGovernmentPage";
import DigilearnManufacturingPage from "./pages/digilearn/AboutUs/DigilearnManufacturingPage";
import DigilearnTelecommunicationPage from "./pages/digilearn/AboutUs/DigilearnTelecommunicationPage";
import DigilearnSoftwarePage from "./pages/digilearn/AboutUs/DigilearnSoftwarePage";
import DigilearnFMCGPage from "./pages/digilearn/AboutUs/DigilearnFMCGPage";
import DigilearnAviationPage from "./pages/digilearn/AboutUs/DigilearnAviationPage";
import ResourceRecruitmentHomePage from "./pages/ResourceRecruitment/Home/ResourceRecruitmentHomePage";
import SolutionPage from "./pages/ResourceRecruitment/Solution/SolutionPage";
import PayrollOutsourcingPage from "./pages/ResourceRecruitment/Solution/PayrollOutsourcingPage";
import LogisticsServicesPage from "./pages/ResourceRecruitment/Solution/LogisticsServicesPage";
import IndustriesDetailPage from "./pages/ResourceRecruitment/Industries/IndustriesDetailPage";
import RecruitmentSourcingPage from "./pages/ResourceRecruitment/Solution/RecruitmentSourcingPage";
import ResourceRecruitmentBlogsPage from "./pages/ResourceRecruitment/ResourceRecruitmentBlogs/ResourceRecruitmentBlogsPage";
import ResourceRecruitmentBlogsDetailPage from "./pages/ResourceRecruitment/ResourceRecruitmentBlogs/ResourceRecruitmentBlogsDetailPage";
import ResourceRecruitmentCaseStudiesPage from "./pages/ResourceRecruitment/ResourceRecruitmentCaseStudies/ResourceRecruitmentCaseStudiesPage";
import ResourceRecruitmentCareerPage from "./pages/ResourceRecruitment/ResourceRecruitmentCareer/ResourceRecruitmentCareerPage";
import ResourceRecruitmentAboutPage from "./pages/ResourceRecruitment/ResourceRecruitmentAbout/ResourceRecruitmentAboutPage";
import ResourceRecruitmentContactPage from "./pages/ResourceRecruitment/ResourceRecruitmentContact/ResourceRecruitmentContactPage";
import ResourceRecruitmentCareerDetailPage from "./pages/ResourceRecruitment/ResourceRecruitmentCareer/ResourceRecruitmentCareerDetailPage";
import ResourceRecruitmentInterviewTipsPage from "./pages/ResourceRecruitment/ResourceRecruitmentCareer/ResourceRecruitmentInterviewTips";
import ResourceRecruitmentCaseStudiesDetailPage from "./pages/ResourceRecruitment/ResourceRecruitmentCaseStudies/ResourceRecruitmentCaseStudiesDetailPage";
import FlsLandingPage from "./pages/fls/Home/flsLandingPage";
import FlsInterpretation from "./pages/fls/Services/flsInterpretation";
import FlsLanguageTraining from "./pages/fls/Services/flsLanguageTraining"
import FlsTranscription from "./pages/fls/Services/flsTranscription"
import FlsVoiceOver from "./pages/fls/Services/flsVoiceOver"
import FlsTranslation from "./pages/fls/Services/flsTranslation"
import FlsSubtitling from "./pages/fls/Services/flsSubtitling"
import FlsIndustries from "./pages/fls/flsIndustriesDetail"
import FlsAbout from "./pages/fls/flsAboutUs"
import FlsContactUs from "./pages/fls/flsContact"
import FlsCaseStudy from "./pages/fls/flsCasesStudyList"
import FlsCaseStudyDetail from "./pages/fls/flsCaseStudy"
import FlsBlogsPage from "./pages/fls/flsBlogPage"
import FlsBlogsDetail from "./pages/fls/flsBlogDetail";
import ThankYouDigiLearn from "./pages/OtherPages/ThankYouDigiLearn";
import NewBreadCrum from "./components/breadcrumb/BreadCrumbs";


function App() {


  return (
    <>
      <BrowserRouter>

        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="career" element={<CareerPage />} />
            <Route path="career/:userId" element={<CareerDetail />} />
            <Route path="contactus" element={<ContactPage />} />
            <Route path="about-us" element={<AboutPage />} />
            <Route path="events" element={<EventsPage />} />
            <Route path="events/:eventsId" element={<EventDetailPage />} />
            <Route path="blog" element={<BlogsPage />} />
            <Route path="blog/:userId" element={<BlogDetailPage />} />
            <Route path="investor/board-of-director" element={<InvestorPage />} />
            <Route path="investor/:investorId" element={<InvestorPage />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-condtion" element={<TermsAndCondtion />} />
            <Route path="thank-you" element={<ThankYouPage />} />
            <Route path="digital-learning-services/digilearn-thank-you" element={<ThankYouDigiLearn thanksmessage="Contacting Us" thanksmessagedesc="Our team will contact you soon!" btntext="Go to Homepage" link="/digital-learning-services/" />} />

            {/* <Route path="training/" element={<TrainingHome />} />
            <Route path="training/certification-training" element={<CertificationTraining />} />            
            <Route path="training/application-development" element={<ApplicationDevelopment />} />
            <Route path="training/course-detail" element={<CourseDetailPage />} />
            <Route path="training/guarantee-run-class" element={<RunningClassesPage/>} />
            <Route path="training/delivery-mode" element={<DeliveryModePage />} />
            <Route path="training/promotion" element={<PromotionPage/>} />
            <Route path="training/individual-training" element={<IndividualTrainingPage/>} />
            <Route path="training/group-training" element={<GroupTrainingPage/>} />
            <Route path="training/get-obligation" element={<GetObligationPage/>} />
            <Route path="training/corporate-training" element={<CorporateTrainingPage/>} />
            <Route path="training/government-training" element={<GovernmentTrainingPage/>} />
            <Route path="training/webinars" element={<WebinarsPage/>} />
            <Route path="training/case-studies" element={<CaseStudiesPage/>} />
            <Route path="training/white-papers" element={<WhitePapersPage/>} />
            <Route path="training/testimonials" element={<TestimonialPage/>} />
            <Route path="training/generate-quote" element={<GenerateQuotaPage/>} />
            <Route path="training/cart" element={<CartsPage/>} /> */}

            <Route path="digital-learning-services/" element={<DigilearnSolutionsHomePage  />} />
            <Route path="digital-learning-services/elearning-courses" element={<GoDigitalLearningPage  />} />
            <Route path="digital-learning-services/elearning-courses/:courseId" element={<CousreDetailPage  />} />
            <Route path="digital-learning-services/elearning-platforms" element={<LearningManagementPlatformPage  />} />
            <Route path="digital-learning-services/elearning-content" element={<CustomContentDevelopmentPage  />} />
            <Route path="digital-learning-services/elearning-content/:ccdId" element={<PerformanceSupportPage />} />
            <Route path="digital-learning-services/partners" element={<PartnersPage  />} />

            {/* <Route path="digital-learning-services/custom-e-learning" element={<CustomELearningPage/>} />
            <Route path="digital-learning-services/micro-learning" element={<MicroLearningPage/>} />            
            <Route path="digital-learning-services/interactive-videos" element={<InteractiveVideosPage/>} /> */}
            <Route path="digital-learning-services/consultancy" element={<ConsultancyPage />} />
            {/*
             <Route path="digital-learning-services/webinars" element={<ResourcesWebinarsPage/>} />
            <Route path="digital-learning-services/webinars/:webinarId" element={<ResourcesWebinarsDetailPage/>} /> */}
            <Route path="digital-learning-services/resources/brochures" element={<ResourcesBrochuresPage />} />
            <Route path="digital-learning-services/resources/brochures/digilearn-thank-you" element={<ThankYouDigiLearn thanksmessage="Downloading Brochures" btntext="Go Back" link="/digital-learning-services/resources/brochures" />} />
            <Route path="digital-learning-services/resources/demos" element={<ResourcesProductDemoPage />} />
            <Route path="digital-learning-services/about-us" element={<DigilearnAboutOverviewPage />} />
            <Route path="digital-learning-services/contact-us" element={<DigilearnContactPage />} />
            <Route path="digital-learning-services/locations" element={<DigilearnLocationPage />} />
            <Route path="digital-learning-services/industries" element={<DigilearnIndustriesPage />} />
            <Route path="digital-learning-services/industries/automobile" element={<DigilearnAutomobilePage />} />
            <Route path="digital-learning-services/industries/power-oil-energy" element={<DigilearnPOEPage />} />
            <Route path="digital-learning-services/industries/pharmaceuticals" element={<DigilearnPharmaceuticalsPage />} />
            <Route path="digital-learning-services/industries/bfsi" element={<DigilearnBFSIPage />} />
            <Route path="digital-learning-services/industries/government" element={<DigilearnGovernmentPage />} />
            <Route path="digital-learning-services/industries/manufacturing" element={<DigilearnManufacturingPage />} />
            <Route path="digital-learning-services/industries/telecommunication" element={<DigilearnTelecommunicationPage />} />
            <Route path="digital-learning-services/industries/software" element={<DigilearnSoftwarePage />} />
            <Route path="digital-learning-services/industries/fmcg" element={<DigilearnFMCGPage />} />
            <Route path="digital-learning-services/industries/aviation" element={<DigilearnAviationPage />} />


            <Route path="foreign-language-services" element={<FlsLandingPage />} />
            <Route path="foreign-language-services/interpretation-services" element={<FlsInterpretation />} />
            <Route path="foreign-language-services/language-training" element={<FlsLanguageTraining />} />
            <Route path="foreign-language-services/transcription-services" element={<FlsTranscription />} />
            <Route path="foreign-language-services/voice-over-services" element={<FlsVoiceOver />} />
            <Route path="foreign-language-services/translation-services" element={<FlsTranslation />} />
            <Route path="foreign-language-services/subtitling-services" element={<FlsSubtitling />} />
            <Route path="foreign-language-services/about-us" element={<FlsAbout />} />
            <Route path="foreign-language-services/contact-us" element={<FlsContactUs />} />
            <Route path="foreign-language-services/thank-you" element={<ThankYouDigiLearn thanksmessage="Contacting Us" thanksmessagedesc="Our team will contact you soon!" btntext="Go to Homepage" link="/foreign-language-services" />} />
            <Route path="foreign-language-services/blog" element={<FlsBlogsPage />} />
            <Route path="foreign-language-services/blog/:userId" element={<FlsBlogsDetail />} />
            <Route path="foreign-language-services/industries/" element={<FlsIndustries />} />
            <Route path="foreign-language-services/case-study" element={<FlsCaseStudy />} />
            <Route path="foreign-language-services/case-study/:userId" element={<FlsCaseStudyDetail />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;