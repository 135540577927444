import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import "./breadcrumb.css";
import { AiFillChrome, AiFillHome } from "react-icons/ai";



const BreadCrumbs = () => {

    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);


    return (
        <>
            <ul className=' breadcrumbs'>
                <div className='bgground d-flex'>
                    <li>
                        <Link to="/"><AiFillHome /></Link>
                    </li>

                    {pathnames.map((value, index) => {
                        
                        const last = index === pathnames.length - 1;

                        return last ? (
                            <>
                                {
                                    pathnames.indexOf("investor") === -1 && <>
                                        <li key={value}>{value.replace(/-/g, " ")}</li>
                                    </>
                                }

                            </>

                        ) : (
                            <>
                                {
                                    pathnames.includes("investor") ? <>
                                        <li key={value}>
                                            <Link key={value} to={`/investor/board-of-director`}>Investor</Link>
                                        </li>
                                    </> : <>
                                        <li key={value}>
                                            <Link key={value} to={`/${pathnames.slice(0, index + 1).join("/")}`}>{value.replace(/-/g, " ")}</Link>

                                        </li>

                                    </>

                                }

                            </>

                        );
                    })}

                </div>

            </ul>

        </>
    )
}

export default BreadCrumbs;


