import CommonBtn,{CommonHeading } from "../other/Headingtext-Btn";
// import CommonBtn from "../other/Headingtext-Btn";
const SendResumeSec = ({image,title,subTitle,emailPhone})=>{
    return(
        <section className="send-resume-sec common-spacing-top-bottom">
            <div className="inner-container">
                <div className="inner d-flex justify-content-between align-items-center">
                    <div className="left">
                        <img src={image} alt="" />
                    </div>
                    <div className="right">
                        <CommonHeading mainHeading={title} subHeading={subTitle} />
                        <div className="mail-phone">
                            {emailPhone}
                        </div>
                        <div className="links">
                            <CommonBtn link="mailto:career@vinsys.com" text="Send Now"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SendResumeSec;