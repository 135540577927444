const ApiPath = ()=>{
    return(
        process.env.REACT_APP_API_BASE_URL
    )
}
const TokenCodeUserName = ()=>{
    return(
        process.env.REACT_APP_API_TOKEN_CODE_USER_NAME
    )
}
const TokenCodeUserPassword = ()=>{
    return(
        process.env.REACT_APP_API_TOKEN_CODE_USER_PASSWORD
    )
}
export default ApiPath
export {TokenCodeUserName, TokenCodeUserPassword}


