import $ from 'jquery'
import React, { useState, useEffect } from "react"
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../../components/other/ScrollToTop"
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from "../../../components/other/Headingtext-Btn"
import AboutOverview from "../../../components/about/AboutOverview"
import FlsWhatOffer, {FlsWhatOfferItems} from "../../../components/fls/FlsWhatOffer"
import FlsChooseUs, {FlsChooseUsItems} from "../../../components/fls/FlsChooseUs"
import FlsGetQuote from "../../../components/fls/Home/FlsGetQuote"

import axios from 'axios';
import ApiPath from '../../../components/other/ApiPath';

const FlsTranscription = () => {
    $(document).ready(function() {
        $("body").addClass("inner-page")  
    })

    const [flsTranscriptiondata, setData] = useState([]);
    const [addClass, setAddClass] = useState(false);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        // Define the API endpoint URL
        const apiUrl = `${ApiPath()}/get-transcription-page-details`; // Replace with your API URL
    
        // Use Axios to make a GET request
        axios.get(apiUrl)
        .then((response) => {
            setData(response.data);
            
            // Check if any item's index is greater than 4
            const shouldAddClass = response.data.what_we_offer_list.some((item, index) => index >= 4);
            setAddClass(shouldAddClass);
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
        })
        .finally(() => {
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!flsTranscriptiondata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return(
        <>
            <Helmet>
                <title>{flsTranscriptiondata.seo != null && flsTranscriptiondata.seo.meta_title != null ? flsTranscriptiondata.seo.meta_title : flsTranscriptiondata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={flsTranscriptiondata.seo != null && flsTranscriptiondata.seo.meta_key != null ? flsTranscriptiondata.seo.meta_key : flsTranscriptiondata.title} />

                <meta name="description" content={flsTranscriptiondata.seo != null && flsTranscriptiondata.seo.meta_description != null ? flsTranscriptiondata.seo.meta_description : flsTranscriptiondata.title} />

                <meta property="og:title" content={flsTranscriptiondata.seo != null && flsTranscriptiondata.seo.meta_title != null ? flsTranscriptiondata.seo.meta_title : flsTranscriptiondata.title} />

                <meta property="og:description" content={flsTranscriptiondata.seo != null && flsTranscriptiondata.seo.meta_description != null ? flsTranscriptiondata.seo.meta_description : flsTranscriptiondata.title} />

                <meta property="og:image" content={flsTranscriptiondata.seo != null && flsTranscriptiondata.seo.og_image != null ? flsTranscriptiondata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {flsTranscriptiondata.seo != null && flsTranscriptiondata.seo.schema_list != null ? flsTranscriptiondata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            
            <ScrollToTop />
            <InnerBanner
                style="digi-inner-banner"
                image={flsTranscriptiondata.banner != null ? flsTranscriptiondata.banner.media : `${process.env.PUBLIC_URL}/images/go-digital-learning-banner-min.jpg`}>
                
                <div className='text-center'>
                    <CommonHeading
                        mainHeading={flsTranscriptiondata.banner != null ? flsTranscriptiondata.banner.heading : flsTranscriptiondata.title} />
                    <p>
                        {flsTranscriptiondata.banner != null ? flsTranscriptiondata.banner.subheading : ''}
                    </p>
                </div>
            </InnerBanner>
            
            {flsTranscriptiondata.overview != null ?
                <AboutOverview
                    style="delivery-about-sec"
                    image={flsTranscriptiondata.overview.gallery != null ? flsTranscriptiondata.overview.gallery[0].media : ''} star={Number(flsTranscriptiondata?.star)} rating={Number(flsTranscriptiondata?.rating)}>
                    
                    <CommonHeading mainHeading={flsTranscriptiondata.overview.heading} />
                    <div className="detail-wrapper" dangerouslySetInnerHTML={{ __html: flsTranscriptiondata.overview.content}} />
                </AboutOverview>
            : ''}

            {flsTranscriptiondata.what_we_offer_list != null ?
                <FlsWhatOffer
                    styleClass={addClass ? 'five-column' : 'two-column'}
                    bgColor="dark-bg"
                    commonHeading={flsTranscriptiondata.what_we_offer_section.heading}
                    innerText={flsTranscriptiondata.what_we_offer_section.subheading} >
                    
                    {flsTranscriptiondata.what_we_offer_list.slice(0, 5).map((val, index) => {
                        return(
                            <FlsWhatOfferItems 
                                icon={val.media}
                                iconAlt={val.alt != null ? val.alt : val.heading}
                                title={val.heading}
                                itemKey={index+1}
                            />
                        )
                    })}
                </FlsWhatOffer>
            : ''}

            {flsTranscriptiondata.why_choose_us != null ?
                <FlsChooseUs
                    heading={flsTranscriptiondata.why_choose_us_section.heading}
                    subHeading={flsTranscriptiondata.why_choose_us_section.subheading}
                    galleryImg={flsTranscriptiondata.why_choose_us_section.gallery != null ? flsTranscriptiondata.why_choose_us_section.gallery[0].media : ''}
                    galleryImgAlt={flsTranscriptiondata.why_choose_us_section.gallery != null ? flsTranscriptiondata.why_choose_us_section.gallery[0].alt : ''} >

                    {flsTranscriptiondata.why_choose_us.map((val) => {
                        return(
                            <FlsChooseUsItems 
                                icon={val.thumb_image} 
                                iconAlt={val.thumb_alt != null ? val.thumb_alt : val.heading}
                                title={val.heading}
                            />
                        )
                    })}
                </FlsChooseUs>
            : ''}

            <FlsGetQuote />
        </>
    )
}

export default FlsTranscription