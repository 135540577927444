import HomeBanner from "../../components/home/Banner";
import ServiceSection from "../../components/home/ServiceSec";
import WhatsNewSec from "../../components/home/WhatsNew";
import ScrollToTop from "../../components/other/ScrollToTop";
import Partners, { Clients } from "./PartnersClients";
import $, { event } from 'jquery';
import HomeData from "../../components/Data/HomeData";
const HomePage = () => {
    $("body").removeClass("inner-page")
    document.title = "Digital Learning | IT Development | Consulting | Translation Services - Vinsys"   
    return (
        <>            
            <HomeData>
                <ScrollToTop />
                <HomeBanner />
                <ServiceSection />
                <Partners />
                <WhatsNewSec />
                <Clients />
            </HomeData>
        </>
    )
}
export default HomePage;