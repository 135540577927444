import InvestorInner from '../../components/investor/InvestorInner';
import BoardMember from '../../components/investor/BoardMember';
import { CommonBtnDownload } from '../../components/other/Headingtext-Btn';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { InvestorContext } from '../../components/Data/Context';
const AllInvestorSec = () => {
    const location = useLocation();
    const isCurrentPath = (path) => {
        return location.pathname === path;
    };
    const InvestorData = useContext(InvestorContext)
    // const updatedData = InvestorData.investor_relation.find((updatedData) => `/investor/${updatedData.slug}` === location.pathname)
    if (isCurrentPath(`/investor/${InvestorData.investor_relation[0].slug}`)) {
        return (
            /*<InvestorInner investorType="director-listing" heading={InvestorData.investor_relation[0].heading}>
                <div className="items d-flex">
                    {InvestorData.investor_relation[0].investors.map((val) => {
                        return (
                            <BoardMember profileImage={val.image} name={val.name} designation={val.designation}>
                                <a href={val.linkedin} class="link" target='_blank'><div class="icon icon-linkedin"></div></a>
                                <a href={val.twitter} class="link" target='_blank'><div class="icon icon-twitter"></div></a>
                            </BoardMember>
                        )
                    })}
                </div>
            </InvestorInner>*/
            <InvestorInner investorType="director-listing" heading={InvestorData.investor_relation[0].heading}>
                <div className='table-view'>
                    <table>
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>Name of the Director</th>
                                <th>DIN</th>
                                <th>Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {InvestorData.investor_relation[0].investors.map((val, index) => {
                                return (                                
                                    <tr>
                                        <td>{index+1}</td>
                                        <td>{val.name}</td>
                                        <td>{val.din}</td>
                                        <td>{val.designation}</td>
                                    </tr>
                                )
                            })} 
                        </tbody>
                    </table>
                </div>
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[1].slug}`)) {
        return (
            <InvestorInner investorType="director-listing" heading={InvestorData.investor_relation[1].heading}>                
                {InvestorData.investor_relation[1].investors.map((val) => {
                    if(val.board_direcotr_list!=null){
                        return(       
                            <div className='table-view'>            
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan={5} className='text-center'>{val.name}</th>
                                        </tr>
                                        <tr>
                                            <th>Sr.No</th>
                                            <th>Name of the Director</th>
                                            <th>DIN</th>
                                            <th>POSITION IN COMMITTEE</th>
                                            <th>NATURE OF DIRECTORSHIP</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {val.board_direcotr_list.map((val, index) => {
                                            return (                                
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{val.name}</td>
                                                    <td>{val.din}</td>
                                                    <td>{val.passosittion}</td>
                                                    <td>{val.nature_of_direcotrship}</td>
                                                </tr>
                                            )
                                        })} 
                                    </tbody>
                                </table>
                            </div>
                        )
                    }
                })} 
               
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[2].slug}`)) {
        return (
            <InvestorInner investorType="director-listing" heading={InvestorData.investor_relation[2].heading}>
                <div className='table-view'>
                    <table>
                        <thead>
                            <tr>
                                <th>Sr.No</th>
                                <th>Name of the Director</th>
                                {/* <th>DIN</th> */}
                                <th>Designation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {InvestorData.investor_relation[2].investors.map((val, index) => {
                                return (                                
                                    <tr>
                                        <td>{index+1}</td>
                                        <td>{val.name}</td>
                                        {/* <td>{val.din}</td> */}
                                        <td>{val.designation}</td>
                                    </tr>
                                )
                            })} 
                        </tbody>
                    </table>
                </div>
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[3].slug}`)) {
        return (
            <InvestorInner investorType="annual-return-section" heading={InvestorData.investor_relation[3].heading}>
                <div className='items d-flex'>
                    {InvestorData.investor_relation[3].investors.map((val) => {
                        return (
                            <div className="item">
                                <div className="content-box">
                                    <div class="heading">{val.name}</div>
                                </div>                               
                                <CommonBtnDownload class={`download-btn ${val.file!="" ? 'require' : 'no-require'}`} link={val.file} placing="_blank" text="Download PDF" />
                            </div>
                        )

                    })}
                </div>
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[9].slug}`)) {
        return (
            <InvestorInner investorType="subs-company-section" heading={InvestorData.investor_relation[9].heading}>
                <div className='items d-flex'>
                    {InvestorData.investor_relation[9].investors.map((val) => {
                        return (
                            <div className="item">
                                <div className="img-box">
                                    <img src={val.image} alt="company" />
                                </div>
                                <div className="content-box d-flex align-items-center">
                                    <div className="flag">
                                        <img src={`${process.env.PUBLIC_URL}/images/investor/flag.svg`} alt="flag" />
                                    </div>
                                    <div className="company">{val.name}<span>{(val.country == null ? '' : val.country)}</span></div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[5].slug}`)) {
        return (
            <InvestorInner investorType="boardMeating-section" heading={InvestorData.investor_relation[5].heading}>
                <div className='items d-flex'>
                    {InvestorData.investor_relation[5].investors.map((val) => {
                        if(val.bord_metting_list!=null){
                            return (
                                <div className="item">
                                    <div className="top-heading">
                                        {val.name}
                                    </div>
                                    <ul>
                                        {val.bord_metting_list.map((val) => {
                                            return (
                                                <li className="d-flex">
                                                    <div className="name">{val.title}</div>
                                                    <a href={val.file} className="download" target='_blank'><i className="icon-pdf"></i>Download</a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            )
                        }
                    })}
                </div>
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[10].slug}`)) {
        return (
            <InvestorInner investorType="annual-return-section" heading={InvestorData.investor_relation[10].heading}>
                <div className='items d-flex'>
                    {InvestorData.investor_relation[10].investors.map((val) => {
                        return (
                            <div className="item">
                                <div className="content-box">
                                    <div class="heading">{val.name}</div>
                                </div>
                                <CommonBtnDownload class={`download-btn ${val.file!="" ? 'require' : 'no-require'}`} link={val.file} placing="_blank" text="Download PDF" />
                            </div>
                        )
                    })}
                </div>
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[4].slug}`)) {
        return (
            <InvestorInner investorType="annual-return-section" heading={InvestorData.investor_relation[4].heading}>
                <div className='items d-flex'>
                    {InvestorData.investor_relation[4].investors.map((val) => {
                        return (
                            <div className="item">
                                <div className="content-box">
                                    <div class="heading">{val.name}</div>
                                    <p>{val.short_note}</p>
                                </div>
                                <CommonBtnDownload class={`download-btn ${val.file!="" ? 'require' : 'no-require'}`} link={val.file} placing="_blank" text="Download PDF" />
                            </div>
                        )
                    })}
                </div>
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[6].slug}`)) {
        return (
            <InvestorInner investorType="annual-return-section" heading={InvestorData.investor_relation[6].heading}>
                <div className='items d-flex'>
                    {InvestorData.investor_relation[6].investors.map((val) => {
                        return (
                            <div className="item">
                                <div className="content-box">
                                    <div class="heading">{val.name}</div>
                                    <p>{val.short_note}</p>
                                </div>
                                <CommonBtnDownload class={`download-btn ${val.file!="" ? 'require' : 'no-require'}`} link={val.file} placing="_blank" text="Download PDF" />
                            </div>
                        )
                    })}
                </div>
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[7].slug}`)) {
        return (
            <InvestorInner investorType="annual-return-section" heading={InvestorData.investor_relation[7].heading}>
                <div className='items d-flex'>
                    {InvestorData.investor_relation[7].investors.map((val) => {
                        return (
                            <div className="item">
                                <div className="content-box">
                                    <div class="heading">{val.name}</div>
                                    <p>{val.short_note}</p>
                                </div>
                                <CommonBtnDownload class={`download-btn ${val.file!="" ? 'require' : 'no-require'}`} link={val.file} placing="_blank" text="Download PDF" />
                            </div>
                        )
                    })}
                </div>
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[8].slug}`)) {
        return (
            <InvestorInner investorType="annual-return-section" heading={InvestorData.investor_relation[8].heading}>
                <div className='items d-flex'>
                    {InvestorData.investor_relation[8].investors.map((val) => {
                        return (
                            <div className="item">
                                <div className="content-box">
                                    <div class="heading">{val.name}</div>
                                    <p>{val.short_note}</p>
                                </div>
                                <CommonBtnDownload class={`download-btn ${val.file!="" ? 'require' : 'no-require'}`} link={val.file} placing="_blank" text="Download PDF" />
                            </div>
                        )

                    })}
                </div>
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[11].slug}`)) {
        return (
            <InvestorInner investorType="investor-contact-section" heading={InvestorData.investor_relation[11].heading}>
                <div className='items d-flex'>
                    {InvestorData.investor_relation[11].investors.map((val) => {
                        return (
                            <div className="item">
                                <div className="img-box">
                                    <img src={val.image} alt="Investor Contact" />
                                </div>
                                {/* <div className="content-box">
                                    <div className="name">{val.name}</div>
                                    <a href={`tel:${val.number}`} className="link"><i className="icon icon-call"></i>{val.number}</a>
                                    <a href={`mailto:${val.email}`} className="link"><i className="icon icon-mail"></i>{val.email}</a>
                                </div> */}
                            </div>
                        )

                    })}
                </div>
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[12].slug}`)) {
        return (
            <InvestorInner investorType="reports-section" heading={InvestorData.investor_relation[12].heading}>
                <div className='items d-flex'>
                    {InvestorData.investor_relation[12].investors.map((val) => {
                        return (
                            <div class="item">
                                <div class="left-section">
                                    <div class="img-box">
                                        <img src={val.image} alt="Report" />
                                    </div>
                                    <div class="name">{val.name}
                                        <span>{val.short_note}</span>
                                    </div>
                                </div>
                                <a href={val.file} class="common-btn download-btn" target='_blank'><i class="download-icon"></i></a>
                            </div>
                        )

                    })}
                </div>
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[13].slug}`)) {
        return (
            <InvestorInner investorType="reports-section" heading={InvestorData.investor_relation[13].heading}>
                <div className='items d-flex'>
                    {InvestorData.investor_relation[13].investors.map((val) => {
                        return (

                            <div class="item">
                                <div class="left-section">
                                    <div class="img-box">
                                        <img src={val.image} alt="Report" />
                                    </div>
                                    <div class="name">{val.name}
                                        <span>{val.short_note}</span>
                                    </div>
                                </div>
                                <a href={val.file} class="common-btn download-btn" target='_blank'><i class="download-icon"></i></a>
                            </div>
                        )
                    })}
                </div>
            </InvestorInner >
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[14].slug}`)) {
        return (
            <InvestorInner investorType="reports-section" heading={InvestorData.investor_relation[14].heading}>
                <div className='items d-flex'>
                    {InvestorData.investor_relation[14].investors.map((val) => {
                        return (

                            <div class="item">
                                <div class="left-section">
                                    <div class="img-box">
                                        <img src={val.image} alt="Report" />
                                    </div>
                                    <div class="name">{val.name}
                                        <span>{val.short_note}</span>
                                    </div>
                                </div>
                                <a href={val.file} class="common-btn download-btn" target='_blank'><i class="download-icon"></i></a>
                            </div>
                        )
                    })}
                </div>
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[15].slug}`)) {
        return (
            <InvestorInner investorType="reports-section" heading={InvestorData.investor_relation[15].heading}>
                <div className='items d-flex'>
                    {InvestorData.investor_relation[15].investors.map((val) => {
                        return (

                            <div class="item">
                                <div class="left-section">
                                    <div class="img-box">
                                        <img src={val.image} alt="Report" />
                                    </div>
                                    <div class="name">{val.name}
                                        <span>{val.short_note}</span>
                                    </div>
                                </div>
                                <a href={val.file} class="common-btn download-btn" target='_blank'><i class="download-icon"></i></a>
                            </div>
                        )
                    })}
                </div>
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[16].slug}`)) {
        return (
            <InvestorInner investorType="reports-section" heading={InvestorData.investor_relation[16].heading}>
                <div className='items d-flex'>
                    {InvestorData.investor_relation[16].investors.map((val) => {
                        return (

                            <div class="item">
                                <div class="left-section">
                                    <div class="img-box">
                                        <img src={val.image} alt="Report" />
                                    </div>
                                    <div class="name">{val.name}
                                        <span>{val.short_note}</span>
                                    </div>
                                </div>
                                <a href={val.file} class="common-btn download-btn" target='_blank'><i class="download-icon"></i></a>
                            </div>
                        )
                    })}
                </div>
            </InvestorInner>
        )
    } else if (isCurrentPath(`/investor/${InvestorData.investor_relation[17].slug}`)) {
        return (
            <InvestorInner investorType="reports-section" heading={InvestorData.investor_relation[17].heading}>
                <div className='items d-flex'>
                    {InvestorData.investor_relation[17].investors.map((val) => {
                        return (

                            <div class="item">
                                <div class="left-section">
                                    <div class="img-box">
                                        <img src={val.image} alt="Report" />
                                    </div>
                                    <div class="name">{val.name}
                                        <span>{val.short_note}</span>
                                    </div>
                                </div>
                                <a href={val.file} class="common-btn download-btn" target='_blank'><i class="download-icon"></i></a>
                            </div>
                        )
                    })}
                </div>
            </InvestorInner>
        )
    }



}
export default AllInvestorSec