import InnerBannerGradiant from "../../components/other/InnerBannerGradiant";
import ScrollToTop from "../../components/other/ScrollToTop";
import { CommonHeading } from "../../components/other/Headingtext-Btn";
import TrendingBlogs from "../../components/blogs/TrendingBlogs";
import BlogDetail from "../../components/blogs/BlogDetail";
import $ from 'jquery'
import BreadCrumbs from "../../components/breadcrumb/BreadCrumbs";
const BlogDetailPage = ()=>{   
    $("body").addClass("inner-page")        
    return(
        <>            
            <ScrollToTop/>
            <InnerBannerGradiant>
                <CommonHeading class="text-center" mainHeading="Our Blogs"/>
            </InnerBannerGradiant>
            <BreadCrumbs/>
            <BlogDetail/>
            {/* <TrendingBlogs/> */}            
        </>
    )
}

export default BlogDetailPage;