import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import CommonSec from '../../../components/other/CommonSec'
import CommonBtn, {CommonHeading} from '../../../components/other/Headingtext-Btn'
import ApiPath from '../../../components/other/ApiPath';
import { BrouchreDownloadForm } from '../../../components/career/CareerDetail/ApplyForm';
const ResourcesBrochuresPage = () => {
    const [pdfname, setpdfname] = useState(null);
    $("body").addClass("inner-page")
    $('.popup-brochre').on('click',function(){
        $('#apply-form').addClass("show-form");        
        $('body').addClass("popup-added")
    })
    const [resourcesbrochuresdata, setresourcesbrochuresdata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const resourcesbrochurespagedata = await fetch(`${ApiPath()}/get-broucher-page-details`);
                const currentresourcesbrochurespagedata = await resourcesbrochurespagedata.json();
                setresourcesbrochuresdata(currentresourcesbrochurespagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!resourcesbrochuresdata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{resourcesbrochuresdata.seo != null && resourcesbrochuresdata.seo.meta_title != null ? resourcesbrochuresdata.seo.meta_title : resourcesbrochuresdata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={resourcesbrochuresdata.seo != null && resourcesbrochuresdata.seo.meta_key != null ? resourcesbrochuresdata.seo.meta_key : resourcesbrochuresdata.title} />

                <meta name="description" content={resourcesbrochuresdata.seo != null && resourcesbrochuresdata.seo.meta_description != null ? resourcesbrochuresdata.seo.meta_description : resourcesbrochuresdata.title} />

                <meta property="og:title" content={resourcesbrochuresdata.seo != null && resourcesbrochuresdata.seo.meta_title != null ? resourcesbrochuresdata.seo.meta_title : resourcesbrochuresdata.title} />

                <meta property="og:description" content={resourcesbrochuresdata.seo != null && resourcesbrochuresdata.seo.meta_description != null ? resourcesbrochuresdata.seo.meta_description : resourcesbrochuresdata.title} />

                <meta property="og:image" content={resourcesbrochuresdata.seo != null && resourcesbrochuresdata.seo.og_image != null ? resourcesbrochuresdata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {resourcesbrochuresdata.seo != null && resourcesbrochuresdata.seo.schema_list != null ? resourcesbrochuresdata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            <InnerBanner style="digi-inner-banner" image={resourcesbrochuresdata.banner != null ? resourcesbrochuresdata.banner.media : `${process.env.PUBLIC_URL}/images/go-digital-learning-banner-min.jpg`}>
                <div className='text-center'>
                    <CommonHeading mainHeading={resourcesbrochuresdata.banner != null ? resourcesbrochuresdata.banner.heading : resourcesbrochuresdata.title} />
                    <p>
                        {resourcesbrochuresdata.banner != null ? resourcesbrochuresdata.banner.subheading : ''}
                    </p>
                </div>
            </InnerBanner>
            <CommonSec innerspace="inner-container">
                <div dangerouslySetInnerHTML={{ __html: resourcesbrochuresdata.first_section.content }} />
            </CommonSec>
            <CommonSec sectionstyle="brouherSection dark-bg" innerspace="inner-container">
                <div className='items d-flex'>
                    {resourcesbrochuresdata.broucher.map((val,index)=>{
                        return(                       
                            <div className="item" key={index}>
                                <div className="top-wrapper">
                                    <div className="img-wrapper">
                                        <img src={val.thumb_image} alt={val.alt!=null ? val.alt : val.heading}/>
                                    </div>
                                    <div className="sm-heading">
                                       {val.heading}
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: val.content }} />
                                </div>
                                <div className="btn-wrapper d-flex">
                                    {/* <a className='common-btn' href={val.media}  download target='_blank'>Download Now</a>*/}
                                    <button type="button" className="common-btn popup-brochre" onClick={()=>{setpdfname(val.media)}}>Download Now</button> 
                                    <BrouchreDownloadForm pdfpath={pdfname}/>
                                </div>
                            </div>
                        )
                        
                    })}
                    
                </div>
            </CommonSec>
            

        </>

    )
}
export default ResourcesBrochuresPage