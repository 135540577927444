import $ from 'jquery'
const HeaderFun= ()=>{

$(document).ready(function() {
    // This will fire when document is ready:
    $(window).resize(function() {
        // This will fire each time the window is resized:
        if($(window).width() >= 1024) {
            $(".menuLink").on('click', function () {
                $(this).parents('.items').find('a').removeClass("active");
                $(this).parents('.dropdown-menu').find('.menuContent').removeClass("active");
                var activeTab = $(this).attr("data-href");
                $(this).addClass("active");
                $('#' + activeTab).addClass("active");
            });

        } else {
            // if smaller
            $('.toggle-icon').on('click', function() {
                $('.nav-sec').addClass('active')
            });

            $('.close-icon').on('click', function() {
                $('.nav-sec').removeClass('active')
            });
            $('span.arrow-btn').on('click', function () {
                $(this).closest('.dropdown-menus').find('.dropdown.non-active').slideDown()
                $(this).closest('.dropdown-menus').find('.dropdown.active').slideUp()
            });
            $('.dropdown').children('a').on('click', function() {
                if ($(this).parent('.dropdown').hasClass("active")) {
                    $(this).parent('.dropdown').removeClass("active").find(".dropdown-menu").removeClass("active");
                } else {
                    $(".dropdown .dropdown-menu").removeClass("active");
                    $(this).parent('.dropdown').addClass("active").find(".dropdown-menu").addClass("active");
                }
                return false;
            });

            $(".menuLink").removeClass('active');
            $(".menuContent").removeClass('active');
            $(".menuLink").on('click', function() {
                var activeTab = $(this).attr("data-href");
                $(this).closest('.items').addClass("hide");
                $('#' + activeTab).addClass("active");
            });

            $('.backButton').on('click', function() {
                $(this).closest('.dropdown').find('.menuContent').removeClass('active');
                $(this).closest('.dropdown').find('.left-section .items').removeClass('hide');
            })
        }
    }).resize(); // This will simulate a resize to trigger the initial run.
});
}
export default HeaderFun;