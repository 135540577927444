import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from '../../../components/other/Headingtext-Btn'
import AboutOverview from '../../../components/about/AboutOverview'
import FmcgCenterImageSection, { FmcgCenterImageSectionItem } from '../../../components/digilearn/about/FmcgCenterImageSection'
import VinsysServices, { VinsysServicesItem } from '../../../components/digilearn/about/VinsysServices'
import WhyVinsys from '../../../components/digilearn/LearningManagementPlatform/WhyVinsys'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import ApiPath from '../../../components/other/ApiPath';
const DigilearnFMCGPage = () => {
    $("body").addClass("inner-page")
    const [fmcgpagedata, setfmcgpagedata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const fmcgpagepagedata = await fetch(`${ApiPath()}/get-fmcg-details`);
                const currentfmcgpagepagedata = await fmcgpagepagedata.json();
                setfmcgpagedata(currentfmcgpagepagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!fmcgpagedata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{fmcgpagedata.seo != null && fmcgpagedata.seo.meta_title != null ? fmcgpagedata.seo.meta_title : fmcgpagedata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={fmcgpagedata.seo != null && fmcgpagedata.seo.meta_key != null ? fmcgpagedata.seo.meta_key : fmcgpagedata.title} />

                <meta name="description" content={fmcgpagedata.seo != null && fmcgpagedata.seo.meta_description != null ? fmcgpagedata.seo.meta_description : fmcgpagedata.title} />

                <meta property="og:title" content={fmcgpagedata.seo != null && fmcgpagedata.seo.meta_title != null ? fmcgpagedata.seo.meta_title : fmcgpagedata.title} />

                <meta property="og:description" content={fmcgpagedata.seo != null && fmcgpagedata.seo.meta_description != null ? fmcgpagedata.seo.meta_description : fmcgpagedata.title} />

                <meta property="og:image" content={fmcgpagedata.seo != null && fmcgpagedata.seo.og_image != null ? fmcgpagedata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {fmcgpagedata.seo != null && fmcgpagedata.seo.schema_list != null ? fmcgpagedata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            <InnerBanner style="digi-inner-banner" image={fmcgpagedata.banner != null ? fmcgpagedata.banner.media : `${process.env.PUBLIC_URL}/images/go-digital-learning-banner-min.jpg`}>
                <div className='text-center'>
                    <CommonHeading mainHeading={fmcgpagedata.banner != null ? fmcgpagedata.banner.heading : fmcgpagedata.title} />
                    <p>
                        {fmcgpagedata.banner != null ? fmcgpagedata.banner.subheading : ''}
                    </p>
                </div>
            </InnerBanner>
            <AboutOverview style="delivery-about-sec" image={fmcgpagedata.introduction.gallery[0].media} star={Number(fmcgpagedata?.star)} rating={Number(fmcgpagedata?.rating)}>
                <CommonHeading mainHeading="Introduction" />
                <div dangerouslySetInnerHTML={{ __html: fmcgpagedata.introduction.content }} />
            </AboutOverview>
            <FmcgCenterImageSection sectionstyles="fmcgSection dark-bg"
                sectiontitle={fmcgpagedata.how_vinsys_contributes_section.heading} sectionshortdescription={fmcgpagedata.how_vinsys_contributes_section.subheading}
                image={`${process.env.PUBLIC_URL}/images/digitalLearning/basket.png`} imagealt="">
                {fmcgpagedata.how_vinsys_contributes_list.map((val,index) => {
                    return (
                        <FmcgCenterImageSectionItem key={index}
                            icon={val.thumb_image}
                            title={val.heading}
                        />
                    )
                })}
            </FmcgCenterImageSection>
            <VinsysServices sectionheading={fmcgpagedata.vinsys_services_section.heading} sectiondescription={fmcgpagedata.vinsys_services_section.subheading}>
                {fmcgpagedata.vinsys_services_list.map((val,index)=>{
                    return(
                        <VinsysServicesItem key={index} icon={val.thumb_image} image={val.media} imagealt={val.thumb_alt!=null ? val.thumb_alt : val.heading} name={val.heading} />   
                    )
                })}                             
            </VinsysServices>
            <WhyVinsys subheading={fmcgpagedata.why_vinsys_section.subheading} image={fmcgpagedata.why_vinsys_section.gallery[0].media} imagealt={fmcgpagedata.why_vinsys_section.gallery[0].alt != null ? fmcgpagedata.why_vinsys_section.gallery[0].alt : fmcgpagedata.why_vinsys_section.gallery[0].heading}>
                <ul className='items d-flex mt-60'>
                    {fmcgpagedata.why_vinsys.map((val, index) => {
                        return (
                            <li key={index}>
                                <img src={val.thumb_image} alt={val.heading} />
                                {val.heading}
                            </li>
                        )
                    })}
                </ul>
            </WhyVinsys>
            <DigiGetInTouch />
        </>
    )
}
export default DigilearnFMCGPage