import CommonSec from "../../other/CommonSec"
import { CommonHeading } from "../../other/Headingtext-Btn"

const VinsysCaterSection = ({image, imagealt, title, children})=>{
    return(
        <CommonSec sectionstyle="automobileLearning" innerspace="inner-container">
            <div className="inner-content">
                <img src={image} alt={imagealt}/>
                <div className="content-box">
                    <CommonHeading mainHeading={title}/>
                    <div className="description">
                        {children}
                    </div>
                </div>
            </div>            
        </CommonSec>
    )
}
export default VinsysCaterSection