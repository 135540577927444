import PartnerData, {ClientsData} from "../../components/other/Data";
import PartnerSec from "../../components/home/Partner";
import { useContext } from "react";
import HomeContext from "../../components/Data/Context";
const Partners = ({style})=>{
    const PartnersData = useContext(HomeContext)
    const datapath = PartnersData.our_partner_section
    return(
        <PartnerSec style={style} heading={datapath.heading} subHeading={datapath.subheading}>                            
            {datapath.partners.map((val)=>{
                return(
                    <div className="item" key={val.id}>
                        <img src={val.media} alt={val.alt} />
                    </div>   
                )                           
            })}   
        </PartnerSec>
    )
}
const PartnersOtherModule = ({style,heading,subheading,children})=>{    
    return(
        <PartnerSec style={style} heading={heading} subHeading={subheading}>                            
           {children}
        </PartnerSec>
    )
}
const Clients = ({style})=>{
    const ClientsDatas = useContext(HomeContext)
    const datapath = ClientsDatas.our_clients_section
    return(
            <PartnerSec style={`clients ${style}`} heading={datapath.heading} subHeading={datapath.subheading}>                            
                {datapath.clients.map((val)=>{
                    return(
                        <div className="item" key={val.id}>
                            <img src={val.media} alt={val.alt} />
                        </div>   
                    )                           
                })}   
            </PartnerSec>
    )
}
export default Partners
export {Clients,PartnersOtherModule}
