import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from '../../../components/other/Headingtext-Btn'
import AboutOverview from '../../../components/about/AboutOverview'
import FmcgCenterImageSection, { FmcgCenterImageSectionItem } from '../../../components/digilearn/about/FmcgCenterImageSection'
import VinsysServices, { VinsysServicesItem } from '../../../components/digilearn/about/VinsysServices'
import WhyVinsys from '../../../components/digilearn/LearningManagementPlatform/WhyVinsys'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import ApiPath from '../../../components/other/ApiPath';
const DigilearnAviationPage = () => {
    $("body").addClass("inner-page")
    const [aviationpagedata, setaviationpagedata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const aviationpagepagedata = await fetch(`${ApiPath()}/get-aviation-details`);
                const currentaviationpagepagedata = await aviationpagepagedata.json();
                setaviationpagedata(currentaviationpagepagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!aviationpagedata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{aviationpagedata.seo != null && aviationpagedata.seo.meta_title != null ? aviationpagedata.seo.meta_title : aviationpagedata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={aviationpagedata.seo != null && aviationpagedata.seo.meta_key != null ? aviationpagedata.seo.meta_key : aviationpagedata.title} />

                <meta name="description" content={aviationpagedata.seo != null && aviationpagedata.seo.meta_description != null ? aviationpagedata.seo.meta_description : aviationpagedata.title} />

                <meta property="og:title" content={aviationpagedata.seo != null && aviationpagedata.seo.meta_title != null ? aviationpagedata.seo.meta_title : aviationpagedata.title} />

                <meta property="og:description" content={aviationpagedata.seo != null && aviationpagedata.seo.meta_description != null ? aviationpagedata.seo.meta_description : aviationpagedata.title} />

                <meta property="og:image" content={aviationpagedata.seo != null && aviationpagedata.seo.og_image != null ? aviationpagedata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {aviationpagedata.seo != null && aviationpagedata.seo.schema_list != null ? aviationpagedata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            <InnerBanner style="digi-inner-banner" image={aviationpagedata.banner != null ? aviationpagedata.banner.media : `${process.env.PUBLIC_URL}/images/go-digital-learning-banner-min.jpg`}>
                <div className='text-center'>
                    <CommonHeading mainHeading={aviationpagedata.banner != null ? aviationpagedata.banner.heading : aviationpagedata.title} />
                    <p>
                        {aviationpagedata.banner != null ? aviationpagedata.banner.subheading : ''}
                    </p>
                </div>
            </InnerBanner>
            <AboutOverview style="delivery-about-sec" image={aviationpagedata.introduction.gallery[0].media} star={Number(aviationpagedata?.star)} rating={Number(aviationpagedata?.rating)} >
                <CommonHeading mainHeading={aviationpagedata.introduction.heading} />
                <div dangerouslySetInnerHTML={{ __html: aviationpagedata.introduction.content }} />
            </AboutOverview>
            <FmcgCenterImageSection sectionstyles="aviationSection dark-bg"
                sectiontitle={aviationpagedata.how_vinsys_contributes_section.heading} sectionshortdescription={aviationpagedata.how_vinsys_contributes_section.subheading}
                image={`${process.env.PUBLIC_URL}/images/digitalLearning/aviation.png`} imagealt={aviationpagedata.how_vinsys_contributes_section.heading}>
                {aviationpagedata.how_vinsys_contributes_list.map((val,index) => {
                    return (
                        <FmcgCenterImageSectionItem key={index}
                            icon={val.thumb_image}
                            title={val.heading}
                        />
                    )
                })}
            </FmcgCenterImageSection>
            <VinsysServices sectionheading={aviationpagedata.vinsys_services_section.heading} sectiondescription={aviationpagedata.vinsys_services_section.subheading}>
                {aviationpagedata.vinsys_services_list.map((val,index)=>{
                    return(
                        <VinsysServicesItem key={index} icon={val.thumb_image} image={val.media} imagealt={val.thumb_alt!=null ? val.thumb_alt : val.heading} name={val.heading} />   
                    )
                })}                             
            </VinsysServices>
            <WhyVinsys subheading={aviationpagedata.why_vinsys_section.subheading} image={aviationpagedata.why_vinsys_section.gallery[0].media} imagealt={aviationpagedata.why_vinsys_section.gallery[0].alt != null ? aviationpagedata.why_vinsys_section.gallery[0].alt : aviationpagedata.why_vinsys_section.gallery[0].heading}>
                <ul className='items d-flex mt-60'>
                    {(aviationpagedata.why_vinsys!=null) 
                        ?
                        aviationpagedata.why_vinsys.map((val, index) => {
                            return (
                                <li key={index}>
                                    <img src={val.thumb_image} alt={val.heading} />
                                    {val.heading}
                                </li>
                            )
                        })
                        : ""                        
                    }
                </ul>
            </WhyVinsys>
            <DigiGetInTouch />
        </>
    )
}
export default DigilearnAviationPage