import $ from 'jquery'
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../../components/other/ScrollToTop'
import InnerBanner from '../../../components/other/InnerBanner'
import { CommonHeading } from '../../../components/other/Headingtext-Btn'
import CommonSec from '../../../components/other/CommonSec'
import DigiGetInTouch from '../../../components/digilearn/Home/DigiGetInTouch'
import MapSection from '../../../components/other/MapSection'
import ErrorSection from '../../../components/other/ErrorSection';
import ApiPath from '../../../components/other/ApiPath';

const DigilearnContactPage = () => {
    $("body").addClass("inner-page")
    const [contactpagedata, setcontactpagedata] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const contactpagepagedata = await fetch(`${ApiPath()}/get-contact-details`);
                const currentcontactpagepagedata = await contactpagepagedata.json();
                setcontactpagedata(currentcontactpagepagedata);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                console.log("not load")

            }
        };
        fetchData();
    }, []);


    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!contactpagedata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{contactpagedata.seo != null && contactpagedata.seo.meta_title != null ? contactpagedata.seo.meta_title : contactpagedata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={contactpagedata.seo != null && contactpagedata.seo.meta_key != null ? contactpagedata.seo.meta_key : contactpagedata.title} />

                <meta name="description" content={contactpagedata.seo != null && contactpagedata.seo.meta_description != null ? contactpagedata.seo.meta_description : contactpagedata.title} />

                <meta property="og:title" content={contactpagedata.seo != null && contactpagedata.seo.meta_title != null ? contactpagedata.seo.meta_title : contactpagedata.title} />

                <meta property="og:description" content={contactpagedata.seo != null && contactpagedata.seo.meta_description != null ? contactpagedata.seo.meta_description : contactpagedata.title} />

                <meta property="og:image" content={contactpagedata.seo != null && contactpagedata.seo.og_image != null ? contactpagedata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {contactpagedata.seo != null && contactpagedata.seo.schema_list != null ? contactpagedata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>
            <ScrollToTop />
            {contactpagedata.banner != null
                ?
                <InnerBanner style="digi-inner-banner" image={contactpagedata.banner.media}>
                    <div className='text-center'>
                        <CommonHeading mainHeading={contactpagedata.banner.heading} />
                        <p>
                            {contactpagedata.banner.subheading}
                        </p>
                    </div>
                </InnerBanner>
                :
                <ErrorSection errorheading="Add Banner" />
            }
            {contactpagedata.contact_list != null && contactpagedata.contact_list.gallery != null
                ?
                <CommonSec sectionstyle="contactUs" innerspace="inner-container">
                    <div className='items d-flex'>
                        <div className="item">
                            <div className="icon-wrapper">
                                <img src={contactpagedata.contact_list.gallery[0].media} alt={contactpagedata.contact_list.gallery[0].alt != null ? contactpagedata.contact_list.gallery[0].alt : contactpagedata.contact_list.gallery[0].heading} />
                            </div>
                            <div className="small-heading">{contactpagedata.contact_list.gallery[0].heading}</div>
                            <a href={`tel:${contactpagedata.contact_list.gallery[0].subheading}`}>{contactpagedata.contact_list.gallery[0].subheading}</a>
                        </div>
                        <div className="item">
                            <div className="icon-wrapper">
                                <img src={contactpagedata.contact_list.gallery[1].media} alt={contactpagedata.contact_list.gallery[1].alt != null ? contactpagedata.contact_list.gallery[1].alt : contactpagedata.contact_list.gallery[1].heading} />
                            </div>
                            <div className="small-heading">{contactpagedata.contact_list.gallery[1].heading}</div>
                            <a href={`mailto:${contactpagedata.contact_list.gallery[1].subheading}`}>{contactpagedata.contact_list.gallery[1].subheading}</a>
                        </div>
                        <div className="item">
                            <div className="icon-wrapper">
                                <img src={contactpagedata.contact_list.gallery[2].media} alt={contactpagedata.contact_list.gallery[2].alt != null ? contactpagedata.contact_list.gallery[2].alt : contactpagedata.contact_list.gallery[2].heading} />
                            </div>
                            <div className="small-heading">{contactpagedata.contact_list.gallery[2].heading}</div>
                            <p>
                                {contactpagedata.contact_list.gallery[2].subheading}
                            </p>
                        </div>
                    </div>
                </CommonSec>
                :
                ""
            }
            <DigiGetInTouch />

            {/* {
                contactpagedata?.contact_list.intro ?? 
            } */}

            <MapSection mapurl={contactpagedata.contact_list.intro} />
        </>
    )
}
export default DigilearnContactPage