import { CommonHeading } from "../other/Headingtext-Btn"
const InvestorInner = ({investorType, innerclass, heading, children}) => {
    return (
        <>              
            <section className={`investor-relation-section mb-50 ${innerclass}`}>
                <div className="inner-container">
                    <div className={`tab-content ${investorType}`}>
                        <CommonHeading class="text-center grey-color text-center" mainHeading={heading}/>                        
                        {children} 
                    </div>
                </div>
            </section>
        </>
    )
}
export default InvestorInner