import CommonSec from "../../other/CommonSec";
import { CommonHeading } from "../../other/Headingtext-Btn";
import GetQuoteForm from "../../other/GetQuoteForm"

const FlsGetQuote = ({flsServicelist}) => {
    return (
        <CommonSec sectionstyle="flsGetQuote" innerspace="inner-container">
            <div className="inner-content d-flex">
                <div className="content-wrapper">
                    <CommonHeading mainHeading="Get A Quote" />
                    <div class="img-wrapper">
                        <img src={`${process.env.PUBLIC_URL}/images/fls/getQuote.png`} alt="Name Icon" />
                    </div>
                </div>
                <div className="form-wrapper">
                    <GetQuoteForm style="contact-form" FormStyle="common-from" />
                </div>
            </div>
        </CommonSec>
    )
}

export default FlsGetQuote