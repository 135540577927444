import $ from 'jquery'
import React, { useState, useEffect } from "react"
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../../components/other/ScrollToTop"
import InnerBanner from '../../../components/other/InnerBanner'
import CommonBtn, { CommonHeading } from "../../../components/other/Headingtext-Btn"
import AboutOverview from "../../../components/about/AboutOverview"
import FlsWhatOffer, { FlsWhatOfferItems } from "../../../components/fls/FlsWhatOffer"
import WhyVinsys from '../../../components/digilearn/LearningManagementPlatform/WhyVinsys'
import FlsGetQuote from "../../../components/fls/Home/FlsGetQuote"

import axios from 'axios';
import ApiPath from '../../../components/other/ApiPath';

const FlsInterpretation = () => {

    $(document).ready(function () {
        $("body").addClass("inner-page")
    })

    const [flsInterpretationdata, setData] = useState([]);
    const [addClass, setAddClass] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Define the API endpoint URL
        const apiUrl = `${ApiPath()}/get-interpretation-page-details`; // Replace with your API URL

        // Use Axios to make a GET request
        axios.get(apiUrl)
            .then((response) => {
                setData(response.data);

                // Check if any item's index is greater than 4
                const shouldAddClass = response.data.what_we_offer_list.some((item, index) => index >= 4);
                setAddClass(shouldAddClass);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!flsInterpretationdata) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }
    return (
        <>
            <Helmet>
                <title>{flsInterpretationdata.seo != null && flsInterpretationdata.seo.meta_title != null ? flsInterpretationdata.seo.meta_title : flsInterpretationdata.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={flsInterpretationdata.seo != null && flsInterpretationdata.seo.meta_key != null ? flsInterpretationdata.seo.meta_key : flsInterpretationdata.title} />

                <meta name="description" content={flsInterpretationdata.seo != null && flsInterpretationdata.seo.meta_description != null ? flsInterpretationdata.seo.meta_description : flsInterpretationdata.title} />

                <meta property="og:title" content={flsInterpretationdata.seo != null && flsInterpretationdata.seo.meta_title != null ? flsInterpretationdata.seo.meta_title : flsInterpretationdata.title} />

                <meta property="og:description" content={flsInterpretationdata.seo != null && flsInterpretationdata.seo.meta_description != null ? flsInterpretationdata.seo.meta_description : flsInterpretationdata.title} />

                <meta property="og:image" content={flsInterpretationdata.seo != null && flsInterpretationdata.seo.og_image != null ? flsInterpretationdata.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {flsInterpretationdata.seo != null && flsInterpretationdata.seo.schema_list != null ? flsInterpretationdata.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>

            <ScrollToTop />
            <InnerBanner
                style="digi-inner-banner"
                image={flsInterpretationdata.banner != null ? flsInterpretationdata.banner.media : `${process.env.PUBLIC_URL}/images/go-digital-learning-banner-min.jpg`}>

                <div className='text-center'>
                    <CommonHeading
                        mainHeading={flsInterpretationdata.banner != null ? flsInterpretationdata.banner.heading : flsInterpretationdata.title} />
                    <p>
                        {flsInterpretationdata.banner != null ? flsInterpretationdata.banner.subheading : ''}
                    </p>
                </div>
            </InnerBanner>

            {flsInterpretationdata.overview != null ?
                <AboutOverview
                    style="delivery-about-sec"
                    image={flsInterpretationdata.overview.gallery.media}

                    star={Number(flsInterpretationdata?.star)} rating={Number(flsInterpretationdata?.rating)}
                    >

                    <CommonHeading mainHeading={flsInterpretationdata.overview.heading} />
                    <div className="detail-wrapper" dangerouslySetInnerHTML={{ __html: flsInterpretationdata.overview.content }} />

                    <div className={`btn-wrapper d-flex mt-30 ${flsInterpretationdata.overview.link === null ? 'no-require' : ''}`}>


                        <CommonBtn to={flsInterpretationdata.overview.link} text={flsInterpretationdata.overview.button_text}></CommonBtn>
                    </div>
                </AboutOverview>
                : ''}

            {flsInterpretationdata.what_we_offer_list != null ?
                <FlsWhatOffer
                    styleClass={addClass ? 'five-column' : 'two-column'}
                    bgColor="dark-bg"
                    commonHeading={flsInterpretationdata.what_we_offer_section.heading}
                    innerText={flsInterpretationdata.what_we_offer_section.subheading} >

                    {flsInterpretationdata.what_we_offer_list.slice(0, 5).map((val, index) => {
                        return (
                            <FlsWhatOfferItems
                                icon={val.media}
                                iconAlt={val.alt != null ? val.alt : val.heading}
                                title={val.heading}
                                itemKey={index + 1}
                            />
                        )
                    })}
                </FlsWhatOffer>
                : ''}

            {flsInterpretationdata.why_choose_us != null ?
                <WhyVinsys
                    sectionstyle="gradiant-bg"
                    subheading={flsInterpretationdata.why_choose_us_section.subheading}
                    image={flsInterpretationdata.why_choose_us_section.gallery[0].media}
                    imagealt={flsInterpretationdata.why_choose_us_section.gallery[0].alt}
                >
                    <ul className='items d-flex mt-30'>
                        {flsInterpretationdata.why_choose_us.map((val) => {
                            return (
                                <li>
                                    <img src={val.thumb_image} alt={val.thumb_alt} />
                                    {val.heading}
                                </li>
                            )
                        })}
                    </ul>
                </WhyVinsys>
                : ''}

            <FlsGetQuote />
        </>
    )
}
export default FlsInterpretation