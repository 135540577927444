import CommonSec from "../../other/CommonSec"
import { CommonHeading } from "../../other/Headingtext-Btn"

const SuiteOfLearningPlatform = ({ sectionstyle, sectionTitle, sectionSubTitle, topspace, children }) => {
    return (

        <CommonSec sectionstyle={`digilearning-suit-learning-platform ${sectionstyle}`} innerspace="inner-container">
            <div className="text-center">
                <CommonHeading mainHeading={sectionTitle} />
                <p>{sectionSubTitle}</p>
            </div>
            <div className={`platforms ${topspace}`}>
                {children}
            </div>
        </CommonSec>

    )
}
export default SuiteOfLearningPlatform