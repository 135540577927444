import EnquiryForm from "../other/EnquiryForm"
import { CommonHeading } from "../other/Headingtext-Btn"
import { useContext } from "react"
import { Helmet } from 'react-helmet';
import { ContactContext } from "../Data/Context";

const ContactTopSec = () => {
    const contactData = useContext(ContactContext)
    const homeSchema = `
{
    "@context": "http://schema.org",
"@type": "Organization",
"name": "Vinsys",
"url": "https://www.vinsys.com/training",
"sameAs": [
"https://www.facebook.com/vinsys",
"https://www.twitter.com/vinsystraining",
"https://www.youtube.com/c/VinsysOfficial",
"https://www.linkedin.com/company/vinsys",
"https://in.pinterest.com/vinsys/"
],
"logo": "https://www.vinsys.com/images/logo.png",
"legalName": "Vinsys",
"address": [
{
    "@type": "PostalAddress",
"addressCountry": "India",
"addressLocality": "Pune",
"addressRegion": "Maharashtra",
"postalCode": "411038",
"streetAddress": "Shivaji Niketan, Tejas Society, Behind Kothrud Bus Stand, Near Mantri Park, Kothrud"
},
{
    "@type": "PostalAddress",
"addressCountry": "UAE",
"addressLocality": "Dubai",
"addressRegion": "Sheikh Zayed Road",
"postalCode": "213279",
"streetAddress": "304, City Tower 2, Near Crown Plaza, Sheikh Zayed Road."
},
{
    "@type": "PostalAddress",
"addressCountry": "India",
"addressLocality": "Hyderabad",
"addressRegion": "Madhapur",
"postalCode": "500081",
"streetAddress": "1st floor, Sai Prithvi Cyber Arcade, Hitech City Rd, above Bata show room, Megha Hills, Sri Rama Colony, Madhapur"
},
{
    "@type": "PostalAddress",
"addressCountry": "KW",
"addressLocality": "Kuwait",
"addressRegion": "Capital TowerTunis Street",
"postalCode": "030006",
"streetAddress": " Capital TowerTunis Street, Fifth-floor, Office number 10, Hawally, Kuwait"
},
{
    "@type": "PostalAddress",
"addressCountry": "USA",
"addressLocality": "New York",
"addressRegion": "132 West 31st Street",
"postalCode": "10001",
"streetAddress": "132 West 31st Street, First Floor"
},
{
    "@type": "PostalAddress",
"addressCountry": "US",
"addressLocality": "WILMINGTON",
"addressRegion": "DE",
"postalCode": "19801",
"streetAddress": "1209 ORANGE STREET, WILMINGTON"
},
{
    "@type": "PostalAddress",
"addressCountry": "US",
"addressLocality": "San Jose",
"addressRegion": "CA",
"postalCode": "95117",
"streetAddress": "3163 Loma Verde Drive, Unit 10"
}
],
"contactPoint": [
{
    "@type": "ContactPoint",
"telephone": "+91-20-67444700",
"contactType": "Customer Service",
"contactOption": "TollFree",
"areaServed": "IN"
},
{
    "@type": "ContactPoint",
"telephone": "+971-4-3524949",
"contactType": "Customer Service",
"contactOption": "TollFree",
"areaServed": "UAE"
},
{
    "@type": "ContactPoint",
"telephone": "+91 040 23114305",
"contactType": "Customer Service",
"contactOption": "TollFree",
"areaServed": "IN"
},
{
    "@type": "ContactPoint",
"telephone": "+965 9744 1944",
"contactType": "Customer Service",
"contactOption": "TollFree",
"areaServed": "Kuwait"
},
{
    "@type": "ContactPoint",
"telephone": "+1-8445180061",
"contactType": "Customer Service",
"contactOption": "TollFree",
"areaServed": "USA"
}
]
}`
    const homeSchema1 = `
{
    "@context": "https://schema.org",
"@type": "LocalBusiness",
"name": "Vinsys",
"image": "https://www.vinsys.com/images/logo.png",
"@id": "https://www.vinsys.com/",
"url": "https://www.vinsys.com/",
"telephone": "+91-20-67444700",
"address": {
    "@type": "PostalAddress",
"streetAddress": "Shivaji Niketan, Tejas Society, Behind Kothrud Bus Stand, Near Mantri Park, Kothrud,",
"addressLocality": "Pune",
"postalCode": "411038",
"addressCountry": "IN" 
},
"geo": {
    "@type": "GeoCoordinates",
"latitude": 18.50014081530139,
"longitude": 73.81028299458153 
},
"openingHoursSpecification": {
    "@type": "OpeningHoursSpecification",
"dayOfWeek": [
"Monday",
"Tuesday",
"Wednesday",
"Thursday",
"Friday",
"Saturday",
"Sunday"
],
"opens": "00:00",
"closes": "23:59" 
} 
}
`
    return (
        <>
            <Helmet>
                <title>{contactData.seo != null && contactData.seo.meta_title != null ? contactData.seo.meta_title : contactData.title}</title>
                <link rel="canonical" href={window.location.href} />
                <meta name="keywords" content={contactData.seo != null && contactData.seo.meta_key != null ? contactData.seo.meta_key : contactData.title} />
                <meta name="description" content={contactData.seo != null && contactData.seo.meta_description != null ? contactData.seo.meta_description : contactData.title} />
                <meta property="og:title" content={contactData.seo != null && contactData.seo.meta_title != null ? contactData.seo.meta_title : contactData.title} />
                <meta property="og:description" content={contactData.seo != null && contactData.seo.meta_description != null ? contactData.seo.meta_description : contactData.title} />
                <meta property="og:image" content={contactData.seo!=null && contactData.seo.og_image!=null ? contactData.seo.og_image :  `${process.env.PUBLIC_URL}/images/logo.png`} />
                <meta property="og:url" content={window.location.href} />
                <script type="application/ld+json">
                    {homeSchema}
                </script>
                <script type="application/ld+json">
                    {homeSchema1}
                </script>              
            </Helmet>

            <section className="contact-top-sec">
                <div className="inner-container d-flex justify-content-between align-items-center">
                    <div className="left">
                        <img src={contactData.get_in_touch_section.image.media} alt="" />
                    </div>
                    <div className="right">
                        <CommonHeading mainHeading={contactData.get_in_touch_section.heading} />
                        <EnquiryForm style="contact-form" FormStyle="common-from" />
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactTopSec