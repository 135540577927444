import { CommonHeading } from "../../other/Headingtext-Btn"
const CousreHighlights = ({sctionTitle,children})=>{
    return(
       <section className="digilearn-cousre-highlights">
            <div className="inner-container">
                <CommonHeading mainHeading={sctionTitle}/>                
                {children}               
            </div>
       </section>
    )
}
export default CousreHighlights