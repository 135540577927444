import React, {useState, useEffect} from "react";
import {InvestorContext} from './Context';
import ApiPath from "../other/ApiPath";
import Cookies from 'js-cookie';
const InvestorDataMain = ({children})=>{
    const TokensCodes = Cookies.get('access_token'); 
    const [investordata, setInvestorData] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
        try {
            //Contact Page Data
            const investorpagedata = await fetch(`${ApiPath()}/get-investor-relation-page`, {
                headers: {
                    'Authorization': `Bearer ${TokensCodes}`
                }

            });                      
            const currentinvestorpagedata = await investorpagedata.json();            
            setInvestorData(currentinvestorpagedata);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            }
    };
    fetchData();
    }, [TokensCodes]);

    if (loading) {
        return(
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!investordata) {
        return <div className='text-center'>No data available.</div>;
    } 
    return(
        <InvestorContext.Provider value={investordata}>
            {children}
        </InvestorContext.Provider>
    )
}
export default InvestorDataMain