import CommonBtn from "../../components/other/Headingtext-Btn"
import { Helmet } from 'react-helmet';
import $ from 'jquery'
const NotFoundPage = ()=>{
    $("body").addClass("inner-page")
    document.title = "Digital Learning | IT Development | Consulting | Translation Services - Vinsys"
    return(
        <>
          <Helmet>
            <title>Digital Learning | IT Development | Consulting | Translation Services - Vinsys</title>
            <link rel="canonical" href={window.location.href} />
            <meta name="keywords" content="Digital Learning | IT Development | Consulting | Translation Services - Vinsys" />
            <meta name="description" content="Digital Learning | IT Development | Consulting | Translation Services - Vinsys" />
            <meta property="og:title" content="Digital Learning | IT Development | Consulting | Translation Services - Vinsys" />
            <meta property="og:description" content="Digital Learning | IT Development | Consulting | Translation Services - Vinsys" />               
            <meta property="og:image" content="/images/logo.png" /> 
            <meta property="og:url" content={window.location.href} />                       
        </Helmet>
        
        <section className="thankuPg common-spacing-top-bottom first-section">
            <div className="inner-container">
                <div className="content">
                    <img src={`${process.env.PUBLIC_URL}/images/not-found-image.jpg`} alt="Thank you" />
                    <div className="small-heading">Look like you're lost</div>
                    <p>the page you are looking for not avaible!</p>
                    <div className="links">
                        <CommonBtn link="/" text="Go to Homepage"/>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}
export default NotFoundPage