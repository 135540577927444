import $ from 'jquery'
import React, { useState, useEffect } from "react"
import { Helmet } from 'react-helmet';
import ScrollToTop from "../../../components/other/ScrollToTop"
import InnerBanner from '../../../components/other/InnerBanner'
import CommonBtn, { CommonHeading } from "../../../components/other/Headingtext-Btn"
import AboutOverview from "../../../components/about/AboutOverview"
import FlsWhatOffer, { FlsWhatOfferItems } from "../../../components/fls/FlsWhatOffer"
import FlsChooseUs, { FlsChooseUsItems } from "../../../components/fls/FlsChooseUs"
import FlsGetQuote from "../../../components/fls/Home/FlsGetQuote"
import FlsProcessWrapper, { FlsProcessItems } from "../../../components/fls/Home/FlsProcess"

import axios from 'axios';
import ApiPath from '../../../components/other/ApiPath';
import GetQuoteForm from '../../../components/other/GetQuoteForm';
import Modal from '../../../components/other/Modal';
import { useForm } from 'react-hook-form';
import { useGetApi, usePostApi } from '../../../utils/Customhooks/ApiCalls';
import { useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../../config';

const SITE_KEY = process.env.REACT_APP_API_GOOGLE_RECAPTCHA_KEY;;

const FlsVoiceOver = () => {


    const navigate = useNavigate();


    const {
        data: getdata,
        isLoading: apiloading,
        error,
        doFetch,
    } = useGetApi("");

    const {
        data: states,
        isLoading: apiStateloading,
        error: stateError,
        doFetch: stateFetch,
    } = useGetApi("");

    const {
        data: Countrydata,
        isLoading: apiloadingCountry,
        error: countryerror,
    } = useGetApi("/get_all_counrty");

    const { doPost, isLoading, success } = usePostApi("/post-job-inquiry");
    const [modalOpen, setmodalOpen] = useState(false);
    // const [states, setStates] = useState([]);



    const {
        register,
        handleSubmit: reactFormSubmit,
        watch,
        formState: { errors },
    } = useForm();



    $(document).ready(function () {
        $("body").addClass("inner-page")
    })

    const [flsTranslationData, setData] = useState([]);
    const [addClass, setAddClass] = useState(false);
    const [loading, setLoading] = useState(true);
    const [errormessage, setErrorMessage] = useState(null);

    useEffect(() => {
        // Define the API endpoint URL
        const apiUrl = `${ApiPath()}/get-translation-page-details`; // Replace with your API URL

        // Use Axios to make a GET request
        axios.get(apiUrl)
            .then((response) => {
                setData(response.data);

                // Check if any item's index is greater than 4
                const shouldAddClass = response.data.what_we_offer_list.some((item, index) => index >= 4);
                setAddClass(shouldAddClass);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {

        const selectedCountry = watch('country');


        if (selectedCountry) {
            stateFetch(`/get_state_by_country/${selectedCountry}`)
        }
    }, [watch('country')]);




    const handleOnClick = async (data) => {

        try {

            window.grecaptcha.ready(() => {
                try {
                    window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {

                        if (token) {
                            handleSubmit(token, {...data,page_url: window.location.href});
                        }

                    }).catch((error) => {
                        console.log("error", error)

                    })

                } catch (error) {
                    setErrorMessage("Security Check Failed");
                    doFetch("/googl-recaptcha-error")
                }

            });

        } catch (error) {

            console.log("error", error)

        }

    }

    const handleSubmit = (token, data) => {

        const translationFormData = { ...data, resume: data.file[0], module: 'Translation Services' }

        const formData = new FormData();
        for (const key in translationFormData) {
            formData.append(key, translationFormData[key]);
        }

        doPost(formData)

    }

    if (success) {
        navigate("/thank-you")
    }


    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!flsTranslationData) {
        console.log("not load")
        return <div className='text-center'>No data available.</div>;
    }

    return (
        <>

            {
                modalOpen &&
                <>
                    <Modal setmodalOpen={setmodalOpen} modalHeading="Apply for Job Position">

                        <form onSubmit={reactFormSubmit(handleOnClick)}>

                            <ul class="common-from">
                                <li>
                                    <input type="text" placeholder="Your Name" pattern="[A-Za-z0-9\s]+" id="name" name="name" {...register("name", {
                                        required: true, pattern: /^[A-Za-z ]+$/i
                                    })} />
                                    {errors.name && errors.name.type === "required" && (
                                        <p className="errorMsg">Name is required.</p>
                                    )}
                                    {errors.name && errors.name.type === "pattern" && (<p className="errorMsg">Alphabetical character only</p>)}
                                </li>

                                <li>
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder="Your Email"
                                        {...register("email", {
                                            required: true,
                                            pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                                        })}
                                    />
                                    {errors.email && errors.email.type === "required" && (
                                        <p className="errorMsg">Email is required.</p>
                                    )}
                                    {errors.email && errors.email.type === "pattern" && (
                                        <p className="errorMsg">Email is not valid.</p>
                                    )}

                                </li>

                                <li>
                                    <input className='phone-input' type="number" placeholder="Phone Number" id="phone_number" name="phone_number" {...register("phone_number", {
                                        required: true, maxLength: 15,
                                    })} />

                                    {errors.phone_number && errors.phone_number.type === "required" && (
                                        <p className="errorMsg">Phone is required.</p>
                                    )}
                                    {errors.phone_number && errors.phone_number.type === "maxLength" && (<p className="errorMsg">Phone Number can not exceed with more than 15 digit</p>)}
                                </li>

                                <li class="w-100">
                                    <input type="text" placeholder="Language Proficiency" id="language" name="language_proficiency" {...register("language_proficiency", {
                                        required: true,pattern: /^[A-Za-z ]+$/i
                                    })} />

                                    {errors.language_proficiency && errors.language_proficiency.type === "required" && (
                                        <p className="errorMsg">Language Proficency is required.</p>
                                    )}
                                    {errors.language_proficiency && errors.language_proficiency.type === "pattern" && (<p className="errorMsg">Alphabetical character only</p>)}
                                </li>

                                <li>
                                    <select name="country" {...register("country", { required: true })}>
                                        <option value="">Select Country</option>
                                        {Countrydata.map((val) => {
                                            return (
                                                <option value={val.id} key={val.id}>{val.name}</option>
                                            )
                                        })}
                                    </select>

                                    {errors.country && errors.country.type === "required" && (
                                        <p className="errorMsg">country Name is required.</p>
                                    )}
                                </li>

                                {
                                    states && states.length > 0 && <>

                                        <li>
                                            <select name="state" {...register("state", { required: true })}>
                                                <option value="">Select State</option>
                                                {states.map((val) => {
                                                    return (
                                                        <option value={val.id} key={val.id}>{val.name}</option>
                                                    )
                                                })}
                                            </select>

                                            {errors.state && errors.state.type === "required" && (
                                                <p className="errorMsg">State Name is required.</p>
                                            )}
                                        </li>

                                    </>
                                }


                                <li> <input
                                    type="file"
                                    {...register('file', {
                                        required: 'Resume is required',
                                        validate: {
                                            acceptFormats: (file) => {
                                                if (file[0]) {
                                                    const acceptedFormats = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
                                                    if (!acceptedFormats.includes(file[0].type)) {
                                                        return 'Only PDF or DOC files are allowed';
                                                    }
                                                }
                                                return true;
                                            },
                                            maxFileSize: (file) => {
                                                if (file[0]) {
                                                    if (file[0].size > 200000) {
                                                        return 'Resume size should be less than 200 KB';
                                                    }
                                                }
                                                return true;
                                            },
                                        },
                                    })}
                                />
                                    {errors.file && <p className="errorMsg">{errors.file.message}</p>}</li>

                                <li>

                                    <div className="w-100">
                                        <button type="submit" className="common-btn" disabled={isLoading} >Apply Now <i className="icon">&nbsp;</i></button>
                                    </div>
                                    <p className="errorMsg">{errormessage}</p>
                                </li>
                            </ul></form >
                    </Modal>

                </>


            }




            <Helmet>
                <title>{flsTranslationData.seo != null && flsTranslationData.seo.meta_title != null ? flsTranslationData.seo.meta_title : flsTranslationData.title}</title>

                <link rel="canonical" href={window.location.href} />

                <meta name="keywords" content={flsTranslationData.seo != null && flsTranslationData.seo.meta_key != null ? flsTranslationData.seo.meta_key : flsTranslationData.title} />

                <meta name="description" content={flsTranslationData.seo != null && flsTranslationData.seo.meta_description != null ? flsTranslationData.seo.meta_description : flsTranslationData.title} />

                <meta property="og:title" content={flsTranslationData.seo != null && flsTranslationData.seo.meta_title != null ? flsTranslationData.seo.meta_title : flsTranslationData.title} />

                <meta property="og:description" content={flsTranslationData.seo != null && flsTranslationData.seo.meta_description != null ? flsTranslationData.seo.meta_description : flsTranslationData.title} />

                <meta property="og:image" content={flsTranslationData.seo != null && flsTranslationData.seo.og_image != null ? flsTranslationData.seo.og_image : `${process.env.PUBLIC_URL}/images/logo.png`} />

                <meta property="og:url" content={window.location.href} />

                {flsTranslationData.seo != null && flsTranslationData.seo.schema_list != null ? flsTranslationData.seo.schema_list.map((val) => {
                    return (
                        <script type="application/ld+json">
                            {val.detail}
                        </script>
                    )
                }) : ''}
            </Helmet>

            <ScrollToTop />
            <InnerBanner
                style="digi-inner-banner"
                image={flsTranslationData.banner != null ? flsTranslationData.banner.media : `${process.env.PUBLIC_URL}/images/go-digital-learning-banner-min.jpg`}>

                <div className='text-center'>
                    <CommonHeading
                        mainHeading={flsTranslationData.banner != null ? flsTranslationData.banner.heading : flsTranslationData.title} />
                    <p>
                        {flsTranslationData.banner != null ? flsTranslationData.banner.subheading : ''}
                    </p>
                </div>
            </InnerBanner>

            {flsTranslationData.overview != null ?
                <AboutOverview
                    style="delivery-about-sec"
                    image={flsTranslationData.overview.gallery != null ? flsTranslationData.overview.gallery[0].media : ''}
                    star={Number(flsTranslationData?.star)} rating={Number(flsTranslationData?.rating)}>

                    <CommonHeading mainHeading={flsTranslationData.overview.heading} />
                    <div className="detail-wrapper" dangerouslySetInnerHTML={{ __html: flsTranslationData.overview.content }} />
                </AboutOverview>
                : ''}

            {flsTranslationData.what_we_offer_list != null ?
                <FlsWhatOffer
                    styleClass={addClass ? 'five-column' : 'two-column'}
                    bgColor="dark-bg"
                    commonHeading={flsTranslationData.what_we_offer_section.heading}
                    innerText={flsTranslationData.what_we_offer_section.subheading} >

                    {flsTranslationData.what_we_offer_list.slice(0, 5).map((val, index) => {
                        return (
                            <FlsWhatOfferItems
                                icon={val.media}
                                iconAlt={val.alt}
                                title={val.thumb_alt != null ? val.thumb_alt : val.heading}
                                itemKey={index + 1}
                            />
                        )
                    })}
                </FlsWhatOffer>
                : ''}

            {flsTranslationData.our_process_list != null ?
                <FlsProcessWrapper
                    styleClass="flsLocalProcess darkGradiantBg"
                    heading={flsTranslationData.our_process_section.heading}
                    subHeading={flsTranslationData.our_process_section.subheading} >

                    {flsTranslationData.our_process_list.map((val, index) => {
                        return (
                            <FlsProcessItems
                                itemKey={index + 1}
                                icon={val.thumb_image}
                                iconAlt={val.thumb_alt != null ? val.thumb_alt : val.heading}
                                title={val.heading}
                            />
                        )
                    })}
                </FlsProcessWrapper>
                : ''}

            {flsTranslationData.why_choose_us != null ?
                <FlsChooseUs
                    heading={flsTranslationData.why_choose_us_section.heading}
                    subHeading={flsTranslationData.why_choose_us_section.subheading}
                    galleryImg={flsTranslationData.why_choose_us_section.gallery != null ? flsTranslationData.why_choose_us_section.gallery[0].media : ''}
                    galleryImgAlt={flsTranslationData.why_choose_us_section.gallery != null ? flsTranslationData.why_choose_us_section.gallery[0].alt : ''} >

                    {flsTranslationData.why_choose_us.map((val) => {
                        return (
                            <FlsChooseUsItems
                                icon={val.thumb_image}
                                iconAlt={val.thumb_alt != null ? val.thumb_alt : val.heading}
                                title={val.heading}
                            />
                        )
                    })}
                </FlsChooseUs>
                : ''}

            <FlsGetQuote />


            <FlsChooseUs
                heading={flsTranslationData.are_your_translator.heading}
                subHeading={flsTranslationData.are_your_translator.subheading}
                galleryImg={flsTranslationData.are_your_translator.gallery.media}
                galleryImgAlt={flsTranslationData.are_your_translator.gallery.alt}
                styleClass="flsTranslator" >

                {/* <GetQuoteForm style="contact-form" FormStyle="common-from" formType="Translation Service" /> */}

                <div className={`btn-wrapper d-flex ${flsTranslationData.are_your_translator.link === null ? 'no-require' : ''}`} onClick={() => { setmodalOpen(true) }}>
                    <CommonBtn to={flsTranslationData.are_your_translator.link} text={flsTranslationData.are_your_translator.button_text}></CommonBtn>
                </div>

            </FlsChooseUs>
        </>

    )
}
export default FlsVoiceOver