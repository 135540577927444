import $ from 'jquery'
import FlsCaseStudyItem, { FlsCaseStudyItemWrapper } from "./flsCaseStudyItem"
import React from "react"

import { useEffect, useState } from 'react';
import axios from 'axios';
import ApiPath from '../../components/other/ApiPath';
import LatestCaseStudy from './latestCaseStudt';
import CommonBtn from '../other/Headingtext-Btn';
import { Link } from 'react-router-dom';


const CaseStudyFilterItems = ({ moduleID, children }) => {
    $("body").addClass("inner-page")

    const [filterVal, SetfilterVal] = useState({
        service: '',
        industry: '',
        keyword: ''
    })
    const handleChange = (event) => {
        SetfilterVal({ ...filterVal, [event.target.name]: event.target.value });
        SetCurNumber(1)
        SetlastNumberItems(0)
    };



    const [curNumber, SetCurNumber] = useState(1)
    const [lastNumberItems, SetlastNumberItems] = useState(0)
    const [curNumberItems, SetCurNumberItems] = useState(9)

    const [casestudyListData, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const flsModuleId = moduleID == '' || moduleID == undefined ? '' : '&module_id=' + moduleID;
    const flsIndustryId = filterVal.industry == '' || filterVal.industry == undefined || filterVal.industry == null ? '' : '&fls_industries_id=' + filterVal.industry;
    const flsServiceId = filterVal.service == '' || filterVal.service == undefined || filterVal.service == null ? '' : '&fls_service_id=' + filterVal.service;
   

    useEffect(() => {



        const apiUrl = `${ApiPath()}/get-case-study-list?page=${curNumber}${flsModuleId}${flsIndustryId}${flsServiceId}`;

        axios.get(apiUrl).then((response) => {
            setData(response.data)
        }).catch((error) => {
            console.error('Error fetching data:', error);
        }).finally(() => {
            setLoading(false);
        });

    }, [curNumber, filterVal]);

    const handleReset = () => {

        SetfilterVal({ ...filterVal, service: '', industry: '' });


    }



    if (loading) {
        return (
            <section className="loder-sec d-flex justify-content-center align-items-center">
                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            </section>
        );
    }

    if (!casestudyListData) {

        return <div className='text-center'>No data available.</div>;
    }

    const itemsCount = []
    let totalCsCount = Math.ceil(casestudyListData.total_count / 9)
    

    for (let i = 1; i <= totalCsCount; i++) {
        itemsCount.push([i])
    }
    const handleItemClick = (event) => {
        const value = event.target.textContent;
        SetCurNumber(value);
        console.log('::' + value)
    };
    const NavItemsChangeReverse = () => {
        if (curNumberItems > 5) {
            SetlastNumberItems(lastNumberItems - 5)
            SetCurNumberItems(curNumberItems - 5)
        }
    }
    const NavItemsChange = () => {
        if (curNumberItems < totalCsCount) {
            SetlastNumberItems(lastNumberItems + 5)
            SetCurNumberItems(curNumberItems + 5)
        }
    }

    return (
        <>
            <section className="flsCaseStudyListing common-spacing-top-bottom" id="blog-count">
                <div className="inner-container">
                    <form className="form">
                        <ul className="common-from d-flex justify-content-center" >
                            {/* <li>
                                <select value={filterVal.industry} onChange={handleChange} name="industry" id="industry" form="">
                                    <option value=""> All Industries</option>
                                    {casestudyListData.fls_industries.map((val) => {
                                        return (
                                            <option value={val.id}>{val.heading}</option>
                                        )
                                    })}
                                </select>
                            </li>
                            <li>
                                <select value={filterVal.service} onChange={handleChange} name="service" id="service" form="">
                                    <option value=""> All Services</option>
                                    {casestudyListData.fls_service.map((val) => {
                                        return (
                                            <option value={val.id}>{val.heading}</option>
                                        )
                                    })}
                                </select>
                            </li>
                            <li onClick={handleReset}>

                                <Link className={`common-btn`} style={{ padding: "12px 36px" }}>Reset<i className="icon">&nbsp;</i></Link>



                            </li> */}
                        </ul>

                        <div className='inner-content d-flex mt-60'>
                            <div className='left-section allProducts'>
                                <FlsCaseStudyItemWrapper>
                                    {casestudyListData.case_study_list.map((val) => {
                                        return (

                                            <>

                                                <FlsCaseStudyItem
                                                    listKey={val.id}
                                                    link={val.slug}
                                                    image={val.banner}
                                                    imageAlt={val.banner_alt != null ? val.banner_alt : val.title}
                                                    UserImage={val.author.image}
                                                    userImageAlt={val.author.alt != null ? val.author.alt : val.author.name}
                                                    userName={val.author.name}
                                                    caseStudyDate={val.published_date}
                                                    caseStudyTitle={val.title}
                                                    caseStudyDesc={val.content && val.content.length > 500 ? val.content.slice(0, 500) + '..'  : val.content} />
                                            </>

                                        )
                                    })}
                                </FlsCaseStudyItemWrapper>

                                {/* Pagination */}
                                <ul className='pagination d-flex align-items-center justify-content-center'>
                                    <li onClick={NavItemsChangeReverse} className="arrow prev"><img src={`${process.env.PUBLIC_URL}/images/paginationArrow.svg`} alt="" /></li>
                                    {itemsCount.slice(lastNumberItems, curNumberItems).map((val) => {
                                        return (
                                            <li onClick={handleItemClick} className={curNumber == val ? 'active' : 'non-active'} key={val} id={val}><a href="#blog-count">{val}</a></li>
                                        )
                                    })}
                                    <li onClick={NavItemsChange} className="arrow next"><img src={`${process.env.PUBLIC_URL}/images/paginationArrow.svg`} alt="" /></li>
                                </ul>
                            </div>
                            <div className='right-section recomendedProducts'>
                                <div className='items d-flex'>
                                    <LatestCaseStudy />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default CaseStudyFilterItems